import React, {Component} from "react";
import {Field, FieldArray, change} from "redux-form";
import {Row, Col, Form, Icon} from "antd";

import IntlMessages from "Common/components/intlMessages";
import InputField from "Common/components/InputField";
import ImageUploader from "Driver/components/ImageUploader";
import UploadDocumentWrapper from 'Vehicle/styles/UploadDocumentWrapper.style';
import image from 'Vehicle/styles/images/upload-document.png';

const required = (value) => (value ? undefined : "This field is required");

const FormItem = Form.Item;
const FORM_NAME = "CreateService";

class UploadDocument extends Component {
    render() {
        const {handleDispatch,editingMode,entity, handleDelete} = this.props;
        return (
            <Row gutter={30}>
                <Col lg={20} md={20}>
                    <FieldArray
                        name="documents"
                        component={UploadDocuments}
                        handleDispatch={handleDispatch}
                        editingMode={editingMode}
                        entity={entity}
                        handleDelete={handleDelete}
                    />
                </Col>
            </Row>
        );
    }
}

export default UploadDocument;

const imageRequired = (value) => {
    return value && value.length && typeof value == "object"
        ? undefined
        : "This field is required";
};


class UploadDocuments extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            documentData: {},
        };
    }

  

    handleImageChange = (data, index, field) => {
        const {documentData} = this.state;
        const documentImage = {};
        documentImage[index] = data;
        this.setState({
            documentData: {...documentData, ...documentImage},
        });
        this.props.handleDispatch(change(FORM_NAME, field, data));
    };

    render() {
        const {
            fields,
            meta: {error, submitFailed},
            entity,
            editingMode,
        } = this.props;
        return (
            <div>
                <h2>  <IntlMessages id="forms.label.document"/>{" "}</h2>
                {fields.map((documents, index) => {
                    const {documentData} = this.state;
                    let data = documentData[index];
                    if (!data) {
                        data = (editingMode && entity && entity["documents"] && entity["documents"][index] && entity["documents"][index]["uploadedDocument"] )?entity["documents"][index]["uploadedDocument"]:[];
                    }
                    let docId=(editingMode && entity && entity["documents"] && entity["documents"][index] && entity["documents"][index]["id"] )?entity["documents"][index]["id"]:false;
                    return (
                        <UploadDocumentWrapper style={{padding:"10px"}}>
                            <Row gutter={48}>
                                <Col lg={20} md={22}>
                                    <Row gutter={16}>
                                        <Col lg={24} md={24}>
                                            <FormItem>
                                                <label>
                                                    <IntlMessages id="forms.label.documentTitle"/>{" "}
                                                </label>
                                                <Field
                                                    name={`${documents}.name`}
                                                    validate={required}
                                                    component={InputField}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col lg={24} md={24}>
                                            <FormItem>
                                                <label>
                                                    <IntlMessages id="forms.label.images"/>
                                                </label>
                                                <ImageUploader
                                                    onChange={this.handleImageChange}
                                                    index={index}
                                                    entityType="driver"
                                                    field={`${documents}.uploadedDocument`}
                                                    data={data}
                                                />
                                                <Field
                                                    type="hidden"
                                                    component={InputField}
                                                    name={`${documents}.uploadedDocument`}
                                                    validate={imageRequired}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4} md={2}>
                                    <FormItem>
                                        <br/>
                                        <Icon
                                            type="close"
                                            style={{cursor: "pointer"}}
                                            onClick={() => {
                                                this.props.handleDelete(docId)
                                                fields.remove(index)

                                            }}
                                            // onClick={() => fields.remove(index)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </UploadDocumentWrapper>
                    );
                })}
            <UploadDocumentWrapper>
                <div style={{display:"inline-block",cursor: "pointer"}} onClick={() => fields.push({})}>
                   <img src={image} /><br/>
                    Browse from your computer
                {submitFailed && error && <span>{error}</span>}
                </div>
            </UploadDocumentWrapper>
            </div>
        );
    }
}
