// import { retrieveDriver } from "./saga";

const actions = {
  RETRIEVE_DRIVER_FATIGUE_REQUEST: "RETRIEVE_DRIVER_FATIGUE_REQUEST",
  RETRIEVE_DRIVER_FATIGUE_REQUESTV1: "RETRIEVE_DRIVER_FATIGUE_REQUESTV1",
  RETRIEVE_JOB_ACTIVITIES: "RETRIEVE_JOB_ACTIVITIES",
  RETRIEVE_DRIVER_DETAIL: "RETRIEVE_DRIVER_DETAIL",
  FETCH_FATIGUE_REQUEST: "FETCH_FATIGUE_REQUEST",
  FETCH_FATIGUE: "FETCH_FATIGUE",

  retrieveDriverFatigue: (id, resolve, reject) => {
    return {
      type: actions.RETRIEVE_DRIVER_FATIGUE_REQUEST,
      id,
      resolve,
      reject,
    };
  },

  retrieveDriverFatigueV1: (id, startDateTime, resolve, reject) => {
    return {
      type: actions.RETRIEVE_DRIVER_FATIGUE_REQUESTV1,
      id,
      startDateTime,
      resolve,
      reject,
    };
  },

  retrieveJobActivities: (driverId, startDateTime, endDateTime, resolve, reject) => {
    return {
      type: actions.RETRIEVE_JOB_ACTIVITIES,
      driverId,
      startDateTime,
      endDateTime,
      resolve,
      reject,
    };
  },

  retrieveDriverDetail: (driverId, resolve, reject) => {
    return {
      type: actions.RETRIEVE_DRIVER_DETAIL,
      driverId,
      resolve,
      reject,
    };
  },
  fetchFatigueRequest: (resolve, reject, filterChecked) => {
    return {
      type: actions.FETCH_FATIGUE_REQUEST,
      resolve,
      reject,
      filterChecked,
    };
  },
  fetchFatigue: (data) => {
    return {
      type: actions.FETCH_FATIGUE,
      payload: data,
    };
  },
};
export default actions;
