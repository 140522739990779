import actions from "./actions";
const initialState = {
  vehicles: [],
  assets: [],
  loading: false,
  vehicleTypes: [],
  vehicleDropdown: [],
  refetch: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_VEHICLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        vehicles: action.response,
        loading: false,
      };
    case actions.FETCH_VEHICLE_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actions.FETCH_ASSET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_ASSET_LIST_SUCCESS:
      return {
        ...state,
        assets: action.response,
        loading: false,
      };
    case actions.FETCH_ASSET_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actions.FETCH_VEHICLE_TYPE_SUCCESS:
      return {
        ...state,
        vehicleTypes: action.response ?? [],
      };
    case actions.FETCH_VEHICLE_DROPDOWN_SUCCESS:
      return {
        ...state,
        vehicleDropdown: action.response.result ?? [],
      };
    case actions.REFETCH_REQUEST:
      return {
        ...state,
        refetch: action.status,
      };
    default:
      return state;
  }
};
