import React from 'react';
import {Col} from 'antd';

import {FormWrapper} from 'Common/components/Form/styles/formWrapper.style';

const FormGroupWrapper = ({label,children}) => (

  <FormWrapper>
    <Col lg={10} md={12} style={{padding:' 0px 15px',marginBottom:'24px'}}>
      {label}
      {children}
      </Col>
  </FormWrapper>
);

export default FormGroupWrapper;