import React, { Component } from "react";
import { Icon, message, Tooltip } from "antd";
import Button from "Common/components/button";
import { connect } from "react-redux";
import moment from "moment";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import ItemStatus from "Common/components/ItemSummary/status";
import PageHeader from "Common/components/Page/PageHeader";
import TableWrapper from "Common/components/Table/index";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/ProtectedColumn";
import localizeDate from "Common/localizeDate";
import actions from "Maintenance/redux/actions";
import {
  MaintainanceCompletedSection,
  MaintainanceRepairSection,
  MaintainanceSection,
} from "Common/components/Table/styles/responsiveTableWrapper.style";

import { ButtonWrappper } from "Common/components/Form/styles/formWrapper.style";
import CreateServiceForm from "Maintenance/components/CreateServiceForm";

const { deleteMaintenance } = actions;

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

const formatDate = (date) => (date ? (moment(date).isValid() && localizeDate(date).format("DD/MM/YYYY")) || "N/A" : "N/A");

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Maintenance",
  },
];

const quickFilter = {
  filterBy: "type",
  defaultValue: "all",
  options: [
    {
      tab: "Upcoming Services",
      key: "all",
    },
    {
      tab: "Work Order",
      key: "Services",
    },
    {
      tab: "Repairs",
      key: "Repairs",
    },
  ],
};

class ListMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_id: props.match.params.id,
      entity: {},
      loading: false,
      updatedOn: moment().valueOf(),
      type: "all",
      serviceId: null,
      serviceValue: null,
      openService: false,
      entity: null,
      serviceType: "Services",
      editMode: false,
    };
  }

  componentWillMount = () => {
    this.setState({
      updatedOn: moment().valueOf(),
      type: this.props.match.params.type,
    });
  };

  onServiceCreateClicked = () => {
    this.props.history.push("/service/create");
  };

  onRepairCreateClicked = () => {
    if (this.props.fromVehicle) {
      this.setState({
        openService: true,
        serviceType: "Repairs",
      });
    } else {
      this.props.history.push("/repair/create");
    }
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteMaintenance(id, resolve, reject);
    })
      .then(() => {
        this.setState({
          loading: false,
          updatedOn: moment().valueOf(),
        });
        message.success("Successfully deleted maintenance");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting maintenance");
        console.log(error);
      }, this);
  };

  confirm = (e) => {
    var self = this;
    self.onDeleteClicked(e);
  };

  onCancel = () => {
    this.setState({
      serviceCancel: true,
      serviceId: null,
      serviceValue: null,
      openService: false,
      entity: null,
      editMode: false,
      serviceType: "Services",
    });
  };

  onEditService = (row, type) => {
    if (this.props.fromVehicle) {
      this.setState({
        openService: true,
        serviceId: row?.id,
        serviceType: type ? type : "Services",
        editMode: true,
        entity: {
          id: row.id,
          startedDate: row.startedDate,
          odometerReading: row.serviceKms,
          status: row.status,
          repairServiceBy: row?.repairServiceBy,
          serviceType: {
            id: row.serviceType.id,
          },
          vehicle: {
            id: row.vehicleId,
          },
          service: row.service,
        },
      });
    } else {
      this.props.history.push("/maintenance/" + row.id + "/edit/");
    }
  };

  handleRowClick = (record, row) => {
    // if (this.props.match.params.type === "all") {
    //   if (record.openServiceId) {
    //     this.props.history.push(
    //       "/maintenance/" + record.openServiceId + "/edit/"
    //     );
    //   } else {
    //     this.props.history.push("/service/create", {
    //       vehicle: { id: parseInt(record.vehicleId) },
    //       lastServiceKms: record.lastServiceKms || undefined,
    //       lastServiceDate: record.lastServiceDate || undefined,
    //       odometerReading: record.nextServiceKms || undefined,
    //       startedDate: record.estimatedNextServiceDate || undefined,
    //       status: "Open",
    //       serviceType: { id: parseInt(record.serviceTypeId) },
    //     });
    //   }
    //   return;
    // }
    // this.props.history.push("/maintenance/" + record.id + "/edit/");
  };

  onhandleCreate = (record) => {
    if (this.props.fromVehicle) {
      this.setState({
        openService: true,
        editMode: false,
        serviceValue: {
          vehicle: { id: parseInt(record.vehicleId) },
          odometerReading: record.lastServiceKms,
          startedDate: record.lastServiceDate,
          status: "Open",
          serviceType: { id: parseInt(record.serviceType.id) },
        },
      });
    } else {
      this.props.history.push("/service/create", {
        vehicle: { id: parseInt(record.vehicleId) },
        odometerReading: record.lastServiceKms,
        startedDate: record.lastServiceDate,
        status: "Open",
        serviceType: { id: parseInt(record.serviceType.id) },
      });
    }
  };

  onEditMaintenance = (record) => {
    this.props.history.push("/maintenance/" + record.openServiceId + "/edit/");
  };

  getDefaultAdvancedFilterData = () => {
    if (this.props.match.params.type === "all") {
      return { status: "Open,In Progress" };
    } else if (this.props.match.params.type === "Repairs") {
      return { status: "Open,Completed" };
    }
    return { status: "Completed" };
  };

  createComponentActionButtons = () => {
    // if(this.props.match.params.type === "all") {
    //  return  <Fragment>
    //             <Button type="primary" icon="plus" onClick={this.onServiceCreateClicked} style={{marginRight: "15px"}}>{"Create Service"}</Button>
    //             <Button type="primary" icon="plus" onClick={this.onRepairCreateClicked} style={{marginRight: "15px"}}>{"Create Repair"}</Button>
    //         </Fragment>
    // }
    // if(this.props.match.params.type === "Services") {
    //   return <Button type="primary" icon="plus" onClick={this.onServiceCreateClicked} style={{marginRight: "15px"}}>{"Create Service"}</Button>
    // }
    if (this.props.match.params.type === "Repairs") {
      return (
        <Button type="primary" icon="plus" onClick={this.onRepairCreateClicked} style={{ marginRight: "15px" }}>
          {"Create Repair"}
        </Button>
      );
    }
  };

  render() {
    const { type } = this.props.match.params;
    const { context, handleQuickFilterChange, vehicleId, fromVehicle } = this.props;
    let SectionComponent;

    switch (type) {
      case "Services":
        SectionComponent = MaintainanceCompletedSection;
        break;
      case "Repairs":
        SectionComponent = MaintainanceRepairSection;
        break;
      default:
        SectionComponent = MaintainanceSection;
        break;
    }
    // Build the TableWrapper props
    const tableWrapperProps = {
      defaultQuickFilterValue: this.props.match.params.type,
      handleRowClick: this.handleRowClick,
      history: this.props.history,
      updatedOn: this.state.updatedOn,
      columns:
        this.props.match.params.type === "Services"
          ? this.getColumnsService()
          : this.props.match.params.type === "Repairs"
          ? this.getColumnsRepair()
          : this.getColumnsUpcoming(),
      quickFilterElements: quickFilter,
      defaultAdvancedFilterData: this.getDefaultAdvancedFilterData(),
      quickFilterStatus: this.props.match.params.type,
      url:
        this.props.match.params.type === "Services" || this.props.match.params.type === "Repairs"
          ? "maintenance"
          : "maintenance/upcoming?filters[enabled]=true",
      createTitle: this.createComponentActionButtons(),
      context: context,
    };
    if (handleQuickFilterChange) {
      tableWrapperProps.onQuickFilterChange = (value) => handleQuickFilterChange(value);
      tableWrapperProps.customProps = { vehicle: vehicleId };
    }
    if (!vehicleId) {
      tableWrapperProps.additionalFilters;
    }
    return (
      <>
        <LayoutWrapper>
          {context !== "vehicleMaintainance" && (
            <PageHeader title={<IntlMessages id="pagetitle.listMaintenance" />} breadcrumbs={breadcrumbs}>
              <Protected scope="write:users">
                <p></p>
              </Protected>
            </PageHeader>
          )}
          <LayoutContent loading={this.state.loading}>
            <SectionComponent>
              <TableWrapper {...tableWrapperProps} tmsTable />
            </SectionComponent>
          </LayoutContent>
        </LayoutWrapper>
        {this.state.openService && (
          <CreateServiceForm
            maintenanceId={this.props.match.params.id}
            history={this.props.history}
            defaultValue={this.state.serviceValue || {}}
            entity={this.state.entity}
            entity_id={this.state.serviceId}
            editingMode={this.state.editMode}
            type={this.state.serviceType}
            fromVehicle={fromVehicle}
            onCancel={this.onCancel}
            openService={this.state.openService}
          />
        )}
      </>
    );
  }

  getDefaultColumns = () => {
    return [
      {
        title: "Vehicle Rego",
        dataIndex: "registration_number",
        key: "registration_number",
      },
      {
        title: "Service Type",
        dataIndex: "serviceType.title",
        key: "serviceType.title",
      },
      {
        title: "Last Service Kms",
        dataIndex: "lastServiceKms",
        key: "lastServiceKms",
      },
      {
        title: "Next Service Kms",
        dataIndex: "nextServiceKms",
        key: "nextServiceKms",
      },
      {
        title: "Current Kms",
        dataIndex: "currentKms",
        key: "currentKms",
      },
      {
        title: "Due In Kms",
        dataIndex: "dueInKm",
        key: "dueInKm",
        render: (kms) => {
          if (parseInt(kms) < 0) {
            return <span style={{ color: "red" }}>{kms}</span>;
          } else if (parseInt(kms) < 1000) {
            return <span style={{ color: "orange" }}>{kms}</span>;
          } else {
            return kms;
          }
        },
      },
      {
        title: "Last Service Date",
        dataIndex: "lastServiceDate",
        key: "lastServiceDate",
        render: (date) => formatDate(date),
      },
      {
        title: "Next Service Date",
        dataIndex: "nextServiceDate",
        key: "nextServiceDate",
        render: (date, row) => {
          const formattedDate = date ? formatDate(date) : formatDate(row["estimatedNextServiceDate"]);
          const dateParts = formattedDate.split("/");
          const formattedDateUs = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;

          const diffInDays = Math.ceil((new Date(formattedDateUs) - new Date()) / (1000 * 60 * 60 * 24));

          if (diffInDays >= 0 && diffInDays <= 10) {
            return <span style={{ color: "orange" }}>{formattedDate}</span>;
          } else if (diffInDays < 0) {
            return <span style={{ color: "red" }}>{formattedDate}</span>;
          } else {
            return <span style={{ color: "lightgrey" }}>{formattedDate}</span>;
          }
        },
      },
      {
        title: "Action",
        render: (text, row) => {
          return (
            <ButtonWrappper style={{ float: "left" }}>
              {row.openServiceId ? (
                <div>
                  <Button
                    style={{ background: "#ed8a00", borderColor: "#ed8a00", padding: "0 33px" }}
                    onClick={this.onEditMaintenance.bind(this, row)}
                  >
                    <span style={{ color: "#fff" }}>Edit</span>
                  </Button>
                  {/* <Button
                    type="primary"
                    onClick={this.onhandleCreate.bind(this,row)}
                  >
                    Create
                  </Button> */}
                </div>
              ) : (
                <Button type="primary" onClick={this.onhandleCreate.bind(this, row)}>
                  Create
                </Button>
              )}
            </ButtonWrappper>
          );
        },
      },
    ];
  };

  getColumnsUpcoming = () => {
    return getProtectedColumn(
      this.getDefaultColumns,
      // this.onEditClicked,
      this.confirm,
      "users:driver"
    );
  };

  getServiceColumns = () => {
    return [
      {
        title: "Vehicle Rego",
        dataIndex: "registration_number",
        key: "registration_number",
      },
      {
        title: "Service Type",
        dataIndex: "serviceType.title",
        key: "serviceType.title",
        sorter: { sorter },
        render: (text) => (text ? text : "-"),
      },
      {
        title: "Service Kms",
        dataIndex: "serviceKms",
        key: "serviceKms",
      },
      {
        title: "Service Date",
        dataIndex: "startedDate",
        key: "startedDate",
        sorter: { sorter },
        render: (date) => formatDate(date),
      },
      {
        title: "Service Amount",
        dataIndex: "service",
        key: "serviceAmount",
        render: (services) =>
          Array.isArray(services) && services.length > 0
            ? "$" + services.reduce((total, current) => total + (parseFloat(current.amount) || 0), 0)
            : "N/A",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: { sorter },
        render: (status) => (status ? <ItemStatus status={status.toLowerCase()} /> : "-"),
      },
      {
        title: "Action",
        render: (text, row) => (
          <Tooltip title="Edit">
            <Icon type="edit" style={{ fontSize: 16 }} onClick={this.onEditService.bind(this, row, "Services")} />
          </Tooltip>
        ),
      },
    ];
  };

  getColumnsService = () => {
    return getProtectedColumn(
      this.getServiceColumns,
      // this.onEditClicked,
      this.confirm,
      "users:driver"
    );
  };

  getRepairColumns = () => {
    return [
      {
        title: "Vehicle Rego",
        dataIndex: "registration_number",
        key: "registration_number",
        sorter: { sorter },
      },
      {
        title: "Repair Kms",
        dataIndex: "serviceKms",
        key: "serviceKms",
      },
      {
        title: "Repair Date",
        dataIndex: "startedDate",
        key: "repairDate",
        sorter: { sorter },
        render: (date) => formatDate(date),
      },
      {
        title: "Repair Amount",
        dataIndex: "service",
        key: "repairAmount",
        sorter: { sorter },
        render: (repairs) =>
          Array.isArray(repairs) && repairs.length > 0
            ? "$" + repairs.reduce((total, current) => total + (parseFloat(current.amount) || 0), 0)
            : "N/A",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: { sorter },
        render: (status) => (status ? <ItemStatus status={status.toLowerCase()} /> : "-"),
      },
      {
        title: "Action",
        render: (text, row) => (
          <Tooltip title="Edit">
            <Icon type="edit" style={{ fontSize: 16 }} onClick={this.onEditService.bind(this, row, "Repairs")} />
          </Tooltip>
        ),
      },
    ];
  };

  getColumnsRepair = () => {
    return getProtectedColumn(
      this.getRepairColumns,
      // this.onEditClicked,
      this.confirm,
      "users:driver"
    );
  };
}

export default connect(null, { deleteMaintenance })(ListMaintenance);
