import React, { Component } from "react";
import { Col, DatePicker, Row, Select, Input } from "antd";
import Button from "Common/components/button";
import ContactSelect from "Contact/components/form/ContactSelect";

import { connect } from "react-redux";
import moment from "moment";
import actions from "Booking/redux/actions";

const { listJob } = actions;

const { RangePicker } = DatePicker;
const { Option } = Select;

class CommonSelect extends Component {
  render() {
    const { placeholder, options, onChange, value } = this.props;
    return (
      <Select
        placeholder={placeholder}
        onChange={onChange}
        style={{ width: 180 }}
        value={value || undefined}
        allowClear
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    );
  }
}

class JobsFilter extends Component {
  state = {
    dateRange: [moment().subtract(1, "month"), moment().add(1, "month")],
    selectedDriver: null,
    selectedVehicle: null,
    status: "",
    search: "",
    contact: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.quickFilterValue !== this.props.quickFilterValue) {
      this.handleQuickFilterChange();
    }
    if (
      prevState.dateRange !== this.state.dateRange ||
      prevState.selectedDriver !== this.state.selectedDriver ||
      prevState.selectedVehicle !== this.state.selectedVehicle ||
      prevProps.page !== this.props.page ||
      prevProps.pageSize !== this.props.pageSize ||
      prevProps.status !== this.state.status ||
      prevState.search !== this.state.search ||
      prevState.contact !== this.state.contact
    ) {
      this.fetchJobs();
    }
  }

  handleQuickFilterChange = () => {
    this.setState({
      dateRange: null,
      selectedDriver: null,
      selectedVehicle: null,
      search: "",
      contact: null,
    });
  };

  fetchJobs = async () => {
    const { listJob, page, pageSize } = this.props;
    const { dateRange, selectedDriver, selectedVehicle, status, search, contact } = this.state;
    const query = {
      startDate: dateRange?.length > 0 ? moment(dateRange[0]).format("YYYY-MM-DD") : undefined,
      endDate: dateRange?.length > 0 ? moment(dateRange[1]).format("YYYY-MM-DD").valueOf() : undefined,
      driverId: selectedDriver,
      vehicleId: selectedVehicle,
      page,
      pageSize,
      status,
      q: search,
      contact,
    };
    let queryString = Object.keys(query)
      ?.filter((key) => query[key] !== undefined && query[key] !== null)
      ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
      .join("&");
    await new Promise((resolve, reject) => {
      listJob(resolve, reject, queryString);
    });
  };

  handleDateChange = (dates) => {
    this.setState({ dateRange: dates });
  };

  handleDriverChange = (value) => {
    this.setState({ selectedDriver: value });
  };

  handleVehicleChange = (value) => {
    this.setState({ selectedVehicle: value });
  };
  handleStatusChange = (value) => {
    this.setState({ status: value ? value : "" });
  };
  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleContactChange = (value) => {
    this.setState({ contact: value });
  };

  render() {
    const { drivers, vehicles } = this.props;
    const { dateRange, selectedDriver, selectedVehicle, status, search, contact } = this.state;

    const driverOptions = drivers?.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    const vehicleOptions = vehicles?.map((item) => ({
      label: item.registrationNumber,
      value: item.id,
    }));
    const statusOption = [
      {
        label: "All Loads",
        value: "",
      },
      {
        label: "Draft",
        value: "draft",
      },
      {
        label: "Completed",
        value: "completed",
      },
      {
        label: "In Transit",
        value: "inTransit",
      },
      {
        label: "Cancelled",
        value: "cancelled",
      },
      {
        label: "Published",
        value: "published",
      },
    ];

    return (
      <div className="shift_filter">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12} lg={6}>
            <RangePicker onChange={this.handleDateChange} value={dateRange} />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <CommonSelect placeholder="All Driver" options={driverOptions} onChange={this.handleDriverChange} value={selectedDriver} />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <CommonSelect placeholder="All Vehicle" options={vehicleOptions} onChange={this.handleVehicleChange} value={selectedVehicle} />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <CommonSelect placeholder="Status" options={statusOption} onChange={this.handleStatusChange} value={status} />
          </Col>
          {this.props.userRole === "admin" && (
            <Col xs={24} md={12} lg={4}>
              <ContactSelect placeholder="Company" value={contact} onChange={this.handleContactChange} />
            </Col>
          )}
          <Col xs={24} md={12} lg={5}>
            <Input placeholder="Search" value={search} onChange={this.handleSearchChange} allowClear />
          </Col>
          <Col xs={24} md={12} lg={3}>
            <Button className="primary active" icon="plus" onClick={this.props.onCreate}>
              Create New Load
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  drivers: state.Shifts.drivers,
  vehicles: state.Vehicle.vehicleDropdown,
});

const mapDispatchToProps = (dispatch) => ({
  listJob: (resolve, reject, query) => dispatch(listJob(resolve, reject, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsFilter);
