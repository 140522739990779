import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

const styles = {
  wrapper: {
    position: "relative",
    backgroundColor: "white",
    cursor: "pointer",
  },
  pdfContainer: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  slideWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 0.3s ease-in-out",
  },
  slideButtons: {
    position: "absolute",
    bottom: "2px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px",
    zIndex: 10,
  },
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
    color: "#666",
  },
  pageIndicator: {
    position: "absolute",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "12px",
    color: "#666",
    zIndex: 10,
  },
};

const customStyles = `
  .rpv-core__viewer {
    overflow: hidden !important;
  }
  .rpv-core__page-layer {
    overflow: hidden !important;
  }
  .rpv-core__inner-pages {
    overflow: hidden !important;
  }
  .rpv-core__page-container {
    overflow: hidden !important;
  }
  .rpv-core__page {
    margin: 0 !important;
  }
  .rpv-core__inner-page {
    padding: 0 !important;
  }
`;

const PdfThumbnail = ({ pdfUrl, width, height, handleOnClick }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [slideDirection, setSlideDirection] = useState(0);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [defaultScale, setDefaultScale] = useState(0.2);
  const { jumpToPage } = pageNavigationPluginInstance;

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setSlideDirection(1);
      setCurrentPage((prev) => {
        jumpToPage(prev - 1);
        return prev - 1;
      });
    }
  };

  useEffect(() => {
    // We'll use this to set initial scale - you may need to adjust the divisor
    // based on your PDF's original dimensions
    const calculatedScale = Math.min(width / 565, height / 800);
    setDefaultScale(calculatedScale);
  }, [width, height]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setSlideDirection(-1);
      setCurrentPage((prev) => {
        jumpToPage(prev + 1);
        return prev + 1;
      });
    }
  };

  return (
    <div style={{ ...styles.wrapper, width: `${width}px` }} onClick={() => handleOnClick()}>
      <style>{customStyles}</style>
      <div style={{ ...styles.pdfContainer, height: `${height}px` }}>
        <div
          style={{
            ...styles.slideWrapper,
            transform: `translateX(${slideDirection * 100}%)`,
            opacity: slideDirection !== 0 ? 0 : 1,
          }}
          onTransitionEnd={() => setSlideDirection(0)}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
            <Viewer
              fileUrl={pdfUrl}
              defaultScale={defaultScale}
              plugins={[pageNavigationPluginInstance]}
              onDocumentLoad={(e) => setTotalPages(e.doc.numPages)}
            />
          </Worker>
        </div>
      </div>
      <div style={styles.slideButtons}>
        <button
          style={styles.button}
          onClick={(e) => {
            e.stopPropagation();
            handlePrevPage();
          }}
          disabled={currentPage === 0}
        >
          ‹
        </button>

        <button
          style={styles.button}
          onClick={(e) => {
            e.stopPropagation();
            handleNextPage();
          }}
          disabled={currentPage === totalPages - 1}
        >
          ›
        </button>
      </div>
    </div>
  );
};

export default PdfThumbnail;
