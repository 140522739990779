import React, { Component } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { isMoment } from "moment";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/ProtectedColumn";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import TableWrapper from "Common/components/Table/index";
import PageHeader from "Common/components/Page/PageHeader";
import ItemStatus from "Common/components/ItemSummary/status";
import localizeDate from "Common/localizeDate";
import actions from "Fault/redux/actions";
import { FaultSection } from "Common/components/Table/styles/responsiveTableWrapper.style";
import actionsDropdown from "Dropdown/redux/actions";

const { deleteFault } = actions;
const { listDropdown } = actionsDropdown;

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Faults",
  },
];
const defaultQuickFilter = {
  filterBy: "fleetType",
  //filterBy: "FaultType.id",
  defaultValue: "all",
  options: [
    {
      tab: "All Faults",
      key: "all",
    },
  ],
};

class ListFault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updatedOn: moment().valueOf(),
    };
  }
  componentWillMount() {
    if (!this.props.data.length && !this.props.loading) {
      this.props.listDropdown();
    }
  }

  getquickFilter = (faultTypes) => {
    let newQuickFilterOptions = defaultQuickFilter["options"];
    let faultTypesData = faultTypes.sort((a, b) => (a.title > b.title ? 1 : -1));
    for (let faultType of faultTypesData) {
      newQuickFilterOptions = newQuickFilterOptions.concat({
        tab: (faultType.title === "Asset" ? "Trailer" : faultType.title) + " Faults",
        key: faultType.title,
      });
    }
    return {
      ...defaultQuickFilter,
      options: newQuickFilterOptions,
    };
  };
  filterfaultTypes = (type, dropdownDatas) => {
    const faultTypes = dropdownDatas.filter((dropdownData) => {
      return dropdownData.dropdown == type;
    });
    return faultTypes;
  };

  onCreateClicked = () => {
    this.props.history.push("/faults/create");
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteFault(id, resolve, reject);
    })
      .then(() => {
        this.setState({
          loading: false,
          updatedOn: moment().valueOf(),
        });
        message.success("Successfully deleted fault");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting fault");
        console.log(error);
      }, this);
  };

  confirm = (e) => {
    var self = this;
    self.onDeleteClicked(e);
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/faults/" + id + "/edit/");
  };

  handleRowClick = (record) => {
    this.props.history.push("/faults/" + record.id);
  };

  render() {
    return (
      <LayoutWrapper>
        <PageHeader title={<IntlMessages id="pagetitle.listfault" />} breadcrumbs={breadcrumbs}>
          <Protected scope="write:users:driver">
            <p></p>
          </Protected>
        </PageHeader>
        <LayoutContent loading={this.state.loading}>
          <FaultSection>
            <TableWrapper
              defaultQuickFilterValue={this.props.match.params.id}
              history={this.props.history}
              handleRowClick={this.handleRowClick}
              updatedOn={this.state.updatedOn}
              columns={this.getColumns()}
              quickFilterElements={this.getquickFilter(this.filterfaultTypes("FaultType", this.props.data))}
              quickFilterStatus={this.props.match.params.id}
              url="fault/web"
              tmsTable
              createTitle="Create Fault"
              onClick={this.onCreateClicked}
              defaultSort={{ field: "faultDate", order: "descend" }}
              downloadUrl="reports/faults"
              reportTitle="Fault List"
            />
          </FaultSection>
        </LayoutContent>
      </LayoutWrapper>
    );
  }

  getDefaultColumns = () => {
    return [
      {
        title: "Registration Number | Asset ID",
        key: "id",
        dataIndex: "",
        render: (data) => <span>{data.rego || "N/A"}</span>,
      },
      {
        title: "Fleet Type",
        key: "fleetType",
        dateIndex: "fleetType",
        render: (data) => <span>{(data && data.fleetType) || "N/A"}</span>,
        sorter: { sorter },
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        width: 300,
      },
      {
        title: "Fault Date & Time",
        key: "faultDate",
        dataIndex: "faultDate",
        render: (date) => isMoment(localizeDate(date)) && localizeDate(date).format("DD/MM/YYYY HH:mm"),
        sorter: { sorter },
      },
      {
        title: "Fault Close Date & Time",
        key: "faultClosedAt",
        dataIndex: "faultClosedAt",
        render: (date) => isMoment(localizeDate(date)) && localizeDate(date).format("DD/MM/YYYY HH:mm"),
        sorter: { sorter },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, row) => <ItemStatus status={row.status} />,
        sorter: { sorter },
        width: 105,
      },
    ];
  };

  getColumns = () => {
    return getProtectedColumn(this.getDefaultColumns, this.onEditClicked, this.confirm, "users:driver");
  };
}

export default connect(
  (state) => ({
    loading: state.Dropdown.loading,
    data: state.Dropdown.data,
  }),
  { deleteFault, listDropdown }
)(ListFault);
