import styled from "styled-components";

const EntityWrapper = styled.div`
span{
	font-weight:bold;
}
 .detail-item{
     margin-bottom:15px;
 }
 .anticon{
     margin-right:10px;
 }
`;

export  default EntityWrapper;
