import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import moment from "moment";
import { isMoment } from "moment";
import toTitleCase from "Common/toTitleCase";
import { IncidentSection } from "Common/components/Table/styles/responsiveTableWrapper.style";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import PageHeader from "Common/components/Page/PageHeader";
import ItemStatus from "Common/components/ItemSummary/status";
import TableWrapper from "Common/components/Table/index";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/ProtectedColumn";
import IncidentAdvancedFilterForm from "Incident/components/form/IncidentAdvancedFilterForm";
import localizeDate from "Common/localizeDate";
import actions from "Incident/redux/actions";
import actionsDropdown from "Dropdown/redux/actions";

const { deleteIncidentDetail } = actions;
const { listDropdown } = actionsDropdown;

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Incidents",
  },
];

const defaultQuickFilter = {
  filterBy: "incidentType.id",
  defaultValue: "all",
  options: [
    {
      tab: "All Incident",
      key: "all",
    },
  ],
};

function capitalizeFirstLetter(capitalString) {
  const lowerCaseString = capitalString.toLowerCase();
  const requiredFormString =
    lowerCaseString == "near miss"
      ? lowerCaseString.replace("near miss", "Near Miss")
      : lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
  return requiredFormString;
}

const getquickFilter = (incidentTypes) => {
  let newQuickFilterOptions = defaultQuickFilter["options"];
  let incidentTypesData = incidentTypes.sort((a, b) => (a.title > b.title ? 1 : -1));
  for (let incidentType of incidentTypesData) {
    newQuickFilterOptions = newQuickFilterOptions.concat({
      tab: capitalizeFirstLetter(incidentType.title),
      key: incidentType.id,
    });
  }
  return {
    ...defaultQuickFilter,
    options: newQuickFilterOptions,
  };
};

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

class ListIncident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updatedOn: moment().valueOf(),
      subType: "all",
    };
  }

  filterIncidentTypes = (type, dropdownDatas) => {
    const incidentTypes = dropdownDatas.filter((dropdownData) => {
      return dropdownData.dropdown == type;
    });
    return incidentTypes;
  };

  componentWillMount() {
    if (!this.props.data.length && !this.props.loading) {
      this.props.listDropdown();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      subType: nextProps.match.params.id,
    });
  };

  onCreateClicked = () => {
    this.props.history.push("/incidents/create");
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/incidents/" + id + "/edit/");
  };

  confirm = (id) => {
    this.onDeleteClicked(id);
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteIncidentDetail(id, resolve, reject);
    })
      .then(() => {
        this.setState({
          loading: false,
          updatedOn: moment().valueOf(),
        });
        message.success("Successfully deleted incident");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting Incident");
        console.log(error);
      }, this);
  };

  getDefaultColumns = () => {
    return [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (title) => <span className="title">{title}</span>,
      },
      {
        title: "Type",
        dataIndex: "incidentType",
        key: "incidentType",
        render: (text, row) => <span className="incidentType">{(row.incidentType && toTitleCase(row.incidentType)) || ""}</span>,
      },
      {
        title: "Reported By",
        key: "userName",
        dataIndex: "userName",
      },
      {
        title: "Incident Date & Time",
        key: "incidentDate",
        dataIndex: "incidentDate",
        sorter: { sorter },
        render: (date) => isMoment(localizeDate(date)) && localizeDate(date).format("DD/MM/YYYY HH:mm"),
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: { sorter },
        render: (status) => <ItemStatus status={status.toLowerCase()} />,
      },
    ];
  };

  getColumns = () => {
    return getProtectedColumn(this.getDefaultColumns, this.onEditClicked, this.confirm, "incidents");
  };

  handleRowClick = (record) => {
    this.props.history.push("/incidents/" + record.id);
  };

  render() {
    return (
      <LayoutWrapper>
        <PageHeader title={<IntlMessages id="pagetitle.listIncident" />} breadcrumbs={breadcrumbs}>
          <Protected scope="write:incidents">
            <p></p>
          </Protected>
        </PageHeader>
        <LayoutContent loading={this.state.loading}>
          <IncidentSection>
            <TableWrapper
              defaultQuickFilterValue={this.props.match.params.id}
              history={this.props.history}
              handleRowClick={this.handleRowClick}
              updatedOn={this.state.updatedOn}
              columns={this.getColumns()}
              advancedFilterForm={IncidentAdvancedFilterForm}
              quickFilterElements={getquickFilter(this.filterIncidentTypes("incidentType", this.props.data))}
              url="incidents"
              quickFilterStatus={this.props.match.params.id}
              createTitle="Create Incident"
              onClick={this.onCreateClicked}
              defaultSort={{ field: "incidentDate", order: "descend" }}
              tmsTable
              downloadUrl="reports/incidents"
              reportTitle="Incident List"
            />
          </IncidentSection>
        </LayoutContent>
      </LayoutWrapper>
    );
  }
}
export default connect(
  (state) => ({
    // contacts:state.Contact.contacts,
    loading: state.Dropdown.loading,
    data: state.Dropdown.data,
  }),
  {
    ListIncident,
    deleteIncidentDetail,
    listDropdown,
  }
)(ListIncident);

// export default connect(null, { deleteIncidentDetail })(ListIncident);
