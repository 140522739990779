import React from "react";

import moment from "moment";
import { Row, Col, Table } from "antd";
import { localizeDate } from "Booking/components/BookingCreateForm";
import LayoutContent from "Common/components/layoutContent";
import styled from "styled-components";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import Column from "antd/lib/table/Column";
// import JobDetail from "./JobDetail";

const JobSheetContainer = ({ entity, loading, MEDIA_BASE_URL }) => {
  const {
    workHour,
    restHour,
    assets = [],
    allocations = [],
    departureDate,
    arrivalDate,
    comments,
    jobStop = [],
    operatorContact = {},
    driver,
    signature = {},
       startMeterReading,
       arrivalLocation,
     departureLocation,
     endMeterReading,
    driverFitToDrive
  } = entity;

  let processedListTrailer = [];
  let processedListSideLoader = [];
  let shiftHour = undefined,
  axleList = [];

  const breakList = jobStop.map(({ arrivalDate, departureDate }) => ({
    arrivalDate,
    departureDate,
    reason: 1
  }));

  const vehicle =
    (allocations[0] && allocations[0].vehicle && allocations[0].vehicle) || {};

  if (workHour || restHour) {
    let rh = (restHour ? restHour : "00:00:00").split(":");
    shiftHour = moment(workHour ? workHour : "00:00:00", "HH:mm:s")
      .add(rh[0], "h")
      .add(rh[1], "m")
      .add(rh[2], "s")
      .format("HH:mm:s");
  }

  //  processedListTrailer.push({
  // //   departTime: departureDate,
  //   //location: departureLocation,
  //    oddoMeter: startMeterReading,
  //    rego: vehicle.registrationNumber
  //  });

  if (assets.length) {
    assets.forEach(
   ({dockets = [], vehicle = {},  assetId,id, loadType, temperature }) => {
        if (dockets.length) {
          dockets.forEach(({docket, axle1, axle2, axle3, axle4, axle5
          }) => {
        
            const totalWeight= Math.floor(!!axle1? (axle1.weight):(null)) + Math.floor(!!axle2? (axle2.weight):(null)) + Math.floor(!!axle3? (axle3.weight):(null)) + Math.floor(!!axle4? (axle4.weight):(null)) + Math.floor(!!axle5? (axle5.weight):(null))
           
            axleList.push({
              trip: assetId,
              axle1,
              axle2,
              axle3,
              axle4,
              axle5,
             total:totalWeight,
             loadType:loadType?.title,
             temperature
            });
          });
        }
      }
    );
  }

  if (entity.assets) {
    entity.assets.forEach(({ dockets = [], assetId }) => {
      if (dockets.length) {
        dockets.forEach((docket) => {
          const dta = {
            rego: assetId,
            docketNumbers: docket.docketNumber.substring(docket.docketNumber.indexOf("-") + 1),
            ...docket    
          };
          docket.asset.assetType.title == 'Sideloader' ? (
          processedListSideLoader.push(dta)):(null)
        });
      }
    });
  }

  if (entity.assets) {
    entity.assets.forEach(({ dockets = [], assetId }) => {
     
      if (dockets.length) {
        dockets.forEach((docket) => {

          const dta = {
            rego: assetId,
            docketNumbers: docket.docketNumber.substring(docket.docketNumber.indexOf("-") + 1),
            ...docket    
          };
          docket.asset.assetType.title == 'Trailer' || docket.asset.assetType.title == 'B double' ? (
          processedListTrailer.push(dta)):(null)
           
        });
      }
    });
  }

  return (
    <LayoutContent loading={loading}>
      <div id="jobSheetWrapper">
        <StyledJobSheetContainer>
          <Row className="bordered">
            <Col
              span={12}
              style={{ borderRight: "1px solid #000" }}
              className="driver-information"
            >
              <Row className="bordered">
                <TextLine
                  label={<div>Driver Name:</div>}
                  seperator={false}
                  value={`${driver.firstName} ${driver.lastName}`}
                />
                <Col span={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Driver Signature:</span>
                  {signature ? (
                    <img
                      src={MEDIA_BASE_URL + signature.diskName}
                      alt="Driver Signature"
                      height="50"
                      width="80"
                    />
                  ) : null}
                </div>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ padding: 0 }}>
                  <Row 
                  // className="bordered"
                  >
                    <TextLine
                      width={24}
                      label="Start Time"
                      value={
                        (localizeDate(departureDate) &&
                          localizeDate(departureDate).format("HH:mm")) ||
                        "N/A"
                      }
                    />
                  </Row>
                  <Row 
                  // className="bordered"
                  >
                    <TextLine
                      width={24}
                      label="Finish Time"
                      value={
                        (localizeDate(arrivalDate) &&
                          localizeDate(arrivalDate).format("HH:mm")) ||
                        "N/A"
                      }
                    />
                  </Row>
                  <Row 
                  // className="bordered"
                  >
                    <TextLine
                      width={24}
                      label="Shift Hours"
                      value={shiftHour}
                    />
                  </Row>
                  <Row 
                  // className="bordered"
                  >
                    <TextLine width={24} label="Break Hours" value={restHour} />
                  </Row>
                  <Row >
                    <TextLine width={24} label="Work time" value={workHour} />
                  </Row>
                  <Row >
                  <TextLine
                      width={24}
                      label="Start Odometer"
                    value={`${startMeterReading?.speedoMeter}`}
                      // seperator={false}
                      // className="bordered"
                    />
                    </Row>
                    <Row>
                    <TextLine
                      width={24}
                      label="End Odometer"
                     value={`${endMeterReading ? endMeterReading.speedoMeter : "N/A" }`}
                      // seperator={false}
                      // className="bordered"
                    />

                    </Row>
                </Col>
                <Col span={16} style={{ padding: 0 }}>
                  <Row
                  //  className="bordered"
                   >
                    <TextLine
                      width={24}
                      label={<div>Company Name:</div>}
                      value={operatorContact?.firstName}
                      seperator={false}
                    />
                  </Row>
                  <Row>
                    <TextLine
                      width={8}
                      seperator={false}
                      label={<div>Employee ID:</div>}
                      value={driver.id}
                      // className="bordered"
                    />
                    <TextLine
                      width={8}
                      label="Vehicle Rego"
                      value={vehicle.registrationNumber}
                      // className="bordered"
                    />
                    <TextLine
                      width={8}
                      label="Fleet Number"
                      // className="bordered"
                    />
                  </Row>
                  <Row>
                    <TextLine
                      width={10}
                      label={<div>Drivers License No:</div>}
                      value={driver.driverId}
                      seperator={false}
                      // className="bordered"
                    />
                      
                    <TextLine
                      width={8}
                      label={<div>Date:</div>}
                      value={
                        departureDate
                          ? moment(departureDate).format("YYYY-MM-DD")
                          : undefined
                      }
                      seperator={false}
                      // className="bordered"
                    />
                    <TextLine
                      width={6}
                      label={<div>Day:</div>}
                      value={
                        departureDate
                          ? moment(departureDate).format("dddd")
                          : undefined
                      }
                      seperator={false}
                      // className="bordered"
                    />
                    
                  </Row>
                  <Row>
                  <TextLine
                      width={24}
                      label={<div>Departure Location</div>}
                      value={`${departureLocation.address}`}
                      seperator={false}
                      // className="bordered"
                    />
                  </Row>
                  <Row>
                  <TextLine
                      width={24}
                      label={<div>Arrival Location</div>}
                      value={`${arrivalLocation ? arrivalLocation.address : 'N/A'}`}
                      seperator={false}
                      // className="bordered"
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="comment-section">
              <Row>
                <Col span={24}>
                  <Table
                    dataSource={axleList}
                    columns={axleColumns}
                    pagination={false}
                    size="small"
                    bordered
                  />
                  <TextLine
                  label={""}
                  seperator={false}
                className="p5"
                  value ="*All Weight in tons"/>
                  
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    height: 150,
                    padding: 5
                  }}
                >
                  <h3 style={{ textDecoration: "underline" }}>
                    Comments Section :
                  </h3>
                  <p>{comments || "N/A"}</p>
                </Col>
              </Row>
            </Col>
          </Row>
         
        
          {(processedListTrailer).length === 0? (null):(  
          <Row>
          <Row>
            <Col
              span={24}
              style={{
                padding: "5px 10px"
              }}
              className="bordered"
            >
              <strong>
               Trailer 
              </strong>
            </Col>
          </Row>

          <Row className="bordered">
            <Col span={24}>
              <Table
                dataSource={processedListTrailer}
                columns={assetsColumnsTrailer}
                pagination={false}
                size="small"
                bordered
              />
            </Col>
          </Row>
          </Row>
          )}

           
         {(processedListSideLoader).length === 0? (null):( 
          <Row> 
          <Row>
          <Col
            span={24}
            style={{
              padding: "5px 10px"
            }}
            className="bordered"
          >
            <strong>
             SideLoader
            </strong>
          </Col>
        </Row>
          <Row className="bordered">
            <Col span={24}>
              <Table
                dataSource={processedListSideLoader}
                columns={assetsColumnsSideLoader}
                pagination={false}
                size="small"
                bordered
              />
            </Col>
          </Row>
          </Row>
          )}


          <Row>
            <Col
              span={24}
              style={{
                padding: "5px 10px",
                
              }}
              className="bordered">
              <strong>
                Has you vehicle been sanitised at the end of your shift?
                [YES/NO]
              </strong>
            </Col>
          </Row>
          <Row className="bordered">
            <Col span={12} className="p5">
              <Row>
                <Col span={18}>
                  <Table
                    dataSource={breakList}
                    pagination={false}
                    size="small"
                    bordered
                  >
                    <ColumnGroup title="Breaks">
                      <Column
                        title="Reason Code"
                        dataIndex="reason"
                        key="reason"
                      />
                      <Column
                        title="Start Time"
                        dataIndex="arrivalDate"
                        key="arrivalDate"
                        render={(date) =>
                          date ? (
                            <p>{localizeDate(date).format("HH:mm")}</p>
                          ) : null
                        }
                      />
                      <Column
                        title="End Time"
                        dataIndex="departureDate"
                        key="departureDate"
                        render={(date) =>
                          date ? (
                            <p>{localizeDate(date).format("HH:mm")}</p>
                          ) : null
                        }
                      />
                    </ColumnGroup>
                    <ColumnGroup title="Delays">
                      <Column
                        title="Reason Code"
                        dataIndex="reasonCode"
                        key="reasonCode"
                      />
                      <Column
                        title="Start Time"
                        dataIndex="startTime"
                        key="startTime"
                      />
                      <Column
                        title="End Time"
                        dataIndex="endTime"
                        key="endTime"
                      />
                    </ColumnGroup>
                  </Table>
                </Col>
                <Col span={6} className="reason-container pl-20">
                  <h3 style={{ textDecoration: "underline" }}>
                    Reason Codes :
                  </h3>{" "}
                  <ol type="1" className="pl20">
                    <li>Rest Break</li>
                    <li>Refuelling</li>
                    <li>Load not ready</li>
                    <li>Truck in dock</li>
                    <li>Maintenance</li>
                    <li>Truck/Trailler breakdown</li>
                    <li>Traffic accident</li>
                    <li>Wait for change over</li>
                    <li>Delay at back door</li>
                    <li itemType="D">Non Productive Time</li>
                  </ol>
                </Col>
              </Row>
              {/* <Row className="stops-footer">
                <Col span={24}>
                  <Row>
                    <Col span={4}> OF</Col>
                    <Col span={10}>
                      Supervisor sign off: Everything complete?
                      {signature ? (
                        <img
                          src={`${MEDIA_BASE_URL}/${signature.diskName}`}
                          alt="Driver Signature"
                          height="50"
                          width="80"
                        />
                      ) : null}
                    </Col>
                    <Col span={8}>Date of Sign off:</Col>
                  </Row>
                </Col>
              </Row> */}
            </Col>
            <Col span={12} className="bordered declearation">
              <Row className="reason-container">
                <Col span={24}>
                  <h3 style={{ textDecoration: "underline" }}>
                    Driver Declaration
                  </h3>
                  <ol type="1" className="pl20">
                    {driverDeclaration.map((d, idx) => (
                      <li key={`driver-declaration-${idx}`}>{d}</li>
                    ))}
                  </ol>
                </Col>
              </Row>
              <Row className="stops-footer">
                <Col span={24}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={2}>Date:</Col>
                    <Col span={6}>
                      {moment(departureDate).format("YYYY-MM-DD")}
                    </Col>
                    <Col span={2}>Signed:</Col>
                    <Col span={12}>
                      {driverFitToDrive && signature ? (
                        <img
                          src={MEDIA_BASE_URL + signature.diskName}
                          alt="Driver Signature"
                          height="50"
                          width="80"
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledJobSheetContainer>
      </div>
    </LayoutContent>
  );
};
export default JobSheetContainer;

const TextLine = ({
  label = "Driver Name",
  value = "N/A",
  width = 12,
  seperator = true,
  ...props
}) => (
  <Col span={width} {...props}>
    {label}
    {(seperator && ":") || ""} {value}
  </Col>
);

const StyledJobSheetContainer = styled.span`
  li {
    font-size: 13px;
  }

  background-color: #fff;
  padding: 5px;
  .bordered {
    border: 1px solid #000;
  }
  .declearation {
    border: none;
    border-left: 1px solid #000;
    padding: 5px !important;
  }
  .p5 {
    padding: 5px !important;
  }
  .pl20 {
    padding-left: 20px !important;
  }
  .driver-information {
    [class*="ant-col-"] {
      padding: 10px 5px;
    }
  }
  .comment-section {
    border-width: 0 0 0 1px;
    border-color: #000;
  }
  .stops-footer {
    border-top: 1px solid #000;
    padding: 12px 0;
    height: 60px;
  }
  .reason-container {
    padding: 5px !important;
    min-height: 280px;
    border-width: 0 0 0 1px;
    border-color: #000;
  }
  .text-center {
    display: flex;
    align-items: center;
  }
  .sanitized-section {
    border-width: 1px 0 0 0;
    border-color: #000;
  }
`;

const axleColumns = [
  {
    title: "Asset Id",
    dataIndex: "trip",
    key: "trip"
  },
  {
    title: "Load Type",
    dataIndex: "loadType",
    key: "loadType"
  },
  {
    title: "Temperature",
    dataIndex: "temperature",
    key: "temperature"
  },
  {
    title: "Steer",
    dataIndex: "axle1.weight",
    key: "axle1"
  },
  {
    title: "Drive",
    dataIndex: "axle2.weight",
    key: "axle2"
  },
  {
    title: "Axle(A)",
    dataIndex: "axle3.weight",
    key: "axle3"
  },
  {
    title: "Axle(B)",
    dataIndex: "axle4.weight",
    key: "axle4"
  },
,
  // {
  //   title: "5th Axle",
  //   dataIndex: "axle5.weight",
  //   key: "axle5"
  // },
  {
    title: "Total(GVM)",
    dataIndex: "total",
    key:"total"
  },
 
];

const assetsColumnsSideLoader = [
  {
    title: " Trailer Number/Rego",
    dataIndex: "rego",
    key: "rego"
  },
  {
    title: "Container Number",
    dataIndex: "docketNumbers",
    key: "docketNo"
  },
  {
    title: "Customer",
    dataIndex: "costumerStoreNumber",
    key: "customer"
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Weight or MT",
    dataIndex: "weight",
    key: "weight",
  },
  {
    title: "From",
    dataIndex: "pickupLocation",
    key: "pickupLocation",
  },

  {
    title: "Arrive",
    dataIndex: "arrivalTime",
    key: "arrivalTimePickup",
    render: (date) =>
      date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  },
 
  {
    title: "Depart",
    dataIndex: "departureTime",
    key: "departTimePickup",
    render: (date) =>
      date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  },


  {
    title: "To",
    dataIndex: "destinationLocation",
    key: "destinationLocation",
  },
  {
    title: "Arrive",
    dataIndex: "destinationArrivalTime",
    key: "arrivalTimeDestination",
    render: (date) =>
      date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  },
  {
    title: "Depart",
    dataIndex: "destinationDepartureTime",
    key: "departTimeDestination",
    render: (date) =>
      date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments"
  },
  
];

const assetsColumnsTrailer = [
  {
    title: "DC/Store Number",
    dataIndex: "costumerStoreNumber",
    key: "customer"
  },
  {
    title: "Pickup location",
    dataIndex: "pickupLocation",
    key: "pickupLocation",
  },
  {
    title: "Depart Time",
    dataIndex: "departureTime",
    key: "departTime",
    render: (date) =>
      date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  },
  {
    title: "Number/Rego",
    dataIndex: "rego",
    key: "rego"
  },
  {
    title: "Docket Number",
    dataIndex: "docketNumbers",
    key: "docketNo"
  },
  {
    title: "Drop-off location",
    dataIndex: "destinationLocation",
    key: "destinationLocation",
  },
  {
    title: "Arrival Time",
    dataIndex: "arrivalTime",
    key: "arrivalTime",
    render: (date) =>
      date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  },
  // {
  //   title: "Docking Time",
  //   dataIndex: "dockingTime",
  //   key: "dockingTime",
  //   render: (date) =>
  //     date ? <p>{localizeDate(date).format("HH:mm")}</p> : null
  // },
 
  // {
  //   title: "Location",
  //   dataIndex: "location.address",
  //   key: "location"
  //   // render: (location) => (date ? <p>{new Date(date).toUTCString()}</p> : null)
  // },
  {
    title: "Unit Type",
    dataIndex: "unitType",
    key: "unitType"
  },
  {
    title: "Units in",
    dataIndex: "unitsIn",
    key: "unitsIn"
  },
  {
    title: "Units out",
    dataIndex: "unitsOut",
    key: "unitsOut"
  },
  {
    title: "Load Restraint",
    dataIndex: "loadRestraint",
    key: "loadRestraint",
    render: (loadRestraint) => (loadRestraint ? "Yes" : "No"),
  },
  {
    title: "Odometer",
    dataIndex: "oddoMeter.speedoMeter",
    key: "oddoMeter"
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments"
  },

];

const driverDeclaration = [
  "I am personally fit to operate the vehicle.",
  "I am medically fit to operate the vehicle.",
  "I have completed the minimum rest breaks as required under Standard/Basic Fatigue Management.",
  "I hold a current and valid licence for the vehicle I intend to operate.",
  "I am not suffering from the effects of alcohol/drugs(prescribed or illicit) which may prevent me from driving.",
  "I am not suffering from sleep apnoea/deprivation which may affect my alertness and ability to drive.",
  "I will ensure that my vehicle is appropriate & approved for the route I will take.",
  "I have sufficient hours to complete my allocated tasks.",
  "My “Drivers Daily Runsheet“ will be completed via FCS App provided in accordance with regulations and a signed copy will be provided to the record keeper.",
  "I agree that it is my responsibility as a driver to follow all rules and regulations.",
  "My personal circumstances have not changed, which could affect my ability to drive/work/safely.",
  "I declare that the information that I have provided is true and honest and that all undertakings above will be carried out."
];
