import styled from "styled-components";

const ResponsiveTableWrapper = styled.div`
@media 
only screen and (max-width: 420px){
.ant-table-tbody > tr > td{
  border-bottom: 1px solid #eee; 
  padding:6px;
}
}
i {
  color: #9A9FA8;
    // margin-right: 15px;
  cursor: pointer;
  
}

  /* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

@media 
only screen and (max-width: 1024px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

  /* Force table to not be like tables anymore */
  .tableStyled  table, .tableStyled  thead,.tableStyled  tbody,.tableStyled  th, .tableStyled  td, .tableStyled  tr { 
    display: block; 
  }
  
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  
  tr { border: 2px solid #ccc;border-radius: 8px;background-color: #FAFAFA}
  
  td { 
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee; 
    position: relative;
    padding-left: 50%;  
    //background-color: red;
   }
  
  td:before { 
    padding-right: 10px; 
    white-space: nowrap;
    font-weight:lighter;
    //font-family: fangsong;
    //background-color: #ff0000;
  }
 `;

const UserSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(2):before {
      content: "Full Name: ";
    }
    td:nth-child(3):before {
      content: "Organisation: ";
    }
    td:nth-child(4):before {
      content: "Phone: ";
    }
    td:nth-child(5):before {
      content: "Status: ";
    }
    td:nth-child(6):before {
      content: "User Type: ";
    }
    td:nth-child(7):before {
      content: "Actions: ";
    }
  }
`;

const DriverSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(2):before {
      content: "Full Name: ";
    }
    td:nth-child(3):before {
      content: "Phone: ";
    }
    td:nth-child(4):before {
      content: "Medical Expiry: ";
    }
    td:nth-child(5):before {
      content: "Lisence Expiry Date:";
    }
    td:nth-child(6):before {
      content: "Status: ";
    }
    td:nth-child(7):before {
      content: "Actions: ";
    }
  }
`;

const DriverReportSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Full Name: ";
    }
    td:nth-child(2):before {
      content: "Phone: ";
    }
    td:nth-child(3):before {
      content: "License No.: ";
    }
    td:nth-child(4):before {
      content: "License Type.:";
    }
    td:nth-child(5):before {
      content: "License Expiry Date: ";
    }
    td:nth-child(6):before {
      content: "Visa Status: ";
    }
    td:nth-child(7):before {
      content: "Visa Expiry: ";
    }
    td:nth-child(8):before {
      content: "Medical Expiry Date: ";
    }
    td:nth-child(9):before {
      content: "Driver History: ";
    }
    td:nth-child(10):before {
      content: "Police Check: ";
    }
  }
`;

const DriverFitForDutySection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Declaration Date: ";
    }
    td:nth-child(2):before {
      content: "Driver: ";
    }
    td:nth-child(3):before {
      content: "Registration Number: ";
    }
    td:nth-child(4):before {
      content: "Fatigue Self Assessment:";
    }
    td:nth-child(5):before {
      content: "Driver Declaration: ";
    }
    td:nth-child(6):before {
      content: "COVID-19 Assessment: ";
    }
    td:nth-child(7):before {
      content: "Status: ";
    }
    td:nth-child(8):before {
      content: "Action: ";
    }
  }
`;

const VehicleServiceSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Vehicle Registration Number: ";
    }
    td:nth-child(2):before {
      content: "Service Type: ";
    }
    td:nth-child(3):before {
      content: "Service Interval Kms: ";
    }
    td:nth-child(4):before {
      content: "Service Interval Days:";
    }
    td:nth-child(5):before {
      content: "Last Service Kms: ";
    }
    td:nth-child(6):before {
      content: "Next Service Kms: ";
    }
    td:nth-child(7):before {
      content: "Current Kms: ";
    }
    td:nth-child(8):before {
      content: "Due on Kms: ";
    }
    td:nth-child(9):before {
      content: "Last Service Date: ";
    }
    td:nth-child(10):before {
      content: "Next Service Date: ";
    }
  }
`;

const VehicleInspectionSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Inspection Date: ";
    }
    td:nth-child(2):before {
      content: "Registration Number: ";
    }
    td:nth-child(3):before {
      content: "Inspected By: ";
    }
    td:nth-child(4):before {
      content: "Item Checked: ";
    }
    td:nth-child(5):before {
      content: "Status:";
    }
    td:nth-child(6):before {
      content: "Download: ";
    }
  }
`;

const FuelSummarySection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Date: ";
    }
    td:nth-child(2):before {
      content: "Vehicle: ";
    }
    td:nth-child(3):before {
      content: "Driver: ";
    }
    td:nth-child(4):before {
      content: "Location: ";
    }
    td:nth-child(5):before {
      content: "Quantity:";
    }
    td:nth-child(6):before {
      content: "Amount: ";
    }
  }
`;

const IncidentReportSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Reported By: ";
    }
    td:nth-child(2):before {
      content: "Report Submission Date: ";
    }
    td:nth-child(3):before {
      content: "Incident Title: ";
    }
    td:nth-child(4):before {
      content: "Incident Date & Time: ";
    }
    td:nth-child(5):before {
      content: "Incident Type:";
    }
  }
`;

const VehicleSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "SN: ";
    }
    td:nth-child(2):before {
      content: "Vehicle Internal ID: ";
    }
    td:nth-child(3):before {
      content: "Rego";
    }
    td:nth-child(5):before {
      content: "Fleet Type: ";
    }
    td:nth-child(6):before {
      content: "Make: ";
    }
    td:nth-child(7):before {
      content: "Telematics: ";
    }
    td:nth-child(8):before {
      content: "Registration Expiry: ";
    }
    td:nth-child(9):before {
      content: "Next Service Date: ";
    }
    td:nth-child(10):before {
      content: "Kilometers Till Next Service: ";
    }
    td:nth-child(11):before {
      content: "Status ";
    }
    /* Hide the 4th column on smaller screens */
    td:nth-child(4) {
      display: none;
    }
  }
`;

const AdminVehicleSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "S.N: ";
    }
    td:nth-child(2):before {
      content: "Vehicle Internal ID: ";
    }
    td:nth-child(3):before {
      content: "Vehicle Rego: ";
    }
    td:nth-child(4):before {
      content: "Company: ";
    }
    td:nth-child(5):before {
      content: "Fleet Type: ";
    }
    td:nth-child(6):before {
      content: "Make: ";
    }
    td:nth-child(7):before {
      content: "Telematics: ";
    }
    td:nth-child(8):before {
      content: "Registration Expiry: ";
    }
    td:nth-child(9):before {
      content: "Next service Date: ";
    }
    td:nth-child(10):before {
      content: "Kilometers Till Next Service: ";
    }
    td:nth-child(11):before {
      content: "Status: ";
    }

    //td:nth-child(9):before { content: "Action: ";}
  }
`;

const BookingSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "S.N: ";
    }
    td:nth-child(2):before {
      content: "Job ID: ";
    }
    td:nth-child(3):before {
      content: "Driver: ";
    }
    td:nth-child(4):before {
      content: "Vehicle Registration: ";
    }
    td:nth-child(5):before {
      content: "Depature Location: ";
    }
    td:nth-child(6):before {
      content: "Departure Date and Time: ";
    }
    td:nth-child(7):before {
      content: "Arrival Location: ";
    }
    td:nth-child(8):before {
      content: "Arrival Date and Time: ";
    }
    td:nth-child(9):before {
      content: "Job Status: ";
    }
    td:nth-child(10):before {
      content: "Action: ";
    }
    // td:nth-child(11):before { content: "Action";}
  }
`;

const AdminBookingSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "S.N: ";
    }
    td:nth-child(2):before {
      content: "Job ID: ";
    }
    td:nth-child(3):before {
      content: "Driver: ";
    }
    td:nth-child(4):before {
      content: "Vehicle: ";
    }
    td:nth-child(5):before {
      content: "Company: ";
    }
    td:nth-child(6):before {
      content: "Depature Location: ";
    }
    td:nth-child(7):before {
      content: "Departure Date and Time: ";
    }
    td:nth-child(8):before {
      content: "Arrival Location: ";
    }
    td:nth-child(9):before {
      content: "Arrival Date and Time: ";
    }
    td:nth-child(10):before {
      content: "Job Status: ";
    }
  }
`;
const DocumentsSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Serial No.: ";
    }
    td:nth-child(2):before {
      content: "Document Name: ";
    }
    td:nth-child(3):before {
      content: "Creation Date: ";
    }
    td:nth-child(4):before {
      content: "Review Date: ";
    }
    td:nth-child(5):before {
      content: "Created By: ";
    }
    td:nth-child(6):before {
      content: "Number of Documents: ";
    }
    td:nth-child(7):before {
      content: "Document Sub Type: ";
    }
  }
`;

const JobDocumentsSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Creation date: ";
    }
    td:nth-child(2):before {
      content: "Driver Name: ";
    }
    td:nth-child(3):before {
      content: "Document Name: : ";
    }
    td:nth-child(4):before {
      content: "Route From: ";
    }
    td:nth-child(5):before {
      content: "Route To: ";
    }
    td:nth-child(6):before {
      content: "Uploads: ";
    }
  }
`;

const PassengerBooking = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Name";
    }
    td:nth-child(2):before {
      content: "Pick Up Location";
    }
    td:nth-child(3):before {
      content: "Pick Up Date & Time";
    }
    td:nth-child(4):before {
      content: "Check In";
    }
    td:nth-child(5):before {
      content: "Drop Off Location";
    }
    td:nth-child(6):before {
      content: "Drop Off Date & Time";
    }
    td:nth-child(7):before {
      content: "Check Out";
    }
    td:nth-child(8):before {
      content: "Vehicle";
    }
  }
`;
const AllocationSection = styled.div``;

const ContactSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Name";
    }
    td:nth-child(2):before {
      content: "Phone";
    }
    td:nth-child(3):before {
      content: "Status";
    }
    td:nth-child(4):before {
      content: "Contact Type";
    }
    td:nth-child(5):before {
      content: "Actions";
    }
  }
`;

const PassengerSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(2):before {
      content: "Address";
    }
    td:nth-child(3):before {
      content: "Contact Number";
    }
    td:nth-child(4):before {
      content: "Client";
    }
    td:nth-child(5):before {
      content: "Status";
    }
    td:nth-child(6):before {
      content: "Action";
    }
  }
`;

const MaintainanceSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Vehicle Rego: ";
    }
    td:nth-child(2):before {
      content: "Service Type: ";
    }
    td:nth-child(3):before {
      content: "Last Service Kms: ";
    }
    td:nth-child(4):before {
      content: "Next Service Kms: ";
    }
    td:nth-child(5):before {
      content: "Current Kms: ";
    }
    td:nth-child(6):before {
      content: "Due In Kms: ";
    }
    td:nth-child(7):before {
      content: "Last Service Date: ";
    }
    td:nth-child(8):before {
      content: "Next Service Date: ";
    }
  }
`;

const MaintainanceCompletedSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Vehicle Registration: ";
    }
    td:nth-child(2):before {
      content: "Service Type: ";
    }
    td:nth-child(3):before {
      content: "Service Kms: ";
    }
    td:nth-child(4):before {
      content: "Service Date: ";
    }
    td:nth-child(5):before {
      content: "Service Amount: ";
    }
    td:nth-child(6):before {
      content: "Status: ";
    }
    td:nth-child(7):before {
      content: "Action: ";
    }
  }
`;

const MaintainanceRepairSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Vehicle Registration: ";
    }
    td:nth-child(2):before {
      content: "Repair Kms: ";
    }
    td:nth-child(3):before {
      content: "Repair Date: ";
    }
    td:nth-child(4):before {
      content: "Repair Amount: ";
    }
    td:nth-child(5):before {
      content: "Status: ";
    }
    td:nth-child(6):before {
      content: "Action: ";
    }
  }
`;

const SupplierSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Business Name: ";
    }
    td:nth-child(2):before {
      content: "Phone No: ";
    }
    td:nth-child(3):before {
      content: "Contact Person: ";
    }
    td:nth-child(4):before {
      content: "Address: ";
    }
    td:nth-child(5):before {
      content: "Suburb: ";
    }
    td:nth-child(6):before {
      content: "State: ";
    }
    td:nth-child(7):before {
      content: "Post Code: ";
    }
    td:nth-child(8):before {
      content: "Status: ";
    }
    td:nth-child(9):before {
      content: "Service Provided: ";
    }
  }
`;

const DriverSignupSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(3):before {
      content: "Phone: ";
    }
    td:nth-child(4):before {
      content: "License Expiry: ";
    }
    td:nth-child(5):before {
      content: "License Type: ";
    }
    td:nth-child(6):before {
      content: "Status: ";
    }
  }
`;

const AssetTypeSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Type: ";
    }
    td:nth-child(2):before {
      content: "No. of inspection Item: ";
    }
    td:nth-child(3):before {
      content: "Action: ";
    }
  }
`;

const FaultSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Vehicle Rego: ";
    }
    td:nth-child(2):before {
      content: "Fleet Type: ";
    }
    td:nth-child(3):before {
      content: "Description: ";
    }
    td:nth-child(4):before {
      content: "Fault Date & Time:  ";
    }
    td:nth-child(5):before {
      content: "Fault Close Date & Time: ";
    }
    td:nth-child(6):before {
      content: "Status: ";
    }
    td:nth-child(7):before {
      content: "Action: ";
    }
  }
`;
const AssetsSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Date: ";
    }
    td:nth-child(2):before {
      content: "Type: ";
    }
    td:nth-child(3):before {
      content: "Rego/ID: ";
    }
    td:nth-child(4):before {
      content: "Inspected By: ";
    }
    td:nth-child(5):before {
      content: "Status: ";
    }
  }
`;

const FatigueSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "S.N: ";
    }
    td:nth-child(2):before {
      content: "Driver: ";
    }
    td:nth-child(3):before {
      content: "Last 14 Days: ";
    }
    td:nth-child(4):before {
      content: "Last 7 Days: ";
    }
  }
`;
const NCRSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "S.N: ";
    }
    td:nth-child(2):before {
      content: "NCR Number: ";
    }
    td:nth-child(3):before {
      content: "NCR Date: ";
    }
    td:nth-child(4):before {
      content: "Repoted On: ";
    }
    td:nth-child(5):before {
      content: "Repoted By: ";
    }
    td:nth-child(6):before {
      content: "NCR Type: ";
    }
    td:nth-child(7):before {
      content: "Description: ";
    }
    td:nth-child(8):before {
      content: "Status: ";
    }
    td:nth-child(9):before {
      content: "Driver: ";
    }
    td:nth-child(10):before {
      content: "Vehicle: ";
    }
    td:nth-child(11):before {
      content: "Action: ";
    }
  }
`;

const FuelSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "S.N: ";
    }
    td:nth-child(2):before {
      content: "Date: ";
    }
    td:nth-child(3):before Dat {
      content: "Vehicle Rego:";
    }
    td:nth-child(4):before {
      content: "Driver: ";
    }
    td:nth-child(5):before {
      content: "Location: ";
    }
    td:nth-child(6):before {
      content: "Quantity: ";
    }
    td:nth-child(7):before {
      content: "Amount: ";
    }
    td:nth-child(8):before {
      content: "KMs: : ";
    }
    td:nth-child(9):before {
      content: "Fuel Filled On: : ";
    }
  }
`;

const ContactPassenger = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Name";
    }
    td:nth-child(2):before {
      content: "Relationship To Passenger";
    }
    td:nth-child(3):before {
      content: "Mobile Number";
    }
    td:nth-child(4):before {
      content: "Email";
    }
    td:nth-child(5):before {
      content: "Notify";
    }
  }
`;
const BookingPassenger = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Run Number";
    }
    td:nth-child(2):before {
      content: "Pick Up Location";
    }
    td:nth-child(3):before {
      content: "Pick Up Date & Time";
    }
    td:nth-child(4):before {
      content: "Check In";
    }
    td:nth-child(5):before {
      content: "Drop Off Location";
    }
    td:nth-child(6):before {
      content: "Drop Off Date & Time";
    }
    td:nth-child(7):before {
      content: "Check Out";
    }
    td:nth-child(8):before {
      content: "Vehicle";
    }
  }
`;

const IncidentSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Title";
    }
    td:nth-child(2):before {
      content: "Type";
    }
    td:nth-child(3):before {
      content: "Reported By";
    }
    td:nth-child(4):before {
      content: "Incident Date & Time";
    }
    td:nth-child(5):before {
      content: "Status";
    }
    td:nth-child(6):before {
      content: "Action";
    }
  }
`;

const FeedbackSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Title";
    }
    td:nth-child(2):before {
      content: "Booking Departure Date";
    }
    td:nth-child(3):before {
      content: "Booking Arrival Location";
    }
    td:nth-child(4):before {
      content: "Booking Departure Location";
    }
    td:nth-child(5):before {
      content: "Allocation Vehicle ID";
    }
    td:nth-child(6):before {
      content: "Allocation Driver ID";
    }
    td:nth-child(7):before {
      content: "Contact";
    }
    td:nth-child(8):before {
      content: "Status";
    }
    td:nth-child(9):before {
      content: "Action";
    }
  }
`;

const RouteSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Route ID";
    }
    td:nth-child(2):before {
      content: "Name";
    }
    td:nth-child(3):before {
      content: "Departure Location";
    }
    td:nth-child(4):before {
      content: "Arrival Location";
    }
    td:nth-child(5):before {
      content: "Status";
    }
    td:nth-child(6):before {
      content: "Action";
    }
  }
`;

const RunsSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Run Number";
    }
    td:nth-child(2):before {
      content: "Client Contact";
    }
    td:nth-child(3):before {
      content: "Route";
    }
    td:nth-child(4):before {
      content: "Action";
    }
  }
`;

const ReportSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Route";
    }
    td:nth-child(2):before {
      content: "Run Number";
    }
    td:nth-child(3):before {
      content: "Scheduled Location";
    }
    td:nth-child(4):before {
      content: "Scheduled Date & Time";
    }
    td:nth-child(5):before {
      content: "Actual Date & Time";
    }
    td:nth-child(6):before {
      content: "Actual Variation";
    }
    td:nth-child(7):before {
      content: "Vehicle Internal ID";
    }
    td:nth-child(8):before {
      content: "Driver ID";
    }
    td:nth-child(9):before {
      content: "Client";
    }
    td:nth-child(10):before {
      content: "Operator";
    }
    td:nth-child(11):before {
      content: "Action";
    }
  }
`;

const ToolboxSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Topic";
    }
    td:nth-child(2):before {
      content: "Creation Date";
    }
    td:nth-child(3):before {
      content: "Issued By";
    }
    td:nth-child(4):before {
      content: "Action";
    }
  }
`;

const NotificationSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Time:";
    }
    td:nth-child(2):before {
      content: "Event:";
    }
    td:nth-child(3):before {
      content: "Source:";
    }
    td:nth-child(4):before {
      content: "Actions:";
    }
  }
`;
const ClientSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Name: ";
    }
    td:nth-child(2):before {
      content: "Address: ";
    }
    td:nth-child(3):before {
      content: "ABN: ";
    }
    td:nth-child(4):before {
      content: "Email: ";
    }
  }
`;

const InvoiceSection = styled.div`
  @media only screen and (max-width: 1024px) {
    td:nth-child(1):before {
      content: "Invoice ID: ";
    }
    td:nth-child(2):before {
      content: "Billed To: ";
    }
    td:nth-child(3):before {
      content: "Issue Date: ";
    }
    td:nth-child(4):before {
      content: "Due Date: ";
    }
    td:nth-child(5):before {
      content: "Amount: ";
    }
    td:nth-child(6):before {
      content: "Status: ";
    }
  }
`;

const DisabledResponsiveTableWrapper = styled.div`
@media 
only screen and (max-width: 1024px) { 
  overflow-y: auto;
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      word-break: normal !important;
  }
`;

export {
  AllocationSection,
  ResponsiveTableWrapper,
  UserSection,
  DriverSection,
  VehicleSection,
  AdminVehicleSection,
  BookingSection,
  AdminBookingSection,
  PassengerBooking,
  ContactSection,
  PassengerSection,
  ContactPassenger,
  BookingPassenger,
  IncidentSection,
  FeedbackSection,
  RouteSection,
  RunsSection,
  ReportSection,
  DocumentsSection,
  JobDocumentsSection,
  FuelSection,
  DriverSignupSection,
  SupplierSection,
  AssetTypeSection,
  FaultSection,
  AssetsSection,
  FatigueSection,
  NCRSection,
  MaintainanceSection,
  DisabledResponsiveTableWrapper,
  VehicleInspectionSection,
  DriverReportSection,
  DriverFitForDutySection,
  VehicleServiceSection,
  FuelSummarySection,
  IncidentReportSection,
  MaintainanceCompletedSection,
  MaintainanceRepairSection,
  ToolboxSection,
  NotificationSection,
  ClientSection,
  InvoiceSection,
};
