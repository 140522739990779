import React, { Component } from "react";
import { Field, FieldArray } from "redux-form";
import { Row, Col, Form, Icon } from "antd";

import IntlMessages from "Common/components/intlMessages";
import Button from "Common/components/button";
import InputField from "Common/components/InputField";
// import Section from "Common/components/section";

// const required = (value) => (value ? undefined : "This field is required");

const FormItem = Form.Item;
// const FORM_NAME = "createService";

class ServiceInfo extends Component {
  render() {
    const { handleDispatch, editingMode, entity, handleDelete, titleforlabel } = this.props;
    return (
      <Col lg={24} md={24}>
        <FieldArray
          name="service"
          component={ServiceInfoForm}
          handleDispatch={handleDispatch}
          editingMode={editingMode}
          entity={entity}
          handleDelete={handleDelete}
          titleforlabel={titleforlabel}
        />
      </Col>
    );
  }
}

export default ServiceInfo;

class ServiceInfoForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      serviceData: {},
      repairData: {},
      type: "",
    };
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.entity && nextProps.entity !== this.props.entity) {
  //       if (!this.props.editingMode) {
  //       }
  //       else {
  //         this.setState({type:nextProps.entity.type})
  //       }
  //       const entity = nextProps.entity;
  //     }
  //   }

  render() {
    const {
      fields,
      meta: { error, submitFailed },
      editingMode,
      entity,
    } = this.props;
    // const {type} = this.state;

    return (
      <div>
        {!editingMode ? (
          !this.props.titleforlabel ? (
            <div>
              <h2>
                {" "}
                <IntlMessages id="forms.label.serviceinfo" />{" "}
              </h2>

              {fields.map((service, index) => {
                const { serviceData } = this.state;
                let data = serviceData[index];
                if (!data) {
                  data = editingMode && entity && entity["service"] ? entity["service"] : [];
                }
                // let serId = editingMode && entity && entity["service"] ? entity["service"] : false;
                return (
                  <div key={index}>
                    <Row gutter={[16, 16]}>
                      <Col lg={20} md={20}>
                        <Row gutter={48}>
                          <Col lg={12} md={12}>
                            <FormItem>
                              <label>
                                <IntlMessages id="forms.label.serviceDes" />{" "}
                              </label>
                              <Field name={`${service}.description`} component={InputField} />
                            </FormItem>
                          </Col>
                          <Col lg={12} md={12}>
                            <FormItem>
                              <label>
                                <IntlMessages id="forms.label.serviceAmt" />{" "}
                              </label>
                              <Field name={`${service}.amount`} component={InputField} />
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={2}>
                        <FormItem>
                          <br />
                          <Icon
                            type="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              fields.remove(index);
                            }}
                            // onClick={() => fields.remove(index)}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                );
              })}

              <Button style={{ marginTop: "5px" }} type="default" onClick={() => fields.push({})}>
                Add New Service
              </Button>
              {submitFailed && error && <span>{error}</span>}
            </div>
          ) : (
            <div>
              <h2>
                {" "}
                <IntlMessages id="forms.label.repairinfo" />
              </h2>

              {fields.map((service, index) => {
                const { repairData } = this.state;
                let data = repairData[index];
                if (!data) {
                  data = editingMode && entity && entity["service"] ? entity["service"] : [];
                }
                // let serId = (editingMode && entity && entity["service"]) ? entity["service"] : false;
                return (
                  <div key={index}>
                    <Row gutter={30}>
                      <Col lg={20} md={20}>
                        <Row gutter={30}>
                          <Col lg={12} md={12}>
                            <FormItem>
                              <label>
                                <IntlMessages id="forms.label.repairDes" />{" "}
                              </label>
                              <Field name={`${service}.description`} component={InputField} />
                            </FormItem>
                          </Col>
                          <Col lg={12} md={12}>
                            <FormItem>
                              <label>
                                <IntlMessages id="forms.label.repairAmt" />{" "}
                              </label>
                              <Field name={`${service}.amount`} component={InputField} />
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={2}>
                        <FormItem>
                          <br />
                          <Icon
                            type="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              fields.remove(index);
                            }}
                            // onClick={() => fields.remove(index)}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                );
              })}

              <Button style={{ marginTop: "5px" }} type="default" onClick={() => fields.push({})}>
                Add New Repair
              </Button>
              {submitFailed && error && <span>{error}</span>}
            </div>
          )
        ) : this.state.type == "Repairs" ? (
          <div>
            <h2>
              {" "}
              <IntlMessages id="forms.label.repairinfo" />
            </h2>

            {fields.map((service, index) => {
              const { repairData } = this.state;
              let data = repairData[index];
              if (!data) {
                data = editingMode && entity && entity["service"] ? entity["service"] : [];
              }
              // let serId = (editingMode && entity && entity["service"]) ? entity["service"] : false;
              return (
                <div key={index}>
                  <Row gutter={30}>
                    <Col lg={20} md={20}>
                      <Row gutter={30}>
                        <Col lg={12} md={12}>
                          <FormItem>
                            <label>
                              <IntlMessages id="forms.label.repairDes" />{" "}
                            </label>
                            <Field name={`${service}.description`} component={InputField} />
                          </FormItem>
                        </Col>
                        <Col lg={12} md={12}>
                          <FormItem>
                            <label>
                              <IntlMessages id="forms.label.repairAmt" />{" "}
                            </label>
                            <Field name={`${service}.amount`} component={InputField} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={2}>
                      <FormItem>
                        <br />
                        <Icon
                          type="close"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fields.remove(index);
                          }}
                          // onClick={() => fields.remove(index)}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </div>
              );
            })}

            <Button style={{ marginTop: "5px" }} type="default" onClick={() => fields.push({})}>
              Add New Repair
            </Button>
            {submitFailed && error && <span>{error}</span>}
          </div>
        ) : (
          <div>
            <h2>
              {" "}
              <IntlMessages id="forms.label.serviceinfo" />{" "}
            </h2>

            {fields.map((service, index) => {
              const { serviceData } = this.state;
              let data = serviceData[index];
              if (!data) {
                data = editingMode && entity && entity["service"] ? entity["service"] : [];
              }
              // let serId = (editingMode && entity && entity["service"]) ? entity["service"] : false;
              return (
                <div key={index}>
                  <Row gutter={30}>
                    <Col lg={20} md={20}>
                      <Row gutter={30}>
                        <Col lg={12} md={12}>
                          <FormItem>
                            <label>
                              <IntlMessages id="forms.label.serviceDes" />{" "}
                            </label>
                            <Field name={`${service}.description`} component={InputField} />
                          </FormItem>
                        </Col>
                        <Col lg={12} md={12}>
                          <FormItem>
                            <label>
                              <IntlMessages id="forms.label.serviceAmt" />{" "}
                            </label>
                            <Field name={`${service}.amount`} component={InputField} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={2}>
                      <FormItem>
                        <br />
                        <Icon
                          type="close"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fields.remove(index);
                          }}
                          // onClick={() => fields.remove(index)}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </div>
              );
            })}

            <Button style={{ marginTop: "5px" }} type="default" onClick={() => fields.push({})}>
              Add New Service
            </Button>
            {submitFailed && error && <span>{error}</span>}
          </div>
        )}
      </div>
    );
  }
}
