import React, { Component } from "react";
import { connect } from "react-redux";
import { Tag, DatePicker, Icon, Select } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

class StickyPeriodFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVisible: false,
      inputValue: "",
      displayStartDate: props.selectedRangeStart
        ? props.selectedRangeStart
        : moment().format("YYYY-MM-DD"),
      displayEndDate: props.selectedRangeEnd
        ? props.selectedRangeEnd
        : moment().format("YYYY-MM-DD"),
    };
  }

  showInput = () => {
    this.setState({ inputVisible: true });
  };

  handleInputChange = (value) => {
    const { dispatch } = this.props;
    const startDate = value[0].format("YYYY-MM-DD");
    const endDate = value[1].format("YYYY-MM-DD");
    this.props.storeDaterange &&
      dispatch(this.props.storeDaterange(startDate, endDate));

    this.setState({
      displayStartDate: startDate,
      displayEndDate: endDate,
      inputVisible: false,
    });
    this.props.stickyFilter(value);
  };

  handleEndOpenChange = (value) => {
    this.setState({ inputVisible: value });
  };

  renderRangePickerDate = () => {
    const { selectedRangeStart, selectedRangeEnd } = this.props;
    const { displayStartDate, displayEndDate } = this.state;
    if (selectedRangeStart && selectedRangeEnd) {
      return selectedRangeStart + " - " + selectedRangeEnd;
    } else {
      return displayStartDate + " - " + displayEndDate;
    }
  };

  handleRangeSelect = (value) => {
    let startDate = moment();
    const endDate = moment();
    switch (value) {
      case "today":
        startDate = moment();
        break;
      case "yesterday":
        startDate = moment().subtract(1, "days");
        break;
      case "week":
        startDate = moment().subtract(7, "days");
        break;
      case "month":
        startDate = moment().subtract(30, "days");
        break;
      default:
        break;
    }
    this.handleInputChange([startDate, endDate]);
  };

  render() {
    const { inputVisible } = this.state;
    return (
      <>
        {inputVisible && (
          <RangePicker
            open
            onChange={this.handleInputChange}
            onOpenChange={this.handleEndOpenChange.bind(this)}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput}>
            {this.renderRangePickerDate()} <Icon type="edit" />
          </Tag>
        )}
        <Select
          defaultValue="week" 
          style={{ width: 180 }}
          onChange={this.handleRangeSelect}
        >
          <Option value="month">Last 30 Days</Option>
          <Option value="week">Last 7 days</Option>
          <Option value="today">Today</Option>
          <Option value="yesterday">Yesterday</Option>
        </Select>
      </>
    );
  }
}

export default connect()(StickyPeriodFilter);
