import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_SHIFTS_START,
  fetchShiftsSuccess,
  fetchShiftsFailure,
  FETCH_DRIVER_REQUEST,
  fetchDriver,
  selectShiftSuccess,
  SELECT_SHIFT_REQUEST,
  fetchContractor,
  FETCH_CONTRACTOR_REQUEST,
  fetchVehicle,
  FETCH_VEHICLE_REQUEST,
  FETCH_WORK_REST_REQUEST,
  fetchWorkRest,
  fetchLocationHistory,
  FETCH_LOCATION_REQUEST,
  fetchLocationHistoryError,
  FETCH_PDF_REQUEST,
  UPDATE_ACTIVITY_REQUEST,
  ADD_ACTIVITY_REQUEST,
  updateActivity,
  GENERATE_SHIFT_REPORT,
  fetchReport,
  FETCH_REPORT_REQUEST,
  DELETE_SHIFT,
} from "./actions";
import Api from "Common/api/Api2";
import Api1 from "Common/api/Api";

const api = new Api();
const api1 = new Api1();

function* fetchShiftsSaga() {
  yield takeEvery(FETCH_SHIFTS_START, function* ({ query, resolve, reject }) {
    try {
      const response = yield call(api.get, `search/shift${query ? "?" + query : ""}`);

      if (resolve) yield put(fetchShiftsSuccess(response.data));
    } catch (err) {
      if (reject) yield put(fetchShiftsFailure(err.toString()));
    }
  });
}

function* fetchDriverSaga() {
  yield takeEvery(FETCH_DRIVER_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "driver/dropdown/all");
      if (resolve) yield put(fetchDriver(response?.data));
    } catch (err) {
      if (reject) yield put(fetchDriver([]));
    }
  });
}

function* fetchShiftSaga() {
  yield takeEvery(SELECT_SHIFT_REQUEST, function* ({ driverId, shiftId, resolve, reject }) {
    try {
      const response = yield call(api.get, `driverActivity/${shiftId}/${driverId}`);
      if (resolve) yield put(selectShiftSuccess(response?.data));
    } catch (err) {
      if (reject) yield put(fetchShiftsFailure(err.toString()));
    }
  });
}

function* fetchContractorSaga() {
  yield takeEvery(FETCH_CONTRACTOR_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api1.get, `contractors`);
      if (resolve) yield put(fetchContractor(response?.data));
    } catch (err) {
      if (reject) yield put(fetchContractor([]));
    }
  });
}

function* fetchVehicleSaga() {
  yield takeEvery(FETCH_VEHICLE_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api1.get, `vehicles`);
      if (resolve) yield put(fetchVehicle(response?.data));
    } catch (err) {
      if (reject) yield put(fetchVehicle([]));
    }
  });
}

function* fetchWorkRestSaga() {
  yield takeEvery(FETCH_WORK_REST_REQUEST, function* ({ driverId, startDate, endDate, resolve, reject }) {
    try {
      const response = yield call(api.get, `ewd/workRestHour/${driverId}?startDate=${startDate}&endDate=${endDate}`);
      if (resolve) {
        yield put(fetchWorkRest(response?.data));
      }
    } catch (err) {
      if (reject) yield put(fetchWorkRest(null));
    }
  });
}

function* fetchLocationHistorySaga() {
  yield takeEvery(FETCH_LOCATION_REQUEST, function* ({ vehicleId, beginTime, endTime, resolve, reject }) {
    try {
      const response1 = yield call(api.get, "/telematics/vehicle/" + vehicleId + "/info");
      if (resolve) {
        const platform = response1?.data?.[0]?.deviceType;
        const response = yield call(api.get, `/telematics/vehicle/${vehicleId}/${platform}/GPSHistory?beginTime=${beginTime}&endTime=${endTime}`);
        if (resolve) yield put(fetchLocationHistory(response?.data));
      }
    } catch (err) {
      if (reject) yield put(fetchLocationHistoryError(err));
    }
  });
}

function* fetchPDFSaga() {
  yield takeEvery(FETCH_PDF_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.getBlob, `driverActivity/${id}/getPreCheckList`);
      if (resolve) yield call(resolve, response);
    } catch (error) {
      if (reject) yield call(reject, error);
    }
  });
}

function* generateShiftReportSaga() {
  yield takeEvery(GENERATE_SHIFT_REPORT, function* ({ shiftId, driverId, resolve, reject }) {
    try {
      const response = yield call(api.get, `reports/${shiftId}/${driverId}/generate`);
      if (resolve) yield call(resolve, response);
    } catch (error) {
      if (reject) yield call(reject, error);
    }
  });
}
function* updateActivityRequest() {
  yield takeEvery(UPDATE_ACTIVITY_REQUEST, function* ({ id, data, resolve, reject }) {
    try {
      const response = yield call(api.update, `driverActivity/${id}`, data);
      if (response.status === 200) {
        const updatedActivity = { id: response.data?.id, ...data };
        yield put(updateActivity(updatedActivity));
      }
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

function* fetchReportSaga() {
  yield takeEvery(FETCH_REPORT_REQUEST, function* ({ shiftId, resolve, reject }) {
    try {
      const response = yield call(api.get, `reports/${shiftId}`);

      if (response.status === 200) {
        yield put(fetchReport(response.data));
      }
      yield call(resolve, response);
    } catch (error) {
      if (reject) yield call(reject, error);
    }
  });
}

function* addActivityRequest() {
  yield takeEvery(ADD_ACTIVITY_REQUEST, function* ({ data, resolve, reject }) {
    try {
      const response = yield call(api.post, `driverActivity`, data);
      if (response.status === 201) {
        const updatedActivity = { id: response.data?.id, ...data };
        yield put(updateActivity(updatedActivity));
      }
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

function* deleteShiftSaga() {
  yield takeEvery(DELETE_SHIFT, function* ({ shiftId, resolve, reject }) {
    try {
      const response = yield call(api.delete, `driverActivity/shift/${shiftId}`);

      if (response.status === 200) {
        yield put(fetchReport(response.data));
      }
      yield call(resolve, response);
    } catch (error) {
      if (reject) yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchDriverSaga),
    fork(fetchShiftSaga),
    fork(fetchContractorSaga),
    fork(fetchVehicleSaga),
    fork(fetchWorkRestSaga),
    fork(fetchLocationHistorySaga),
    fork(fetchShiftsSaga),
    fork(fetchPDFSaga),
    fork(generateShiftReportSaga),
    fork(updateActivityRequest),
    fork(addActivityRequest),
    fork(fetchReportSaga),
    fork(deleteShiftSaga),
  ]);
}
