import React, { Component } from "react";
import { Col, Row, Select, message, Spin, Modal } from "antd";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import { connect } from "react-redux";

import Button from "Common/components/button";
import { ButtonWrappper } from "Common/components/Form/styles/formWrapper.style";
import InputField from "Common/components/InputField";
// import validate from 'Common/validate';
import { required } from "Common/required";
import IntlMessages from "Common/components/intlMessages";
// import Section from 'Common/components/section';
import FormGroupWrapper from "Common/components/Form/FormGroupWrapper";
import DatePickerField from "Common/components/DatePickerField";
import UploadDocument from "Maintenance/components/UploadDocument";
import ServiceInfo from "Maintenance/components/ServiceInfo";
import SelectField from "Common/components/SelectField";
import { VehicleDropdownSelectWithFormSection as VehicleDropdownSelect } from "Vehicle/components/form/VehicleDropdown";
import { DropdownSelectWithFormSection as DropdownSelect } from "Dropdown/components/form/DropdownSelect";

import actions from "Maintenance/redux/actions";
import vehicleactions from "Vehicle/redux/actions";

const Option = Select.Option;

const numeric = (value) => {
  if (!value) {
    return "This field is required";
  }
  if (!/^[0-9]+$/.test(value)) {
    return "Invalid, must be numeric";
  }
};

const { createDocument, updateDocument, deleteDocument } = vehicleactions;

const { updateMaintenance, createMaintenance } = actions;
const FORM_NAME = "CreateService";
const SAVE = "save";
// const SAVE_AND_CREATE = "saveAndCreate";

class CreateServiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showCompleted: false,
      showInprogress: false,
      uploadImage: true,
      type: "",
      isMobileView: window.innerWidth <= 768,
    };
    if (!props.editingMode) {
      this.props.initialize(props.defaultValue || {});
    } else {
      this.props.initialize(props.entity || {});
    }
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth <= 768 });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.entity && nextProps.entity !== this.props.entity) {
      if (!this.props.editingMode) {
      } else {
        this.setState({ type: nextProps.entity.type });
        if (nextProps.entity.status == "Completed") {
          this.setState({
            showCompleted: true,
            showInprogress: false,
          });
        }
      }
      const entity = nextProps.entity;
      // delete entity["id"];
      // delete entity["contact"];
      this.props.initialize({
        ...entity,
      });
    }
  }

  normalizeDataSubmit = (data) => {
    const { dropdownData } = this.props;
    delete data.id;
    data.documents && delete data.documents;
    if (!this.props.editingMode) {
      const sendData = {
        type: this.props.type,
        ...data,
      };
      return sendData;
    }
    if (data.type !== "Repairs") {
      let serviceTypeId = data.serviceType.id;
      data.serviceType = dropdownData.find((obj) => obj.id == serviceTypeId);
    }
    if (data.repairServiceBy == null) data.repairServiceBy = "";
    return data;
  };

  //  normalizeDataDocument = (data) =>{
  //    delete data.service.id;
  //    return {
  //     ...data,
  //   }
  //  }

  handleCreate = (data, context) => {
    let documents = data.documents ? data.documents : false;
    const self = this;
    this.setState({ loading: true, uploadImage: false });
    // const id = this.props.maintenanceId;
    return new Promise((resolve, reject) => {
      this.props.createMaintenance(this.normalizeDataSubmit(data), context, resolve, reject);
    })
      .then((response) => {
        if (documents) {
          let promiseCollection = documents.map((value) => {
            let documentObject = {
              maintenance: {
                id: response.data.id,
              },
              metaData: "Vehicle Maintainance Document",
              ...value,
            };
            return new Promise((resolve, reject) => {
              self.props.createDocument(documentObject, resolve, reject);
              setTimeout(() => resolve(), 1);
            });
          });
          Promise.all(promiseCollection).then((response1) => console.log("", response1));
        }
        return response;
      })
      .then(
        (response) => {
          self.setState({ loading: false, uploadImage: true });

          if (this.props.fromVehicle) {
            this.props.onCancel();
          } else {
            if (context == SAVE) {
              if (this.props.type == "Repairs") {
                self.props.history.push("/maintenance/type/Repairs");
              } else {
                self.props.history.push("/maintenance/type/all");
              }
            }
          }
          this.props.initialize({});
          message.success("Created successfully");

          return true;
        },
        (error) => {
          this.setState({ loading: false, uploadImage: true });
          throw new SubmissionError(error);
        },
        this
      );
  };

  handleUpdate = (data) => {
    let documents = data.documents ? data.documents : false;
    const self = this;
    this.setState({ loading: true, uploadImage: false });
    return new Promise((resolve, reject) => {
      const id = this.props.entity_id;
      this.props.updateMaintenance(id, this.normalizeDataSubmit(data), resolve, reject);
    })
      .then((response) => {
        // return new Promise((resolve,reject) => {
        if (documents) {
          Promise.all(
            documents.map((value) => {
              let documentObject = {
                maintenance: {
                  id: response.data.id,
                },
                metaData: "Vehicle Maintainance Document",
                ...value,
              };

              if (value.id) {
                // Update existing document
                return new Promise((resolve, reject) => {
                  // Remove unwanted properties
                  delete documentObject.contact;
                  delete documentObject.issuingDate;
                  delete documentObject.expiryDate;
                  delete documentObject.status;
                  delete documentObject.id;
                  delete documentObject.createdBy;

                  // Call the update document function and resolve the promise when done
                  self.props.updateDocument(value.id, documentObject, resolve, reject);
                });
              } else {
                // Create new document
                return new Promise((resolve, reject) => {
                  self.props.createDocument(documentObject, resolve, reject);
                });
              }
            })
          );
        }
        message.success("Updated successfully");

        if (this.props.fromVehicle) {
          this.props.onCancel();
        } else {
          if (this.props.type == "Repairs") {
            self.props.history.push("/maintenance/type/Repairs");
          } else {
            self.props.history.push("/maintenance/type/Services");
          }
        }
        this.setState({ loading: false });
        this.props.initialize({});

        return response;
      })
      .catch((error) => {
        this.setState({ loading: false, uploadImage: true });
        throw new SubmissionError(error);
      }, this);
  };

  onDeleteClicked = (id) => {
    if (id) {
      this.setState({ loading: true });

      return new Promise((resolve, reject) => {
        this.props.deleteDocument(id, resolve, reject);
      })
        .then(() => {
          this.setState({ loading: false });
          message.success("Document deleted successfully");
          return true;
        })
        .catch((error) => {
          this.setState({ loading: false });
          throw new SubmissionError(error);
        }, this);
    }
  };

  onSaveClick = (data) => {
    return this.handleCreate(data, "save");
  };

  onSaveandCreateClick = (data) => {
    return this.handleCreate(data, "saveandcreate").then(() => {
      this.props.dispatch(reset(FORM_NAME));
    }, this);
  };

  onSelect = (event, value) => {
    if (value == "Completed") {
      this.setState({
        showCompleted: true,
        showInprogress: true,
      });
    } else if (value == "In Progress") {
      this.setState({
        showInprogress: true,
        showCompleted: false,
      });
    } else if (value == "Completed" || value == "In Progress") {
      this.setState({
        showInprogress: true,
        showCompleted: true,
      });
    } else {
      this.setState({
        showCompleted: false,
        showInprogress: false,
      });
    }
  };

  onCancelClick = () => {
    this.props.history.goBack();
  };
  render() {
    const { loading, uploadImage, showCompleted, isMobileView } = this.state;
    const { handleSubmit, editingMode, dispatch, type, entity, fromVehicle } = this.props;

    const ServiceForm = () => {
      return (
        <div>
          <Spin spinning={loading}>
            <div>
              <Row gutter={[16, 16]}>
                <Col lg={fromVehicle && isMobileView ? 24 : 16}>
                  <Row gutter={[16, 16]}>
                    <h2>
                      <IntlMessages id="forms.label.basicinfo" />{" "}
                    </h2>
                    <FormGroupWrapper label={<IntlMessages id="forms.label.vehicleId" />}>
                      {editingMode || this.props.type == "Services" ? (
                        <VehicleDropdownSelect validate={required} name="vehicle" disabled={true}></VehicleDropdownSelect>
                      ) : (
                        <VehicleDropdownSelect validate={required} name="vehicle"></VehicleDropdownSelect>
                      )}
                    </FormGroupWrapper>
                    {editingMode ? (
                      type == "Repairs" ? null : (
                        <FormGroupWrapper label={<IntlMessages id="forms.label.serviceType" />}>
                          <DropdownSelect name="serviceType" type="serviceTypes" defaultValue={entity && entity.serviceType.id} disabled={true} />
                        </FormGroupWrapper>
                      )
                    ) : (
                      type == "Services" && (
                        <FormGroupWrapper label={<IntlMessages id="forms.label.serviceType" />}>
                          <DropdownSelect
                            name="serviceType"
                            type="serviceTypes"
                            defaultValue={this.props.defaultValue.serviceType && this.props.defaultValue.serviceType.id}
                            disabled={false}
                          />
                        </FormGroupWrapper>
                      )
                    )}
                  </Row>
                  <Row gutter={[16, 16]}>
                    <FormGroupWrapper label={<IntlMessages id={"forms.label.odometerReading." + type} />}>
                      {showCompleted ? (
                        <Field validate={numeric} name="odometerReading" component={InputField} />
                      ) : (
                        <Field validate={numeric} name="odometerReading" component={InputField} />
                      )}
                    </FormGroupWrapper>
                    <FormGroupWrapper label={<IntlMessages id={"forms.label.startDate." + type} />}>
                      {showCompleted ? (
                        <Field validate={required} name="startedDate" component={DatePickerField} />
                      ) : (
                        <Field name="startedDate" component={DatePickerField} />
                      )}
                    </FormGroupWrapper>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <FormGroupWrapper label={<IntlMessages id="forms.label.status" />}>
                      {showCompleted ? (
                        <Field validate={required} name="status" component={SelectField} onChange={this.onSelect} disabled={editingMode}>
                          <Option value="">Select Status</Option>
                          <Option value="Open">Open</Option>
                          <Option value="Completed">Completed</Option>
                        </Field>
                      ) : (
                        <Field validate={required} name="status" component={SelectField} onChange={this.onSelect}>
                          <Option value="">Select Status</Option>
                          <Option value="Open">Open</Option>
                          <Option value="Completed">Completed</Option>
                        </Field>
                      )}
                    </FormGroupWrapper>
                    {/* <FormGroupWrapper label={<IntlMessages id="forms.label.completeDate" />}>
                    {(showCompleted) ? (
                      <Field validate={required} name="completedDate" component={DatePicker} />) :
                      <Field name="completedDate" component={DatePicker} />}
                        </FormGroupWrapper> */}
                    <FormGroupWrapper label={<IntlMessages id={"forms.label.serviceBy." + type} />}>
                      <Field name="repairServiceBy" component={InputField} />
                    </FormGroupWrapper>
                  </Row>
                  {/* <Row gutter={30}>
                  <FormGroupWrapper label={<IntlMessages id="forms.label.lastServicekms" />}>
                    <Field  validate={required} name="odometerReading" component={InputField} />
                  </FormGroupWrapper>
                  <FormGroupWrapper label={<IntlMessages id="forms.label.lastServicedate" />}>
                    <Field  validate={required} name="startedDate" component={DatePicker} />
                  </FormGroupWrapper>
                  </Row>   */}
                  <ServiceInfo
                    entity={this.props.entity}
                    editingMode={editingMode}
                    handleDispatch={dispatch}
                    handleDelete={this.onDeleteClicked}
                    titleforlabel={this.props.titleforlabel}
                  />
                </Col>
                <Col lg={fromVehicle && isMobileView ? 12 : 8}>
                  {uploadImage && (
                    <UploadDocument
                      entity={this.props.entity}
                      editingMode={editingMode}
                      handleDispatch={dispatch}
                      handleDelete={this.onDeleteClicked}
                    />
                  )}
                </Col>
              </Row>
            </div>

            <Row gutter={48}>
              <Col lg={24} md={24}>
                <div style={{ marginTop: "25px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Button loading={loading} className="gray-btn" onClick={this.onCancelClick.bind(this)}>
                    <IntlMessages id="forms.button.cancel" />
                  </Button>
                  {editingMode ? (
                    <ButtonWrappper>
                      <Button type="primary" className="save" loading={loading} onClick={handleSubmit(this.handleUpdate.bind(this))}>
                        <IntlMessages id="forms.button.update" />
                      </Button>
                    </ButtonWrappper>
                  ) : (
                    <div>
                      <ButtonWrappper>
                        <Button type="primary" loading={loading} className="save" onClick={handleSubmit(this.onSaveClick.bind(this))}>
                          <IntlMessages id="forms.button.save" />
                        </Button>
                        {type == "Repairs" && (
                          <Button loading={loading} className="save-and-create-new" onClick={handleSubmit(this.onSaveandCreateClick.bind(this))}>
                            <IntlMessages id="forms.button.saveAndCreateNew" />
                          </Button>
                        )}
                      </ButtonWrappper>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Spin>
        </div>
      );
    };

    return (
      <>
        {this.props?.fromVehicle ? (
          <Modal
            title={(this.props.editingMode ? "Edit " : "Create ") + this.props.type}
            visible={this.props.openService}
            onCancel={() => {
              this.props.reset();
              this.props.onCancel();
            }}
            footer={null}
            width={isMobileView ? 400 : 800}
          >
            {ServiceForm()}
          </Modal>
        ) : (
          <>{ServiceForm()}</>
        )}
      </>
    );
  }
}

CreateServiceForm = reduxForm({
  form: FORM_NAME,
  initialValues: {
    service: [{}],
  },
})(CreateServiceForm);

export default connect(
  (state) => ({
    dropdownData: state.Dropdown.data,
  }),
  { createMaintenance, updateMaintenance, createDocument, updateDocument, deleteDocument }
)(CreateServiceForm);
