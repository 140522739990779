import React, { Component } from "react";
import { Table, Row, Col, Popconfirm } from "antd";
import moment from "moment";
import { ResponsiveTableWrapper, DisabledResponsiveTableWrapper } from "Common/components/Table/styles/responsiveTableWrapper.style";
import QuickFilter from "Common/components/Table/QuickFilter";
import PowerSearch from "Common/components/Table/PowerSearch";
import AdvancedFilter from "Common/components/Table/AdvancedFilter";
import AppliedFilterTags from "Common/components/Table/AppliedFilterTags";
import StickyPeriodFilter from "Common/components/Table/StickyPeriodFilter";
import { TableWrapperStyle } from "Common/components/Table/styles/index.style";
import toUtcDateFormat from "Common/toUtcDateFormat";
import actions from "Vehicle/redux/actions";
import "./styles/style.css";

// For changing the Sub-menu selected in tab
import appActions from "App/redux/actions";
import { connect } from "react-redux";

import Button from "Common/components/button";

import Api from "Common/api/Api";
import Api2 from "Common/api/Api2";

const api = new Api();
const api2 = new Api2();
// const ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const { changeCurrent } = appActions;
const { refetch } = actions;

export const normalizeAdvancedFilterData = (data) => {
  if (!data) return null;
  if (moment.isMoment(data)) {
    data = toUtcDateFormat(data);
  }
  if (typeof data === "object") {
    let secondKey = Object.keys(data)[0];
    if (typeof data[secondKey] == "object" && !moment.isMoment(data[secondKey])) {
      return {
        ...data[secondKey],
        secondKey,
      };
    } else if (typeof data[secondKey] == "object" && moment.isMoment(data[secondKey])) {
      return {
        secondKey,
        key: moment(data[secondKey]).format("YYYY-MM-DD"),
        label: moment(data[secondKey]).format("YYYY-MM-DD"),
      };
    } else {
      return {
        secondKey,
        key: data[secondKey],
        label: data[secondKey],
      };
    }
  }
  return {
    secondKey: null,
    key: data,
    label: data,
  };
};
const paginationInitialize = { current: 1, pageSize: 50 };

class TableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      quickFilterValue: props.defaultQuickFilterValue,
      powerSearchValue: props.powerSearchValue,
      pagination: { current: 1, pageSize: 50, total: "" },
      dataSource: [],
      pageSize: 50,
      advancedFilterData: props.defaultAdvancedFilterData || null,
      loading: true,
      stickyFilterData: props.stickyFilter
        ? {
            start: props.selectedRangeStart ? props.selectedRangeStart : moment().format("YYYY-MM-DD"),
            end: props.selectedRangeEnd ? props.selectedRangeEnd : moment().format("YYYY-MM-DD"),
          }
        : null,
      sorter: props.defaultSort || {},
      downloadLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updatedOn && nextProps.updatedOn !== this.props.updatedOn) {
      this.fetchData();
    }

    if (nextProps.quickFilterStatus !== this.props.quickFilterStatus) {
      this.setState(
        {
          quickFilterValue: nextProps.quickFilterStatus,
          pagination: paginationInitialize,
          advancedFilterData: this.mergeAdvancedFilterData(nextProps.defaultAdvancedFilterData),
        },
        this.fetchData
      );
    } else if (nextProps.defaultAdvancedFilterData !== this.props.defaultAdvancedFilterData) {
      this.setState({ advancedFilterData: this.mergeAdvancedFilterData(nextProps.defaultAdvancedFilterData) }, this.fetchData);
    }
  }

  mergeAdvancedFilterData = (newAdvancedFilterData) => {
    if (newAdvancedFilterData) {
      let oldAdvancedData = this.state.advancedFilterData || {};
      return { ...oldAdvancedData, ...newAdvancedFilterData };
    }
    return this.state.advancedFilterData;
  };

  buildApiUrlFromState(downloadUrl) {
    const { url, customProps } = this.props;
    // console.log('buildApiUrlFromState',url);
    let URL = downloadUrl ?? url;
    let URLParams = [];
    if (this.props.quickFilterElements && this.state.quickFilterValue !== "all") {
      let filterBySegments = this.props.quickFilterElements.filterBy.split(".");
      if (filterBySegments[1]) {
        URLParams.push("filters[" + filterBySegments[0] + "][" + filterBySegments[1] + "]=" + this.state.quickFilterValue);
      } else {
        URLParams.push("filters[" + filterBySegments[0] + "]=" + this.state.quickFilterValue);
      }
    }

    if (this.state.sorter) {
      let sorterField = this.state.sorter.field;
      if (sorterField) {
        const field = sorterField.split(".");
        if (field[1]) {
          URLParams.push("sort[" + field[0] + "][" + field[1] + "]=" + (this.state.sorter.order == "ascend" ? "asc" : "desc"));
        } else {
          URLParams.push("sort[" + field[0] + "]=" + (this.state.sorter.order == "ascend" ? "asc" : "desc"));
        }
      } else {
        URLParams.push("sort[" + this.state.sorter.columnKey + "]=" + (this.state.sorter.order == "ascend" ? "asc" : "desc"));
      }
    }

    if (this.state.powerSearchValue) {
      if (url.split("/")[0] === "search") URLParams.push("q=" + this.state.powerSearchValue);
      else URLParams.push("search=" + this.state.powerSearchValue);
    }
    if (this.state.stickyFilterData) {
      const { start, end } = this.state.stickyFilterData;
      URLParams.push(
        "period[start]=" +
          moment(start + " 00:00:00")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss") +
          "&period[end]=" +
          moment(end + " 23:59:59")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss")
      );
    }
    if (this.state.pagination) {
      URLParams.push("pageSize=" + this.state.pageSize + "&page=" + this.state.pagination.current);
    }

    if (this.state.advancedFilterData) {
      for (var key in this.state.advancedFilterData) {
        let fieldData = normalizeAdvancedFilterData(this.state.advancedFilterData[key]);
        if (fieldData && fieldData.key) {
          if (fieldData.secondKey && fieldData.secondKey !== "departureDate") {
            URLParams.push(`filters[${key}][${fieldData.secondKey}]=` + fieldData.key);
          } else if (fieldData.secondKey && fieldData.secondKey === "departureDate") {
            const startDate = fieldData.label + " 00:00:00";
            const endDate = fieldData.label + " 23:59:59";
            URLParams.push(
              `range[${key}][${fieldData.secondKey}][start]=${moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss")}&range[${key}][${
                fieldData.secondKey
              }][end]=${moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss")}`
            );
          } else {
            URLParams.push(`filters[${key}]=` + fieldData.key);
          }
        }
      }
    }

    if (customProps) {
      for (let key in customProps) {
        URLParams.push(`${key}=${customProps[key]}`);
      }
    }

    if (URLParams.length > 0) {
      const urlSplit = URL.split("?");
      if (urlSplit[1]) {
        URLParams.push(urlSplit[1]);
      }
      URL = urlSplit[0] + "?" + URLParams.join("&");
    }
    return URL;
  }

  fetchData = () => {
    const { getTableData, tmsTable } = this.props;
    this.setState({ loading: true });
    const URL = this.buildApiUrlFromState();
    const selectedApi = tmsTable ? api2 : api;
    return selectedApi
      .get(URL, {})
      .then((response) => {
        getTableData && getTableData(response.data.results);
        if (this.props?.isRefetch) this.props?.refetch(false);
        this.setState({
          loading: false,
          dataSource: response.data.results,
          pagination: {
            ...this.state.pagination,
            total: response.data.total,
          },
        });
      })
      .catch((error) => {
        if (this.props?.isRefetch) this.props?.refetch(false);
        console.log("axios error", error);
      });
  };

  componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Check if the 'refetch' prop has changed
    if (this.props.isRefetch !== prevProps.isRefetch && this.props.isRefetch) {
      this.fetchData();
    }
  }

  handleQuickFilterChange = (value) => {
    const { app, changeCurrent, context, history, onQuickFilterChange } = this.props;
    let currentStatusValue = null;
    if (onQuickFilterChange) {
      onQuickFilterChange(value);
    }
    // if(currentStatusValue !== null && value !== null) {

    if (app.current && app.current[0] && app.current[0].split("/").length > 1) {
      let path = history.location.pathname;
      let urlLastIndex = path.lastIndexOf("/");

      // get the last value from the path name.
      currentStatusValue = path.substring(urlLastIndex + 1);

      // Remove / from the beginning of the url
      let currentUrlValueBeforeLastString = path.substring(0, urlLastIndex);
      changeCurrent([currentUrlValueBeforeLastString.substring(1) + "/" + value]);
    } else {
      changeCurrent([value]);
    }
    if (context !== "vehicleMaintainance" && currentStatusValue !== value) {
      history.push(value);
    }

    // }
    this.setState({ quickFilterValue: value, pagination: paginationInitialize }, this.fetchData);
  };

  handlePowerSearchChange(value) {
    this.setState({ powerSearchValue: value, pagination: paginationInitialize }, this.fetchData);
  }

  handleTableChange = (nextPagination, nextFilter, nextSorter) => {
    const { pagination, sorter } = this.state;
    if (nextPagination && nextPagination.current !== pagination.current) {
      this.setState(
        {
          pagination: nextPagination,
        },
        this.fetchData
      );
    }
    if (nextSorter && nextSorter.columnKey && nextSorter.order) {
      if (nextSorter.columnKey !== sorter.columnKey || nextSorter.order !== sorter.order) {
        this.setState(
          {
            sorter: nextSorter,
            pagination: paginationInitialize,
          },
          this.fetchData
        );
      }
    }
  };

  handleRowClick = (record) => {
    this.props.handleRowClick(record);
  };

  handleAdvancedFilterData = (data) => {
    this.setState({ advancedFilterData: data, pagination: paginationInitialize }, this.fetchData);
  };

  handleFilterRemove = (filter) => {
    this.setState(
      (prevState) => ({
        advancedFilterData: {
          ...prevState.advancedFilterData,
          [filter]: "",
        },
      }),
      this.fetchData
    );
  };

  handleStickyFilterData = (data) => {
    this.setState(
      {
        stickyFilterData: {
          start: data[0].format("YYYY-MM-DD"),
          end: data[1].format("YYYY-MM-DD"),
        },
        pagination: paginationInitialize,
      },
      this.fetchData
    );
  };

  onCreateClicked = (e) => {
    e.preventDefault();
    this.props.url;
  };

  createTitleDiv = () => {
    if (typeof this.props.createTitle === "string") {
      return (
        <Button type="primary" icon="plus" onClick={this.props.onClick} style={{ marginRight: "15px" }}>
          {this.props.createTitle}
        </Button>
      );
    }
    return this.props.createTitle;
  };

  downloadList = (downloadType) => {
    this.setState({
      downloadLoading: true,
    });
    const { downloadUrl, reportTitle, url } = this.props;
    const apiUrl = this.buildApiUrlFromState(`${downloadUrl}/${downloadType}`);
    return api2
      .getBlob(apiUrl, {})
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = reportTitle ?? url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);

        this.setState({
          downloadLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          downloadLoading: false,
        });
      });
  };

  render = () => {
    const { dataSource, pagination } = this.state;
    const {
      normalizeDataSource,
      disabledResponsive,
      columns,
      disabledPowerSearch,
      powerSearchValue,
      quickFilterElements,
      stickyFilter,
      advancedFilterForm,
      // quickFilterStatus,
      onClickCSV,
      onClickPdf,
      downloadUrl,
      // selectedRange
    } = this.props;

    // var createButton = createBtn.substring(0, createBtn.length - 1);
    return (
      <TableWrapperStyle>
        <Row gutter={8}>
          <Col lg={15} md={24}>
            {quickFilterElements && (
              <QuickFilter
                quickFilterElements={quickFilterElements}
                onChange={this.handleQuickFilterChange}
                quickFilterValue={this.props.defaultQuickFilterValue ? this.props.defaultQuickFilterValue : ""}
              />
            )}
            {stickyFilter && (
              <StickyPeriodFilter
                stickyFilter={this.handleStickyFilterData}
                selectedRangeStart={this.props.selectedRangeStart}
                selectedRangeEnd={this.props.selectedRangeEnd}
                storeDaterange={this.props.storeDaterange}
              />
            )}
            {advancedFilterForm && (
              <AppliedFilterTags advancedFilterData={this.state.advancedFilterData} handleFilterRemove={this.handleFilterRemove} />
            )}
          </Col>
          <Col lg={9} md={24} className="searchWrap">
            {this.createTitleDiv()}
            {onClickCSV || downloadUrl ? (
              <Popconfirm
                placement="bottomLeft"
                title="Please choose document format"
                okText="PDF"
                cancelText="CSV"
                onConfirm={() => (downloadUrl ? this.downloadList("pdf") : onClickPdf())}
                onCancel={() => (downloadUrl ? this.downloadList("xlsx") : onClickPdf())}
                okButtonProps={{
                  className: "custom-button",
                }}
                cancelButtonProps={{
                  className: "custom-button",
                }}
              >
                <Button
                  type="primary"
                  icon="download"
                  style={{ marginRight: "15px", padding: `0 ${this.state.downloadLoading ? "29px" : "16px"}` }}
                  loading={this.state.downloadLoading}
                >
                  Download Report
                </Button>
              </Popconfirm>
            ) : (
              ""
            )}

            {disabledPowerSearch ? null : (
              <PowerSearch
                value={powerSearchValue}
                storePowerSearchValue={this.props.storePowerSearchValue}
                onChange={this.handlePowerSearchChange.bind(this)}
              />
            )}

            {advancedFilterForm && (
              <AdvancedFilter
                onSubmit={this.handleAdvancedFilterData}
                advancedFilterData={this.state.advancedFilterData}
                advancedFilterForm={advancedFilterForm}
                context={this.props.url}
              />
            )}
          </Col>
        </Row>
        {this.props.children
          ? React.Children.map(this.props.children, (child) =>
              React.cloneElement(child, {
                dataSource,
                loading: this.state.loading,
                fetchData: this.fetchData,
                stickyFilterData: this.state.stickyFilterData,
              })
            )
          : (disabledResponsive && (
              <DisabledResponsiveTableWrapper>
                <Table
                  id="tableDownloadable"
                  className="tableStyled"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={normalizeDataSource ? normalizeDataSource(dataSource) : dataSource}
                  pagination={pagination}
                  loading={this.state.loading}
                  onChange={this.handleTableChange}
                />
              </DisabledResponsiveTableWrapper>
            )) || (
              <ResponsiveTableWrapper>
                <Table
                  id="tableDownloadable"
                  className="tableStyled"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={normalizeDataSource ? normalizeDataSource(dataSource) : dataSource}
                  pagination={pagination}
                  loading={this.state.loading}
                  onChange={this.handleTableChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.handleRowClick(record);
                      }, // click row
                    };
                  }}
                />
              </ResponsiveTableWrapper>
            )}
      </TableWrapperStyle>
    );
  };
}

// export default TableWrapper;
export default connect(
  (state) => ({
    app: state.App,
    isRefetch: state.Vehicle.refetch,
  }),
  { changeCurrent, refetch }
)(TableWrapper);
