import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Popover from "Common/components/popover";
import IntlMessages from "Common/components/intlMessages";
import authAction from "Public/redux/actions";
import appAction from "App/redux/actions";
import TopbarDropdownWrapper from "App/styles/topbardropdown.style";
import userActions from "User/redux/actions";

const { logout } = authAction;
const { closeAll } = appAction;
const { fetchUserDetail } = userActions;

// const ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      entity: {},
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  initializeClarityUser = (userData) => {
    const userEmail = userData.email;
    const userName = userData.firstName + " " + userData.lastName;
    //TODO: set custom session page in clarity
    window.clarity("identify", userEmail, "custom-session-123", "custom-page-123", userName);
  };

  componentDidMount = () => {
    const userId = this.props.userProfile.sub;
    const normalizedId = userId.substring(userId.indexOf("|") + 1);
    if (window.localStorage.getItem("userData")) {
      const userData = JSON.parse(window.localStorage.getItem("userData"));
      this.setState({ loading: false, entity: userData });
    } else
      new Promise((resolve, reject) => {
        this.props.fetchUserDetail(normalizedId, resolve, reject);
      })
        .then((response) => {
          const userData = response.data;
          this.setState({ loading: false, entity: userData });
          this.initializeClarityUser(userData);
          window.localStorage.setItem("userData", JSON.stringify(userData));
        })
        .catch((error) => {
          this.setState({ loading: false });
        }, this);
  };

  renderLogo = () => {
    let imgSrc =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAAAwCAYAAACooNxlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ1SURBVHgB5ZpNaBRJFMf/1TOQuGs2w16yglkjrO5hJ2yyrGsWWR1hT1lwjQdFEI160INgvKigJqN4EC8mqKCCOKKg5uIHJKAX4+dEPSQBL0HQUQ/qJZmgoCLT5Xud1NiJ89Uz0z1Tzg+Krkp3mq5/3r/eq+4IpCDQEQp8SrxvNwxjGQ2bqDWgAIxZgO87lD9S7hf2MQuRkB+OAKIdRUQHQSQQG++MzverH3zfsagpIT9eJjEaUIFIUy7noyVIoKOlISEFiVGYNbSFrBIPD8a4awliVrAYllW6BsNqbMze1hKSkwtnRaKsojCEDxtQqdisojAkREVGB1tlzGYVhSEq1C4zraLwQ0OkwBUhRRx5I0dmWkWhnSBWVtgXbYNLGNAMCbkfLqKVIBwd8c7BCFxEM0HcjQ5GmzWEFtKB+D53o4PRJkJkwv3oYETN9r8lXKYk23+JnrGuaAccomUdkg1efKWsCiMPtEu7uUBV6MZ4eCCvws0TQQwv45CsQlXoAPLE/Uf1wTNjFmIVhbsRQnf319DK7ZExC7GKws8ZwA1YBFFFTcAbCrSKwq/F54EsFMMqim8iyxTDKoqyr0OkFMNku+EMV4wUwyoKDQQx2+iteAweUeaWEZF0b7bcIu8IqQ/MSfZfxl/BDUzT9GRDZ8eRIEvm/YG1Ta1oXfgPaqtrpp2793wIF0b6qPWjOHB0RGPwGPHjgey73R9o8sdX7EHrr0uzXYoXFC17bvSgf/Q2CsE05Xyv7cL4Zi2vD2e6oL52Dm5sOoU/5waRCxw5q3771yrIOGryQ0TGu6JnUQIyWoYj49r6Y/jZtl48fvPEskbf6B1r7WABgnW/YO3v/1FrTV63c+lmK1rysVAp1g5FRsscW7F32iRPPOglO3SnvVnwpwU4v/qQFVXMxId3aD66yjo6eKTIWOf9jSgRadMuT8qJGMzj10+wrnc3Jj6+tca11bOxZfEaOKGU0cGwIClL3q2LVyf7Lyde4fDt08gFFuXEYO+X+/zlQJAUH5+9xqCH6El1opHCX3E3NkRh/xa5cvLhpWSfo6SxbkH2X5LybKqPz15jmLK6m7+VzjwRtAnSP3oLTuA144WtWLPfKwUcoTtIjHaUAf6pXWJbINwS8tn+i6i2quaM6jtbFCdhm6nstGhuY+TiUN9XqiaQGI6HHw2jjEj7+kZK+QxTAm27dtBx+ny68zqLqoZtQogr0IBMm7uY6gTrFsIJnKFsYky7V7mTSZCrqsPpV9UWubBr2Wb7MEbRUVa2yEQmQSKYSsmcKY7/vxe5wOLZ6xeiBxqRVhD6q7IYycksmdeMc7YqNBVcu3B1ayOGSWG1Ies7cVpcb9IhpMacTtVWn7OPVWdQWuWdML8emEGzTnbJCRIkQG1IOmOc2kp8y9AEwzmKcZNaAyoBnii19qlJj9tEeEatm1oImvMZae4KU1LcOfQAAAAASUVORK5CYII=";
    if (this.state.entity.contact && this.state.entity.contact.logo) {
      imgSrc = `${process.env.REACT_APP_MEDIA_BASE_URL + this.state.entity.contact.logo.diskName}`;
    }
    return imgSrc;
  };

  render() {
    const { entity } = this.state;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {this.props.isMobile ? (
          <span
            style={{
              textAlign: "left",
              display: "inline-block",
              padding: "0 15px ",
              fontSize: "15px",
              borderBottom: "1px solid rgb(192, 193, 194)",
            }}
          >
            {entity.contact && entity.contact.firstName}
            <br />
            <span style={{ color: "#808692", fontSize: "14px" }}>{entity.firstName ? entity.firstName + " " + entity.lastName : ""}</span>
            <br />
            <span style={{ color: "#808692", fontSize: "10px" }}>{entity.email}</span>
          </span>
        ) : (
          <></>
        )}
        <Link className="isoDropdownLink" to="/change-password">
          <IntlMessages id="topbar.changePassword" />
        </Link>
        <a
          className="isoDropdownLink"
          onClick={() => {
            window.localStorage.removeItem("userData");
            this.props.logout();
            this.props.closeAll();
          }}
        >
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <span className="img-block">
          <img src={this.renderLogo()} alt={entity.contact && entity.contact.firstName} />
        </span>
        <span className="userActivity online" />
        {this.props.isMobile ? (
          <></>
        ) : (
          <span
            style={{
              textAlign: "left",
              display: "inline-block",
              marginRight: "15px",
              fontSize: "15px",
            }}
          >
            {entity.contact && entity.contact.firstName}
            <br />
            <span style={{ color: "#808692", fontSize: "14px" }}>{entity.firstName ? entity.firstName + " " + entity.lastName : ""}</span>
            <br />
            <span style={{ color: "#808692", fontSize: "14px" }}>{entity.email}</span>
          </span>
        )}
        <span style={{ float: "right" }}>
          <i className="ion-ios-arrow-down" />
        </span>
      </Popover>
    );
  }
}
export default connect(null, { logout, closeAll, fetchUserDetail })(TopbarUser);
