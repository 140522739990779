import asyncComponent from "Common/helpers/AsyncFunc";

const inspectionroutes = [
    {
        path: 'inspection/trailers',
        component: asyncComponent(() => import('Inspection/containers/ListTrailerInspections')),
        scope: 'read:users'
    },
    {
        path: 'inspection/vehicle',
        component: asyncComponent(() => import('Inspection/containers/ListVehicleInspections')),
        scope: 'read:users'
    },
    {
        path: 'inspection/:id',
        component: asyncComponent(() => import('Inspection/containers/RetrieveInspections')),
        scope: 'read:users'
    }
]

export default inspectionroutes;
