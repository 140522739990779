import React from 'react';
import {Row, Col} from 'antd';
import EntityField from 'Common/styles/entityField.style';

const EntitySection = ({label, children}) => (

  <EntityField>
    <Row gutter={48} className="detail-item">
      <Col lg={8} md={12} sm={12} xs={12}>{label}</Col>
      <Col lg={8} md={12} sm={12} xs={12}>{children}</Col>
    </Row>
  </EntityField>

);

export default EntitySection;
