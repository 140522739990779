import React from "react";
import { Alert, DatePicker } from "antd";
import moment from "moment";

const DatePickerField = ({
  input: { onChange, value },
  disablePrev,
  type,
  reset,
  meta: { touched, error },
  ...custom
}) => {
  const hasError = touched && error !== undefined;

  // function prevDateDisable(current) {
  //   return current < moment().startOf("day");
  // }
  // function nextDateDisable(current) {
  //   return current > moment().endOf("day");
  // }
  
  return (
    <div className={hasError ? "has-error" : ""}>
      <DatePicker
        onChange={onChange}
        value={!value ? value : moment(value)}
        format={"DD/MM/YYYY"}
        {...custom}
        // disabledDate={disablePrev ? prevDateDisable : nextDateDisable}
      />

      {hasError && (
        <Alert className="alert" type="error" message={error} banner />
      )}
    </div>
  );
};

export default DatePickerField;
