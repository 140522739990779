import React, { useState } from "react";
import styled from "styled-components";
import PdfThumbnail from "./pdfThumbnail";
import PDFViewer from "./pdfViewer";
import Fallback from "Common/svg/fallback.svg";
import Doc from "Common/svg/doc.svg";

const mediaUrl = process.env.REACT_APP_MEDIA_BASE_URL;

const DocumentCard = ({ image, pdfWidth, pdfHeight, handleClickImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePdfOnClick = () => {
    setIsOpen((preState) => !preState);
  };
  return (
    <DocumentCardWrapper>
      {image.diskName && image.diskName.endsWith(".pdf") ? (
        <PdfThumbnail pdfUrl={`${mediaUrl}${image.diskName}`} width={pdfWidth} height={pdfHeight} handleOnClick={handlePdfOnClick} />
      ) : image.diskName && (image.diskName.endsWith(".doc") || image.diskName.endsWith(".xlsx") || image.diskName.endsWith(".docx")) ? (
        <img
          onClick={handlePdfOnClick}
          src={Doc} // Fallback image for documents
          alt="Document Image"
          style={{
            width: "100%",
            height: pdfHeight,
            cursor: "pointer",
            objectFit: "cover",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = Fallback;
          }}
        />
      ) : (
        <img
          onClick={() => handleClickImage()}
          src={`${mediaUrl}${image.diskName}`}
          alt="Document Image"
          style={{
            width: "100%",
            height: pdfHeight,
            cursor: "pointer",
            objectFit: "cover",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = Fallback;
          }}
        />
      )}
      <DocumentCardContent>
        {!image?.title && !image?.comment ? (
          <NoContent>
            {image?.fileName || image?.filename || image?.imageName ? (
              <DocumentFileName>{image.fileName || image.filename || image.imageName}</DocumentFileName>
            ) : (
              <NoContentText>No Title and Comments available</NoContentText>
            )}
          </NoContent>
        ) : (
          <>
            {image?.title && <DocumentCardTitle>{image.title}</DocumentCardTitle>}
            {image?.comment && <DocumentCardComment>{image.comment}</DocumentCardComment>}
          </>
        )}
      </DocumentCardContent>
      {isOpen && (
        <PDFViewer isOpen={isOpen} pdfUrl={`${mediaUrl}${image.diskName}`} onClose={handlePdfOnClick} fileType={image.diskName.split(".").pop()} />
      )}
    </DocumentCardWrapper>
  );
};

const DocumentCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
`;

const DocumentCardContent = styled.div`
  padding: 14px;
`;

const DocumentCardTitle = styled.h3`
  font-size: 16px;
  margin: 0;
`;

const DocumentFileName = styled.div`
  width: 100%;
  font-size: 12px;
  margin: 0;
  font-weight: 600;
  word-wrap: break-word;
`;

const DocumentCardComment = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 4px;
`;

const NoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoContentText = styled.span`
  font-size: 10px;
  color: #666;
`;

export default DocumentCard;
