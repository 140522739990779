const actions = {
  RETRIEVE_INSPECTION_REQUEST: "RETRIEVE_INSPECTION_REQUEST",
  DOWNLOAD_INSPECTION_LIST_REQUEST: "DOWNLOAD_INSPECTION_LIST_REQUEST",
  DOWNLOAD_INSPECTION_DETAIL_REQUEST: "DOWNLOAD_INSPECTION_DETAIL_REQUEST",
  retrieveInspections: (id, resolve, reject) => {
    return {
      type: actions.RETRIEVE_INSPECTION_REQUEST,
      id,
      resolve,
      reject,
    };
  },

  downloadInspectionList: (downloadType,query, resolve, reject) => {
    return {
      type: actions.DOWNLOAD_INSPECTION_LIST_REQUEST,
      downloadType,
      query,
      resolve,
      reject,
    };
  },

  downloadInspectionDetail: (id, resolve, reject) => {
    return {
      type: actions.DOWNLOAD_INSPECTION_DETAIL_REQUEST,
      id,
      resolve,
      reject,
    };
  },
};

export default actions;
