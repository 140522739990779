import React from "react";
import { Modal, Button, notification } from "antd";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import ReactFileViewer from "react-file-viewer";

const PDFViewer = ({ isOpen, pdfUrl, onClose, fileType }) => {
  const handleDownload = async () => {
    try {
      // Fetch the PDF file
      const response = await fetch(pdfUrl);

      if (!response.ok) {
        notification.error({ message: `HTTP error! status: ${response.status}` });
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a blob URL
      const blobUrl = window.URL.createObjectURL(blob);

      // Create an invisible link element
      const link = document.createElement("a");
      link.href = blobUrl;

      // Extract filename from URL or use a default name
      const filename = pdfUrl.split("/").pop() || "document.pdf";
      link.download = filename;

      // Append link, click it, and clean up
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed:", error);
      notification.error({ message: "Failed to download PDF. Please try again." });
    } finally {
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      closable={false}
      maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.19)" }}
      bodyStyle={{ padding: 0 }} // Set padding of the modal body to 0
    >
      <ModalHeader>
        <DownloadButton onClick={handleDownload}>
          <DownloadOutlined /> Download
        </DownloadButton>
        <CloseButton onClick={onClose}>
          <CloseOutlined />
        </CloseButton>
      </ModalHeader>

      <ModalContent>
        {fileType === "pdf" ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfUrl} />
          </Worker>
        ) : (
          <ReactFileViewerWrap>
            <ReactFileViewer
              fileType={fileType} // or doc if you're working with .doc files
              filePath={pdfUrl}
            />
          </ReactFileViewerWrap>
        )}
      </ModalContent>
    </Modal>
  );
};

// Styled components

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const DownloadButton = styled(Button)`
  background-color: green !important;
  color: white;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  border: green !important;

  &:hover &:focus,
  &:active {
    background-color: darkgreen;
    color: white;
  }
`;

const CloseButton = styled(Button)`
  background: none;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const ReactFileViewerWrap = styled.div`
.pg-viewer-wrapper {
    overflow: hidden !important;
     .document-container {
     width: 480px;
     }
}
.
`;

export default PDFViewer;
