import React, { Component } from "react";
import moment from "moment/moment";

import IntlMessages from "Common/components/intlMessages";
import EntitySection from "Common/components/entitySection";
import Section from "Common/components/section";
import ItemStatus from "Common/components/ItemSummary/status";
// import BookingStopView from "Booking/components/BookingStopView";
import { fullDate, loadDateFormat } from "Booking/containers/RetrieveBooking";
import ProofOfDeliveryView from "Booking/components/ProofOfDeliveryView";
import { Table, Icon, Button } from "antd";
import { localizeDate } from "Booking/components/BookingCreateForm";
import createNotification from "../../Common/components/notification";
import Auth0 from "Common/helpers/auth0-custom/index";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Row, Col } from "antd";
import DocumentCard from "Common/components/Document/documentCard";

const auth0 = new Auth0();
const JOB_STATUS_COMMENCED = "commenced";
const jobCompletedStatus = "completed";

class BookingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  normalizeJourneyType = (value) => (value === true ? "Single Journey" : value === false ? "Return Journey" : "");

  downloadIndividualRowPdf = async (type, record) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const id = record.id;
    const fileName = type === "checklist" ? "vehicle-inspection-checklist-" : "driver-fit-for-duty-";
    const response = await fetch(baseUrl + "/job/" + id + "/" + type + "/pdf", {
      method: "GET",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${auth0.getAccessToken()}`,
      },
    });
    if (response.status === 200) {
      response
        .blob()
        .then(async (blob) => {
          const url = window.URL.createObjectURL(blob);
          const aTag = document.createElement("a");
          aTag.href = url;
          aTag.download = fileName + id + "-" + moment().format("DD MMM, YYYY");
          aTag.click();
        })
        .catch((error) => {
          console.log({ error });
        });
    } else {
      createNotification("error", "NOT FOUND", "File Not Found");
    }
  };
  handlePodRowClick = (delivery) => {
    const { history, entity } = this.props;
    history.push("/shift/" + delivery.shiftId + "/" + entity.driver?.id);
  };

  render() {
    const { entity } = this.props;
    const { photoIndex } = this.state;
    const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
    const imagesUrl = entity?.images?.map((image, index) => ({
      url: MEDIA_BASE_URL + image.diskName,
      title: [
        <Button
          type="primary"
          style={{
            backgroundColor: "#128239",
            borderColor: "#128239",
            borderRadius: 5,
          }}
          onClick={(e) => {
            e.preventDefault();
            const mediaUrl = MEDIA_BASE_URL + entity.images[index].diskName;
            const filename = entity.images[index].diskName;
            fetch(mediaUrl + `?timestamp=${new Date().getTime()}`)
              .then(function (response) {
                return response.blob();
              })
              .then(function (bolb) {
                const url = window.URL.createObjectURL(bolb);
                const aTag = document.createElement("a");
                aTag.href = url;
                aTag.download = filename;
                aTag.click();
              });
          }}
        >
          Download
        </Button>,
      ],
    }));

    const assetsColumnsTrailer = [
      {
        title: "DC/Store Number",
        dataIndex: "costumerStoreNumber",
        key: "customer",
      },
      {
        title: "Pickup location",
        dataIndex: "pickupLocation",
        key: "pickupLocation",
      },
      {
        title: "Depart Time",
        dataIndex: "departureTime",
        key: "departTime",
        render: (date) => (date ? <p>{localizeDate(date).format("HH:mm")}</p> : null),
      },
      {
        title: "Number/Rego",
        dataIndex: "rego",
        key: "rego",
      },
      {
        title: "Docket Number",
        dataIndex: "docketNumbers",
        key: "docketNo",
      },
      {
        title: "Drop-off location",
        dataIndex: "destinationLocation",
        key: "destinationLocation",
      },
      {
        title: "Arrival Time",
        dataIndex: "arrivalTime",
        key: "arrivalTime",
        render: (date) => (date ? <p>{localizeDate(date).format("HH:mm")}</p> : null),
      },
      {
        title: "Unit Type",
        dataIndex: "unitType",
        key: "unitType",
      },
      {
        title: "Units in",
        dataIndex: "unitsIn",
        key: "unitsIn",
      },
      {
        title: "Units out",
        dataIndex: "unitsOut",
        key: "unitsOut",
      },
      {
        title: "Odometer",
        dataIndex: "oddoMeter.speedoMeter",
        key: "oddoMeter",
      },
    ];
    const assetsColumnAssets = [
      {
        title: "Asset ID",
        dataIndex: "assetId",
        key: "assetId",
      },

      {
        title: "Asset Type",
        dataIndex: "assetType",
        key: "assetType",
      },
      {
        title: "Load Type",
        dataIndex: "loadType",
        key: "loadType",
      },
      {
        title: "Temperature",
        dataIndex: "temperature",
        key: "temperature",
      },
    ];

    const assetsColumnsSideLoader = [
      {
        title: " Trailer Number/Rego",
        dataIndex: "rego",
        key: "rego",
      },
      {
        title: "Container Number",
        dataIndex: "docketNumbers",
        key: "docketNo",
      },
      {
        title: "Customer",
        dataIndex: "costumerStoreNumber",
        key: "customer",
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
      },
      {
        title: "Weight or MT",
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: "From",
        dataIndex: "pickupLocation",
        key: "pickupLocation",
      },

      {
        title: "Arrive",
        dataIndex: "arrivalTime",
        key: "arrivalTimePickup",
        render: (date) => (date ? <p>{localizeDate(date).format("HH:mm")}</p> : null),
      },
      {
        title: "Depart",
        dataIndex: "departureTime",
        key: "departTimePickup",
        render: (date) => (date ? <p>{localizeDate(date).format("HH:mm")}</p> : null),
      },
      {
        title: "To",
        dataIndex: "destinationLocation",
        key: "destinationLocation",
      },
      {
        title: "Arrive",
        dataIndex: "destinationArrivalTime",
        key: "arrivalTimeDestination",
        render: (date) => (date ? <p>{localizeDate(date).format("HH:mm")}</p> : null),
      },
      {
        title: "Depart",
        dataIndex: "destinationDepartureTime",
        key: "departTimeDestination",
        render: (date) => (date ? <p>{localizeDate(date).format("HH:mm")}</p> : null),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
      },
    ];

    let processedListTrailer = [];
    let processedListAsset = [];
    if (entity.assets) {
      if (entity.assets.length) {
        entity.assets.forEach((assets) => {
          const data = {
            ...assets,
            assetType: assets.assetType?.title,
            loadType: assets.loadType?.title,
          };
          processedListAsset.push(data);
        });
      }
    }

    if (entity.assets) {
      entity.assets.forEach(({ dockets = [], assetId }) => {
        if (dockets.length) {
          dockets.forEach((docket) => {
            const dta = {
              rego: assetId,
              docketNumbers: docket.docketNumber.substring(docket.docketNumber.indexOf("-") + 1),
              ...docket,
            };
            docket.asset.assetType.title == "Trailer" || docket.asset.assetType.title == "B double" ? processedListTrailer.push(dta) : null;
          });
        }
      });
    }

    let processedListSideLoader = [];
    if (entity.assets) {
      entity.assets.forEach(({ dockets = [], assetId, docketNumber }) => {
        if (dockets.length) {
          dockets.forEach((docket) => {
            const dta = {
              rego: assetId,
              docketNumbers: docket.docketNumber.substring(docket.docketNumber.indexOf("-") + 1),
              ...docket,
            };
            docket.asset.assetType.title == "Sideloader" ? processedListSideLoader.push(dta) : null;
          });
        }
      });
    }
    return (
      <div id="jobDetailWrapper">
        <div id="jobDetailPrintWrapper" style={{ backgroundColor: "#ffffff" }}>
          <Row gutter={30}>
            <Col lg={14}>
              <Section
                label={
                  <h2>
                    <IntlMessages id="sectiontitle.basic" />
                  </h2>
                }
              >
                <EntitySection label={<IntlMessages id="forms.label.status" />}>
                  <ItemStatus status={entity.status || ""} />
                </EntitySection>
                <EntitySection label={<IntlMessages id="forms.label.payment" />}>
                  <ItemStatus status={entity.paymentStatus || "-"} />
                </EntitySection>
                <EntitySection label={<IntlMessages id="forms.label.contractor" />}>{entity.contractor?.name || "-"}</EntitySection>
                <EntitySection label={<IntlMessages id="forms.label.referenceNo" />}>{entity.refNo || "-"}</EntitySection>
                <EntitySection label={<IntlMessages id="forms.label.invoiceAmount" />}>{entity.invoiceAmount || "-"}</EntitySection>
                <EntitySection label={<IntlMessages id="forms.label.comments" />}>{entity.comments || "-"}</EntitySection>
              </Section>
            </Col>

            <Col lg={10}>
              <h2>
                <IntlMessages id="sectiontitle.image" />
              </h2>

              <Row gutter={[16, 16]}>
                {entity.images &&
                  entity.images.map((image, index) => (
                    <Col lg={10} sm={8} xs={12} key={index}>
                      <DocumentCard
                        image={image}
                        pdfWidth={150}
                        pdfHeight={130}
                        handleClickImage={() => this.setState({ isOpen: true, photoIndex: index })}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
          <Section
            label={
              <h2>
                <IntlMessages id="sectiontitle.operations" />
              </h2>
            }
          >
            <EntitySection label={<IntlMessages id="forms.label.driver" />}>
              {entity.driver?.firstName + entity.driver?.lastName || "-"}
            </EntitySection>
            <EntitySection label={<IntlMessages id="forms.label.vehicle" />}>{entity.vehicle?.registrationNumber || "-"}</EntitySection>
            <EntitySection label={<IntlMessages id="forms.label.driverInstruction" />}>{entity.driverInstruction || "-"}</EntitySection>
            <EntitySection label={<IntlMessages id="forms.label.trailer" />}>
              {entity.vehicleAssets?.length > 0
                ? entity.vehicleAssets.map((trailer) => (
                    <div key={trailer.id}>
                      <strong>{trailer.title}:</strong> {trailer.registrationNumber}
                    </div>
                  ))
                : "-"}{" "}
            </EntitySection>
          </Section>
          <Section
            label={
              <h2>
                <IntlMessages id="sectiontitle.journey" />
              </h2>
            }
          >
            <EntitySection label={<IntlMessages id="forms.label.departureLocation" />}>
              {(entity.departureLocation && entity.departureLocation.name) || ""}
            </EntitySection>
            <EntitySection label={<IntlMessages id="forms.label.departureDate" />}>
              <Icon type="calendar" />
              {loadDateFormat(entity.departureDate, fullDate, entity.companyTimeOffset)}
            </EntitySection>
            <EntitySection label={<IntlMessages id="forms.label.arrivalLocation" />}>
              {(entity.arrivalLocation && entity.arrivalLocation.name) || ""}
            </EntitySection>
            <EntitySection label={<IntlMessages id="forms.label.arrivalDate" />}>
              <Icon type="calendar" />
              {loadDateFormat(entity.arrivalDate, fullDate, entity.companyTimeOffset)}
            </EntitySection>
            {entity.assets && entity.assets.length ? (
              <Section
                label={
                  <h2>
                    <IntlMessages id="sectiontitle.assets" />
                  </h2>
                }
              >
                <Table dataSource={processedListAsset} columns={assetsColumnAssets} />

                {processedListTrailer.length === 0 ? null : (
                  <div>
                    <h4>Trailer</h4>
                    <Table dataSource={processedListTrailer} columns={assetsColumnsTrailer} />
                  </div>
                )}

                {processedListSideLoader.length === 0 ? null : (
                  <div>
                    <h4>Sideloader</h4>
                    <Table dataSource={processedListSideLoader} columns={assetsColumnsSideLoader} />
                  </div>
                )}
              </Section>
            ) : null}
            {/* {entity.jobStops && entity.jobStops.length ? (
              <div>
                <h3>
                  <IntlMessages id="forms.label.stops" />
                </h3>
                <BookingStopView stops={entity.jobStops} />
              </div>
            ) : (
              ""
            )} */}
            {entity.proofOfDeliveries && entity.proofOfDeliveries.length ? (
              <Section
                label={
                  <h2>
                    <IntlMessages id="sectiontitle.pod" />
                  </h2>
                }
              >
                <ProofOfDeliveryView pods={entity.proofOfDeliveries} onRowClick={this.handlePodRowClick} />
              </Section>
            ) : (
              ""
            )}
          </Section>
          {(entity.status == jobCompletedStatus || entity.status == JOB_STATUS_COMMENCED) && (
            <Section
              label={
                <h4>
                  <IntlMessages id="sectiontitle.jobactivity" />
                </h4>
              }
            >
              <Table columns={this.getDefaultColumns()} dataSource={this.getDataSource()} pagination={false} />
            </Section>
          )}
          <br />
        </div>

        <div>
          {this.state.isOpen &&
            (imagesUrl?.length == 1 ? (
              <Lightbox
                image={imagesUrl[0]?.url}
                onClose={() => this.setState({ isOpen: false })}
                title={[
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#128239",
                      borderColor: "#128239",
                      borderRadius: 5,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      const mediaUrl = MEDIA_BASE_URL + entity.images[this.state.photoIndex].diskName;
                      const filename = entity.images[this.state.photoIndex].diskName;
                      fetch(mediaUrl + `?timestamp=${new Date().getTime()}`)
                        .then(function (response) {
                          return response.blob();
                        })
                        .then(function (bolb) {
                          const url = window.URL.createObjectURL(bolb);
                          const aTag = document.createElement("a");
                          aTag.href = url;
                          aTag.download = filename;
                          aTag.click();
                        });
                    }}
                  >
                    Download
                  </Button>,
                ]}
              />
            ) : (
              <Lightbox images={imagesUrl} startIndex={photoIndex} onClose={() => this.setState({ isOpen: false })} />
            ))}
        </div>
      </div>
    );
  }

  getDataSource = () => {
    const { entity } = this.props;
    let data = [];
    let vehicle = entity.vehicle?.registrationNumber;
    let jobStart = localizeDate(entity && entity.departureTime) || "N/A";
    if (entity.status == jobCompletedStatus || entity.status == JOB_STATUS_COMMENCED) {
      let jobDetails = {
        jobId: entity.id,
        time: jobStart === "N/A" ? "N/A" : jobStart.format("DD/MM/YYYY HH:mm"),
        location: entity.departureLocation && entity.departureLocation.address ? entity.departureLocation.address : "N/A",
        fleet: vehicle,
        latlng: entity.departureLocation && entity.departureLocation.geolocation ? entity.departureLocation.geolocation : null,
      };

      data.push({
        ...jobDetails,
        key: entity.id + "job-selected",
        action: " Job Selected",
      });

      data.push({
        ...jobDetails,
        key: entity.id + "driverFitToDrive",
        action: " Driver Fit To Drive",
        remark: (
          <Icon type="download" style={{ fontSize: "25px", color: "#08c" }} onClick={() => this.downloadIndividualRowPdf("fitfordrive", entity)} />
        ),
      });

      data.push({
        ...jobDetails,
        key: entity.id + "vehicle-selected",
        action: " Vehicle Selected",
      });

      data.push({
        ...jobDetails,
        key: entity.id + "vehicleInspectionCheckList",
        action: " Vehicle Inspection Check",
        remark: (
          <Icon type="download" style={{ fontSize: "25px", color: "#08c" }} onClick={() => this.downloadIndividualRowPdf("checklist", entity)} />
        ),
      });

      data.push({
        ...jobDetails,
        key: entity.id + "job-start",
        action: " Job Start",
        odometer: entity.startMeterReading ? entity.startMeterReading?.speedoMeter : "N/A",
      });
      if (entity.jobStops?.length > 0) {
        entity.jobStops
          .sort((a, b) => {
            const aDate = moment(a.arrivalTime);
            const bDate = moment(b.arrivalTime);
            return aDate.diff(bDate);
          })
          .map((stops) => {
            //show break details
            let breakStart = localizeDate(stops.arrivalTime);
            data.push({
              jobId: entity.id,
              key: stops.id + "stops-arrive",
              action: "Break",
              odometer: stops.startMeterReading ? stops.startMeterReading.speedoMeter : "N/A",
              time: breakStart.format("DD/MM/YYYY HH:mm"),
              location: stops.location && stops.location.address ? stops.location.address : "N/A",
              fleet: vehicle,
              latlng: stops.location && stops.location.geolocation ? stops.location.geolocation : null,
            });

            //show break resume details
            let breakEnd = stops.departureTime ? localizeDate(stops.departureTime) : null;
            if (breakEnd) {
              data.push({
                jobId: entity.id,
                key: stops.id + "stops-leave",
                action: "Resume",
                odometer: stops.startMeterReading ? stops.startMeterReading.speedoMeter : "N/A",
                time: breakEnd.format("DD/MM/YYYY HH:mm"),
                location: stops.location && stops.location.address ? stops.location.address : "N/A",
                fleet: vehicle,
                latlng: stops.location && stops.location.geolocation ? stops.location.geolocation : null,
              });
            } else if (entity.status == jobCompletedStatus) {
              data.push({
                jobId: entity.id,
                key: stops.id + "stops-leave",
                action: "Resume",
                time: <span style={{ color: "#ff0000" }}>Did not Resume</span>,
              });
            }
          });
      }
      let jobEnd = entity.arrivalTime ? localizeDate(entity.arrivalTime) : null;
      if (jobEnd) {
        data.push({
          jobId: entity.id,
          key: entity.id + "job-stops",
          action: " Job Complete",
          odometer: entity.endMeterReading ? entity.endMeterReading.speedoMeter : "N/A",
          time: entity.arrivalTime ? localizeDate(entity.arrivalTime).format("DD/MM/YYYY HH:mm") : "N/A",
          location: entity.arrivalLocation && entity.arrivalLocation.address ? entity.arrivalLocation.address : "N/A",
          fleet: vehicle,
          latlng: entity.arrivalLocation && entity.arrivalLocation.geolocation ? entity.arrivalLocation.geolocation : null,
        });
      }
    }

    return data;
  };

  getDefaultColumns = () => {
    return [
      {
        title: "Action",
        key: "action",
        dataIndex: "action",
      },
      {
        title: "Odometer",
        key: "odometer",
        dataIndex: "odometer",
      },
      {
        title: "Time",
        key: "time",
        dataIndex: "time",
      },
      {
        title: "Location",
        key: "location",
        dataIndex: "location",
        render: (text, row) => {
          let latlng = row.latlng && JSON.parse(row.latlng);
          return (
            <div>
              {latlng ? (
                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${latlng.lat},${latlng.lng}`}>
                  {text}
                </a>
              ) : (
                text
              )}
            </div>
          );
        },
      },
      {
        title: "Registration No.",
        key: "fleet",
        dataIndex: "fleet",
      },
      {
        title: "Remarks",
        key: "remark",
        dataIndex: "remark",
      },
    ];
  };
}

export default BookingDetails;
