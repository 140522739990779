import { takeEvery, call, all, fork } from "redux-saga/effects";
import actions from "Inspection/redux/actions";
import Api from "Common/api/Api2";

const api = new Api();

export function* retrieveInspections() {
  yield takeEvery(
    actions.RETRIEVE_INSPECTION_REQUEST,
    function* ({ id, resolve, reject }) {
      try {
        const response = yield call(api.get, "inspection/activity/" + id, {});
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

export function* downloadInspectionList() {
  yield takeEvery(
    actions.DOWNLOAD_INSPECTION_LIST_REQUEST,
    function* ({ downloadType, query, resolve, reject }) {
      const csvTypes = downloadType === "csv" ? "&format=csv" : "";
      try {
        const response = yield call(
          api.getBlob,
          "inspection/activity/download/" +
            downloadType +
            "?" +
            query +
            csvTypes,
          {}
        );
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}
export function* downloadInspectionDetail() {
  yield takeEvery(
    actions.DOWNLOAD_INSPECTION_DETAIL_REQUEST,
    function* ({ id, resolve, reject }) {
      try {
        const response = yield call(
          api.getBlob,
          "inspection/activity/" + id + "/download",
          {}
        );
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(retrieveInspections),
    fork(downloadInspectionList),
    fork(downloadInspectionDetail),
  ]);
}
