import React, { useState } from "react";
import { Table } from "antd";
import { TableWrapperStyle } from "Common/components/Table/styles/index.style";
import { DisabledResponsiveTableWrapper } from "Common/components/Table/styles/responsiveTableWrapper.style";
import { normalizeDateFormat } from "Booking/containers/RetrieveBooking";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const ProofOfDeliveryView = ({ pods, onRowClick }) => {
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(
      `${process.env.REACT_APP_MEDIA_BASE_URL}${image.diskName}`
    );
    setIsLightboxVisible(true);
  };

  const closeLightbox = () => {
    setIsLightboxVisible(false);
    setSelectedImage(null);
  };

  const getDefaultToolboxColumns = () => {
    return [
      {
        title: "Date",
        dataIndex: "activityTime",
        key: "activityTime",
        render: (data) => normalizeDateFormat(data, "DD/MM/YYYY"),
      },
      {
        title: "Address",
        dataIndex: "address.address",
        key: "address.address",
      },
      {
        title: "Comment",
        dataIndex: "payload.comment",
        key: "payload.comment",
      },
      {
        title: "Photo",
        key: "payload.images",
        dataIndex: "payload.images",
        render: (images) =>
          images && images.length > 0 ? (
            <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={`${process.env.REACT_APP_MEDIA_BASE_URL}${image.diskName}`}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "50px",
                    cursor: "pointer",
                  }}
                  alt={`signature-${index}`}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div>
          ) : (
            "-"
          ),
      },
      {
        title: "Details",
        key: "details",
        render: (_, record) => (
          <a
            onClick={() => {
              onRowClick(record);
            }}
          >
            View Details
          </a>
        ),
      },
    ];
  };

  return (
    <TableWrapperStyle>
      <DisabledResponsiveTableWrapper>
        <Table
          // onRow={(record, rowIndex) => ({
          //   onClick: () => {
          //     onRowClick(record);
          //   },
          // })}
          columns={getDefaultToolboxColumns()}
          dataSource={pods}
          className="tableStyled"
          pagination={false}
        />
      </DisabledResponsiveTableWrapper>
      {isLightboxVisible && (
        <Lightbox image={selectedImage} onClose={closeLightbox} />
      )}
    </TableWrapperStyle>
  );
};

export default ProofOfDeliveryView;
