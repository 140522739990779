const actions = {
  CREATE_VEHICLE_REQUEST: "CREATE_VEHICLE_REQUEST",
  RETRIEVE_VEHICLE_REQUEST: "RETRIEVE_VEHICLE_REQUEST",

  FETCH_VEHICLE_LIST_REQUEST: "FETCH_VEHICLE_LIST_REQUEST",
  FETCH_VEHICLE_LIST_SUCCESS: "FETCH_VEHICLE_LIST_SUCCESS",
  FETCH_VEHICLE_LIST_FAIL: "FETCH_VEHICLE_LIST_FAIL",

  FETCH_ASSET_LIST_REQUEST: "FETCH_ASSET_LIST_REQUEST",
  FETCH_ASSET_LIST_SUCCESS: "FETCH_ASSET_LIST_SUCCESS",
  FETCH_ASSET_LIST_FAIL: "FETCH_ASSET_LIST_FAIL",

  UPDATE_VEHICLE_REQUEST: "UDPATE_VEHICLE_REQUEST",
  DELETE_VEHICLE_REQUEST: "DELETE_VEHICLE_REQUEST",
  FETCH_USER: "FETCH_USER",

  LIST_METER_READING_REQUEST: "LIST_METER_READING_REQUEST",
  UPDATE_METER_READING_REQUEST: "UPDATE_METER_READING_REQUEST",
  CREATE_METER_READING_REQUEST: "CREATE_METER_READING_REQUEST",

  CREATE_DOCUMENT_REQUEST: "CREATE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",

  UPDATE_DOCUMENT_REQUEST: "UPDATE_DOCUMENT_REQUEST",

  RETRIEVE_DOCUMENT_REQUEST: "RETRIEVE_DOCUMENT_REQUEST",

  FETCH_CONTRACTOR: "FETCH_CONTRACTOR",

  FETCH_EVENT_HISTORY_VIDEOS_REQUEST: "FETCH_EVENT_HISTORY_VIDEOS_REQUEST",
  FETCH_REAL_TIME_LOCATION: "FETCH_REAL_TIME_LOCATION",
  FETCH_LOCATION_HISTORY_REQUEST: "FETCH_LOCATION_HISTORY_REQUEST",
  FETCH_DEVICE_HISTORY_VIDEO_REQUEST: "FETCH_DEVICE_HISTORY_VIDEO_REQUEST",
  FETCH_DEVICE_INFO_REQUEST: "FETCH_DEVICE_INFO_REQUEST",

  CREATE_VEHICLE_INTEGRATION_REQUEST: "CREATE_VEHICLE_INTEGRATION_REQUEST",
  UPDATE_VEHICLE_INTEGRATION_REQUEST: "UPDATE_VEHICLE_INTEGRATION_REQUEST",
  FETCH_VEHICLE_INTEGRATION_REQUEST: "FETCH_VEHICLE_INTEGRATION_REQUEST",

  FETCH_VEHICLE_DETAILS_FROM_NHVR_REQUEST: "FETCH_VEHICLE_DETAILS_FROM_NHVR_REQUEST",

  FETCH_VEHICLE_TYPE_REQUEST: "FETCH_VEHICLE_TYPE_REQUEST",
  FETCH_VEHICLE_TYPE_SUCCESS: "FETCH_VEHICLE_TYPE_SUCCESS",

  FETCH_VEHICLE_DROPDOWN_REQUEST: "FETCH_VEHICLE_DROPDOWN_REQUEST",
  FETCH_VEHICLE_DROPDOWN_SUCCESS: "FETCH_VEHICLE_DROPDOWN_SUCCESS",

  REFETCH_REQUEST: "REFETCH_REQUEST",

  createVehicle: (data, context, resolve, reject) => ({
    type: actions.CREATE_VEHICLE_REQUEST,
    data,
    context,
    resolve,
    reject,
  }),
  retrieveVehicle: (id, resolve, reject) => ({
    type: actions.RETRIEVE_VEHICLE_REQUEST,
    id,
    resolve,
    reject,
  }),
  fetchVehicleList: (resolve, reject) => ({
    type: actions.FETCH_VEHICLE_LIST_REQUEST,
    resolve,
    reject,
  }),
  fetchAssetList: (resolve, reject) => ({
    type: actions.FETCH_ASSET_LIST_REQUEST,
    resolve,
    reject,
  }),
  listVehicle: (resolve, reject) => ({
    resolve,
    reject,
  }),
  updateVehicle: (id, data, resolve, reject) => ({
    type: actions.UPDATE_VEHICLE_REQUEST,
    id,
    data,
    resolve,
    reject,
  }),
  deleteVehicle: (id, resolve, reject) => ({
    type: actions.DELETE_VEHICLE_REQUEST,
    id,
    resolve,
    reject,
  }),
  // uploadImage(formData, resolve, reject){
  //     return {
  //         type:actions.UPLOAD_IMAGE_REQUEST,
  //         formData,
  //         resolve,
  //         reject
  //     }
  // },

  // deleteImage(id, resolve, reject){
  //     return {
  //         type:actions.DELETE_IMAGE_REQUEST,
  //         id,
  //         resolve,
  //         reject
  //     }
  // },

  createDocument: (data, context, resolve, reject) => ({
    type: actions.CREATE_DOCUMENT_REQUEST,
    data,
    context,
    resolve,
    reject,
  }),

  deleteDocument(id, resolve, reject) {
    return {
      type: actions.DELETE_DOCUMENT_REQUEST,
      id,
      resolve,
      reject,
    };
  },
  updateDocument: (id, data, resolve, reject) => ({
    type: actions.UPDATE_DOCUMENT_REQUEST,
    id,
    data,
    resolve,
    reject,
  }),
  retrieveDocument: (id, resolve, reject) => {
    return {
      type: actions.RETRIEVE_DOCUMENT_REQUEST,
      id,
      resolve,
      reject,
    };
  },
  createMeterReading: (vid, data, context, resolve, reject) => ({
    type: actions.CREATE_METER_READING_REQUEST,
    vid,
    data,
    context,
    resolve,
    reject,
  }),

  updateMeterReadig: (vid, id, data, resolve, reject) => ({
    type: actions.UPDATE_METER_READING_REQUEST,
    vid,
    id,
    data,
    resolve,
    reject,
  }),
  listMeterReading: (vehicleId, resolve, reject) => ({
    type: actions.LIST_METER_READING_REQUEST,
    vehicleId,
    resolve,
    reject,
  }),
  fetchContractorList: (resolve, reject) => ({
    type: actions.FETCH_CONTRACTOR,
    resolve,
    reject,
  }),
  fetchUser: (id, resolve, reject) => ({
    type: actions.FETCH_USER,
    id,
    resolve,
    reject,
  }),
  fetchEventHistoryVideos: (vehicleId, platform, beginTime, endTime, resolve, reject) => ({
    type: actions.FETCH_EVENT_HISTORY_VIDEOS_REQUEST,
    vehicleId,
    platform,
    beginTime,
    endTime,
    resolve,
    reject,
  }),
  fetchRealTimeLocation: (vehicleId, platform, resolve, reject) => ({
    type: actions.FETCH_REAL_TIME_LOCATION,
    vehicleId,
    platform,
    resolve,
    reject,
  }),
  fetchLocationHistory: (vehicleId, platform, beginTime, endTime, resolve, reject) => ({
    type: actions.FETCH_LOCATION_HISTORY_REQUEST,
    vehicleId,
    platform,
    beginTime,
    endTime,
    resolve,
    reject,
  }),
  fetchDeviceHistoryVideos: (vehicleId, channel, beginTime, selectedDate, resolve, reject) => ({
    type: actions.FETCH_DEVICE_HISTORY_VIDEO_REQUEST,
    vehicleId,
    channel,
    beginTime,
    selectedDate,
    resolve,
    reject,
  }),
  fetchDeviceInfo: (vehicleId, resolve, reject) => ({
    type: actions.FETCH_DEVICE_INFO_REQUEST,
    vehicleId,
    resolve,
    reject,
  }),
  createVehicleIntegration: (data, vehicleId, resolve, reject) => ({
    type: actions.CREATE_VEHICLE_INTEGRATION_REQUEST,
    data,
    vehicleId,
    resolve,
    reject,
  }),
  fetchVehicleIntegration: (vehicleId, resolve, reject) => ({
    type: actions.FETCH_VEHICLE_INTEGRATION_REQUEST,
    vehicleId,
    resolve,
    reject,
  }),
  updateVehicleIntegration: (vehicleId, data, resolve, reject) => ({
    type: actions.UPDATE_VEHICLE_INTEGRATION_REQUEST,
    vehicleId,
    data,
    resolve,
    reject,
  }),
  fetchVehicleDetailsFromNHVR: (vehicleRego, resolve, reject) => ({
    type: actions.FETCH_VEHICLE_DETAILS_FROM_NHVR_REQUEST,
    vehicleRego,
    resolve,
    reject,
  }),

  fetchVehicleType: (resolve, reject) => ({
    type: actions.FETCH_VEHICLE_TYPE_REQUEST,
    resolve,
    reject,
  }),

  fetchVehicleDropdown: (resolve, reject) => ({
    type: actions.FETCH_VEHICLE_DROPDOWN_REQUEST,
    resolve,
    reject,
  }),

  refetch: (status) => ({
    type: actions.REFETCH_REQUEST,
    status,
  }),
};

export default actions;
