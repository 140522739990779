import { all, takeEvery, put, fork, call} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import jwt_decode from 'jwt-decode';
import { getToken, clearToken } from 'Common/helpers/utility';
import actions from 'Public/redux/actions';
import Auth0 from 'Common/helpers/auth0-custom';
import  Api  from 'Common/api/Api';

const api = new Api();
const auth0 = new Auth0();

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function*({username,password,resolve,reject}) {
    try{
      const idToken = yield call(auth0.login,username,password);
      if(!auth0.hasWebLoginAccess()){
        auth0.logout();
        return yield call(reject,{ password:"You don't have the permission to login with this app."});
      }
      const profile = yield call(auth0.getProfileFromIdToken,idToken);
      yield put({
        type: actions.LOGIN_SUCCESS,
        idToken: idToken,
        profile
      });
      const decodedIdToken = jwt_decode(idToken);
      //check whether the user has change password or not
      if(decodedIdToken["https://user.io/passwordSet"] !==0){
        yield put(push('/dashboard'));
        yield call(resolve);
      } else{
         yield put(push('/set-password'));
      }
     } catch(error) {
         yield call(reject,{ password: error.description});
    }
  });
}


export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield call(clearToken);
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const idToken = getToken().get('idToken');
    if (idToken) {
      const decodedIdToken = jwt_decode(idToken);
      //check whether the user has change password or not
      if(decodedIdToken["https://user.io/passwordSet"] !==0){
        const profile = yield call(auth0.getProfileFromIdToken, idToken);
        if(window.location.pathname == "/"){
          yield put(push('/dashboard'));
        }else{
          yield put(push(window.location.pathname));
        }
        yield put({
          type: actions.LOGIN_SUCCESS,
          idToken,
          profile
        });
      } else{
        yield put(push('/set-password'));
      }
    }
  });
}

export function* validateEmail(){
  yield takeEvery(actions.VALIDATE_EMAIL,function*({email, resolve, reject}){
    try{
      const response = yield call(api.postWithoutAuthorization, "forgot-password" , email );
      yield call(resolve, response);
    }catch(error){
      yield call(reject, error);
    }
  });
}

export function* setForgotPassword(){
  yield takeEvery(actions.SET_FORGOT_PASSWORD,function*({data, resolve, reject}){
    try{
      const response = yield call(api.patchWithoutAuthorization, "reset-password" , data );
      yield call(resolve, response);
      yield put({
        type: actions.LOGOUT
      });
    }catch(error){
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginError),
    fork(logout),
    fork(validateEmail),
    fork(setForgotPassword)
  ]);
}
