import { all, takeEvery, fork, call } from "redux-saga/effects";
import actions from "Training/redux/actions";
import Api from "Common/api/Api2";

const api = new Api();

export function* fetchTrainingList() {
  yield takeEvery(
    actions.LIST_TRAINING_REQUEST,
    function* ({ resolve, reject }) {
      try {
        const response = yield call(api.get, "driver-training", {});
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}


export function* retrieveTraining() {
  yield takeEvery(
    actions.RETRIEVE_TRAINING_REQUEST,
    function* ({ id, resolve, reject }) {
      try {
        const response = yield call(api.get, "driver-training/training-details/" + id, {});
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}


export default function* rootSaga() {
  yield all([
    fork(fetchTrainingList),
    fork(retrieveTraining),

  ]);
}
