import styled from "styled-components";

const UploadDocumentWrapper = styled.div`
background-color:#E8F3EC;
border:1px dashed #128239;
color:#128239;
padding:60px 20px;
vertical-align: middle;
text-align: center;
	label{
		float:left;
		width:100%;
		text-align:left;
	}
`;

export default UploadDocumentWrapper;

