import React, { Component } from "react";
import { message, Table } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import PageHeader from "Common/components/Page/PageHeader";
import ItemStatus from "Common/components/ItemSummary/status";
// import { localizeDate } from "Booking/components/BookingCreateForm";
import JobFilter from "Booking/components/JobFilter";
import { fetchDriverRequest } from "Shifts/redux/actions";
import { TableWrapperStyle } from "Common/components/Table/styles/index.style";
import actions from "Booking/redux/actions";
import action from "Vehicle/redux/actions";
import { fullDate, loadDateFormat } from "./RetrieveBooking";

const { fetchVehicleDropdown } = action;

const { deleteBooking } = actions;

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Loads",
  },
];

class ListBooking extends Component {
  state = {
    loading: false,
    updatedOn: moment().valueOf(),
    status: "all",
    page: 1,
    pageSize: 50,
  };

  async componentDidMount() {
    const { fetchDriverRequest, fetchVehicleDropdown } = this.props;
    await Promise.all([new Promise((resolve, reject) => fetchDriverRequest(resolve, reject)), fetchVehicleDropdown()]);
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      status: nextProps.match.params.status,
    });
  };

  onCreateClicked = (e) => {
    e.preventDefault();
    this.props.history.push("/jobs/create");
  };

  onDetailClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/jobs/" + id);
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteBooking(id, resolve, reject);
    })
      .then(() => {
        this.setState({
          loading: false,
          updatedOn: moment().valueOf(),
        });
        message.success("Successfully deleted booking");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting booking");
        console.log(error);
      }, this);
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/jobs/" + id + "/edit/");
  };

  confirm = (e) => {
    var self = this;
    self.onDeleteClicked(e);
  };

  handleRowClick = (record) => {
    this.props.history.push("/jobs/" + record.id);
  };

  getFilteredColumns = (columns) => {
    return columns.filter((column) => !column.hidden);
  };

  onPaginationChange = (page, pageSize) => {
    this.setState({ page, pageSize });
  };

  render() {
    const { loading, drivers, contractors, vehicles, pagination } = this.props;
    const { page, pageSize } = this.state;
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userRole = userData?.role;
    return (
      <LayoutWrapper>
        <PageHeader title={<IntlMessages id="pagetitle.booking" />} breadcrumbs={breadcrumbs}></PageHeader>
        <LayoutContent>
          <JobFilter
            drivers={drivers}
            contractors={contractors}
            vehicles={vehicles}
            page={page}
            pageSize={pageSize}
            onCreate={this.onCreateClicked}
            userRole={userRole}
          />
          <TableWrapperStyle>
            <Table
              columns={this.getDefaultColumns(userRole)}
              dataSource={this.props.bookings}
              loading={loading}
              scroll={{ x: "max-content" }}
              pagination={{
                current: page,
                pageSize: pageSize,
                total: Number(pagination?.total),
                onChange: this.onPaginationChange,
              }}
              onRow={(record) => ({
                onClick: () => this.handleRowClick(record),
              })}
            />
          </TableWrapperStyle>
        </LayoutContent>
      </LayoutWrapper>
    );
  }

  getDefaultColumns = (userRole) => {
    const self = this;
    return [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        render: (text, row) => {
          return loadDateFormat(row.date, fullDate, row.companyTimeOffset);
        },
      },
      {
        title: "Contractor",
        key: "contract",
        dataIndex: "contract",
      },
      userRole === "admin"
        ? {
            title: "Company",
            key: "contact.name",
            dataIndex: "contact.name",
          }
        : {},
      {
        title: "Ref No",
        key: "refNo",
        dataIndex: "refNo",
        render: (text, row) => (
          <a style={{ color: "#128239" }} href={`/jobs/${row.id}`} onClick={self.onDetailClicked.bind(this, row.id)}>
            {text}
          </a>
        ),
      },

      {
        title: "Address",
        key: "location.name",
        dataIndex: "location.name",
        render: (location) => {
          if (!location) return "-";
          const [suburb, stateDetails] = location.split(",").map((part) => part.trim());
          if (!stateDetails) return suburb;
          const state = stateDetails.split(" ")[0];
          return `${suburb}, ${state}`;
        },
      },
      {
        title: "Driver",
        key: "userName",
        dataIndex: "userName",
      },
      {
        title: "Vehicle",
        key: "vehicleRego",
        dataIndex: "vehicleRego",
      },
      {
        title: "Proof of Deliveries",
        key: "proofOfDeliveriesCount",
        dataIndex: "proofOfDeliveriesCount",
        render: (pod) => (pod === 0 || !pod ? "-" : pod),
      },
      {
        title: "Payment Status",
        key: "paymentStatus",
        dataIndex: "paymentStatus",
        render: (status) => {
          if (!status) {
            return "-";
          }
          return <ItemStatus status={status && status.toLowerCase()} />;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        fixed: "right",
        render: (status) => <ItemStatus status={status.toLowerCase()} />,
      },
    ];
  };
}
export default connect(
  (state) => ({
    bookings: state.Booking.bookings,
    pagination: state.Booking.pagination,
    drivers: state.Shifts.drivers,
    vehicles: state.Shifts.vehicles,
  }),
  {
    deleteBooking,
    fetchDriverRequest,
    fetchVehicleDropdown,
  }
)(ListBooking);
