import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "Vehicle/redux/actions";
import { Spin, Alert } from "antd";
import Select, { SelectOption } from "Common/components/select";
import { Field, FormSection } from "redux-form";

import SelectFieldWrapper from "Common/styles/selectfield.style";

const { fetchVehicleDropdown } = actions;

class VehicleDropdown extends Component {
  componentWillMount() {
    if (!this.props.vehicles.length && !this.props.loading) {
      this.props.fetchVehicleDropdown();
    }
  }

  render() {
    const {
      defaultValue,
      requiredParent,
      requiredParentValue,
      input,
      meta: { touched, error },
      type,
      loading,
      ...custom
    } = this.props;
    const hasError = touched && error !== undefined;

    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={this.props.loading}>
          <Select
            {...input}
            {...custom}
            className="inputField ant-select-lg"
            value={input.value || defaultValue || ""}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <SelectOption value="">Select Vehicle</SelectOption>
            {this.props.vehicles.map((dropdown, index) => (
              <SelectOption key={index} value={dropdown.id}>
                {dropdown.registrationNumber}
              </SelectOption>
            ))}
          </Select>
          {hasError && <Alert type="error" message={error} banner />}
        </Spin>
      </SelectFieldWrapper>
    );
  }
}
const VehicleDropdownConnect = connect(
  (state) => ({
    vehicles: state.Vehicle.vehicleDropdown,
    loading: state.Vehicle.loading,
  }),
  { fetchVehicleDropdown }
)(VehicleDropdown);

export default VehicleDropdownConnect;

export const VehicleDropdownSelectWithFormSection = ({ name, type, ...custom }) => (
  <FormSection name={name}>
    <Field name="id" type={type} component={VehicleDropdownConnect} {...custom} />
  </FormSection>
);
