import { jsPDF } from "jspdf";
import "jspdf-autotable";
import domtoimage from "dom-to-image";
import createNotification from "../../Common/components/notification";
import moment from "moment/moment";
import Auth0 from "Common/helpers/auth0-custom/index";


const auth0 = new Auth0();

const downloadPdf = (fileName, headingText,cellWidth, columnsToRemove = []) => {
  const doc = new jsPDF();
  if (headingText) {
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(headingText,10,10); 
  }
  const tableElement = document.querySelector("#tableDownloadable table");
  let tableJson = doc.autoTableHtmlToJson(tableElement);
  if (columnsToRemove.length >  0) {
    tableJson.columns = tableJson.columns.filter((column, index) => !columnsToRemove.includes(index));
  }
  if (columnsToRemove.length >  0) {
    tableJson.data = tableJson.data.map(row => {
      columnsToRemove.forEach(index => row.splice(index,  1));
      return row;
    });
  }

  doc.autoTable({
    headStyles: {
      fillColor: "#174d4d",
      cellWidth: cellWidth ? cellWidth : "auto"
    },
    columns: tableJson.columns,
    body: tableJson.data,
  });

  doc.save(`${fileName}.pdf`);
};


const downloadDiary = () => {
  const input = document.getElementById("diaryDownloadable");
  const pdf = new jsPDF("l", "mm", "a4");
  if (pdf) {
    domtoimage.toPng(input).then((imgData) => {
      pdf.addImage(imgData, "PNG", 7, 7, 287, 200);
      pdf.save("workdiary.pdf");
    });
  }
};

const downloadIncident = () => {
  const input = document.getElementById("incidentWrapper");
  const pdf = new jsPDF("l", "mm", "a4");
  var pageWidth = pdf.internal.pageSize.getWidth();
  var pageHeight = pdf.internal.pageSize.getHeight();
  const pageRatio = pageWidth / pageHeight;
  if (pdf) {
    domtoimage.toPng(input).then((imgData) => {
      var img = new Image();
      img.src = imgData;
      img.onload = function () {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const imgRatio = imgWidth / imgHeight;

        if (imgRatio >= 1) {
          const wc = imgWidth / pageWidth;
          if (imgRatio >= pageRatio) {
            pdf.addImage(
              img,
              "PNG",
              0,
              5,
              pageWidth,
              imgHeight / wc,
              null,
              "NONE"
            );
          } else {
            const pi = pageRatio / imgRatio;
            pdf.addImage(
              img,
              "PNG",
              0,
              5,
              pageWidth / pi,
              imgHeight / pi / wc,
              null,
              "NONE"
            );
          }
        } else {
          const wc = imgWidth / pageHeight;
          if (1 / imgRatio > pageRatio) {
            const ip = 1 / imgRatio / pageRatio;
            pdf.addImage(
              img,
              "PNG",
              0,
              5,
              pageHeight / ip,
              imgHeight / ip / wc,
              null,
              "NONE",
              -90
            );
          } else {
            pdf.addImage(
              img,
              "PNG",
              0,
              5,
              pageHeight,
              imgHeight / wc,
              null,
              "NONE",
              -90
            );
          }
        }
        pdf.save("incident.pdf");
      };
    });
  }
};

const downloadJobSheet = async (data) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const id = data.id;
    const fileName = "jobSheet";
    const headers = new Headers();
    headers.append("Accept", "application/pdf");
    headers.append("Content-Type", "application/pdf");
    headers.append("Authorization", `Bearer ${auth0.getAccessToken()}`);
    headers.append("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    const response = await fetch(baseUrl + "/job/" + id + "/" + "jobsheetPdf", {
      method: "GET",
      headers: headers
    });
    if (response.status === 200) {
      response
        .blob()
        .then(async (blob) => {
          const url = window.URL.createObjectURL(blob);
          const aTag = document.createElement("a");
          aTag.href = url;
          aTag.download = fileName + id + "-" + moment().format("YYYYMMMDD")+".pdf";
          aTag.click();
        })
        .catch((error) => {
          console.log({ error });
        });
    } else {
      createNotification("error", "NOT FOUND", "File Not Found");
    }
}



const downloadBooking = (id) => {
  const input = document.getElementById(id);
  const pdf = new jsPDF("l", "mm", "a4");
  const pageHeight = pdf.internal.pageSize.height;
  const pageWidth = pdf.internal.pageSize.width;
  
  if (pdf && input) {
    const scale = 2;
    domtoimage
      .toPng(input, {
        width: input.clientWidth * scale,
        height: input.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left"
        },
        cacheBust: true
      })
      .then((imgData) => {
        const screenshot = new Image();
        screenshot.onload = () => {
          const imageWidth = pageWidth - 20;
          const imageHeight =
            screenshot.height * (imageWidth / screenshot.width);

          let topOffset = 10;     
          pdf.addImage(imgData, "PNG", 10, topOffset, imageWidth, imageHeight);

          let remainingImageHeight = imageHeight - pageHeight;
          while (remainingImageHeight >= 0) {
            topOffset += remainingImageHeight - imageHeight;
            pdf.addPage();
            pdf.addImage(
              imgData,
              "PNG",
              10,
              topOffset,
              imageWidth,
              imageHeight
            );
            remainingImageHeight -= pageHeight;
          }
          pdf.save("job.pdf");
        };
        screenshot.src = imgData;
      });
  }
};

const normalizeForCSV = (headerData, bodyData) => {
  const stringifyData = bodyData.map((data) =>
    data.map((singledataItem) =>
      singledataItem ? JSON.stringify(singledataItem) : null
    )
  );
  const headerBody = [headerData, ...stringifyData];
  const csvData = headerBody.map((data) => data.join(","));
  return csvData.join("\n");
};

const downloadCSV = (headerData, bodyData, filename) => {
  const csv = normalizeForCSV(headerData, bodyData);
  const csvFile = new Blob([csv], { type: "text/csv" });
  let downloadLink;

  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);

  downloadLink.click();
};

export {
  downloadPdf,
  downloadCSV,
  downloadDiary,
  downloadIncident,
  downloadBooking,
  downloadJobSheet,
};
