import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";

import actions from "Vehicle/redux/actions";
import Api from "Common/api/Api";
import Api2 from "Common/api/Api2";

const api = new Api();
const api2 = new Api2();

export function* createVehicle() {
  yield takeEvery(actions.CREATE_VEHICLE_REQUEST, function* ({ data, context, resolve, reject }) {
    try {
      const response = yield call(api2.post, "vehicle", data);
      yield call(resolve, response);
      if (context === "save") {
        // yield(put(push('/fleet/' + response.data.id)));
      }
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* retrieveVehicle() {
  yield takeEvery(actions.RETRIEVE_VEHICLE_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api2.get, "vehicle/" + id, {});
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchVehicleList() {
  yield takeEvery(actions.FETCH_VEHICLE_LIST_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api2.get, "vehicle", {});
      if (resolve) {
        yield call(resolve, response);
      } else {
        yield put({
          type: actions.FETCH_VEHICLE_LIST_SUCCESS,
          response: response.data.results,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.FETCH_VEHICLE_LIST_FAIL,
          error,
        });
      }
    }
  });
}

export function* fetchAssetList() {
  yield takeEvery(actions.FETCH_ASSET_LIST_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api2.get, "vehicle/dropdown/assets", {});
      if (resolve) {
        yield call(resolve, response);
      } else {
        yield put({
          type: actions.FETCH_ASSET_LIST_SUCCESS,
          response: response.data.results,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.FETCH_ASSET_LIST_FAIL,
          error,
        });
      }
    }
  });
}

export function* updateVehicle() {
  yield takeEvery(actions.UPDATE_VEHICLE_REQUEST, function* ({ data, id, resolve, reject }) {
    try {
      const response = yield call(api2.update, "vehicle/" + id, data);
      yield call(resolve, response);
      yield put(actions.refetch(true));
      yield put(push("/fleet/" + response.data.id));
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* deleteVehicle() {
  yield takeEvery(actions.DELETE_VEHICLE_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api2.delete, "vehicle/" + id, {});
      if (resolve) {
        yield call(resolve, response);
        yield put(push("/fleet/status/all"));
      }
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* createDocument() {
  yield takeEvery(actions.CREATE_DOCUMENT_REQUEST, function* ({ data, resolve, reject }) {
    try {
      const response = yield call(api2.post, "documents", data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* deleteDocument() {
  yield takeEvery(actions.DELETE_DOCUMENT_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api2.deleteForm, "documents/" + id, {});
      if (resolve) {
        yield call(resolve, response);
        // yield put(push('/documents'));
      } else {
        yield put({
          type: actions.DELETE_DOCUMENT_SUCCESS,
          response,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.DELETE_DOCUMENT_FAIL,
          error,
        });
      }
    }
  });
}

export function* retrieveDocument() {
  yield takeEvery(actions.RETRIEVE_DOCUMENT_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api2.get, "documents/detail/" + id, {});
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* updateDocument() {
  yield takeEvery(actions.UPDATE_DOCUMENT_REQUEST, function* ({ id, data, resolve, reject }) {
    try {
      const response = yield call(api2.update, "documents/" + id, data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* createMeterReading() {
  yield takeEvery(actions.CREATE_METER_READING_REQUEST, function* ({ vid, data, context, resolve, reject }) {
    try {
      const response = yield call(api2.post, "vehicle/" + vid + "/meter-reading", data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* updateMeterReadig() {
  yield takeEvery(actions.UPDATE_METER_READING_REQUEST, function* ({ vid, id, data, resolve, reject }) {
    try {
      const response = yield call(api2.update, "vehicles/" + vid + "/meter-readings" + id, data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* listMeterReading() {
  yield takeEvery(actions.LIST_METER_READING_REQUEST, function* ({ vehicleId, resolve, reject }) {
    try {
      const response = yield call(api2.get, "vehicle/" + vehicleId + "/meter-reading");
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* fetchContractorList() {
  yield takeEvery(actions.FETCH_CONTRACTOR, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "contractors");
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchUser() {
  yield takeEvery(actions.FETCH_USER, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.get, `users/${id}`, {});
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* fetchDeviceInfo() {
  yield takeEvery(actions.FETCH_DEVICE_INFO_REQUEST, function* ({ vehicleId, resolve, reject }) {
    try {
      const response = yield call(api2.get, "/telematics/vehicle/" + vehicleId + "/info");

      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchEventHistoryVideos() {
  yield takeEvery(actions.FETCH_EVENT_HISTORY_VIDEOS_REQUEST, function* ({ vehicleId, platform, beginTime, endTime, resolve, reject }) {
    try {
      const response = yield call(
        api2.get,
        "/telematics/vehicle/" + vehicleId + "/" + platform + "/eventList?beginTime=" + beginTime + "&endTime=" + endTime
      );

      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchDeviceHistoryVideos() {
  yield takeEvery(actions.FETCH_DEVICE_HISTORY_VIDEO_REQUEST, function* ({ vehicleId, channel, beginTime, selectedDate, resolve, reject }) {
    try {
      const response = yield call(
        api2.get,
        "/telematics/vehicle/" + vehicleId + "/deviceVideoUrl?beginTime=" + beginTime + "&endTime=" + selectedDate + "&channelId=" + channel
      );
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchRealTimeLocation() {
  yield takeEvery(actions.FETCH_REAL_TIME_LOCATION, function* ({ vehicleId, platform, resolve, reject }) {
    try {
      const response = yield call(api2.get, "/telematics/vehicle/" + vehicleId + "/" + platform + "/GPSLocation");

      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}
export function* fetchLocationHistory() {
  yield takeEvery(actions.FETCH_LOCATION_HISTORY_REQUEST, function* ({ vehicleId, platform, beginTime, endTime, resolve, reject }) {
    try {
      const response = yield call(
        api2.get,
        "/telematics/vehicle/" + vehicleId + "/" + platform + "/GPSHistory?beginTime=" + beginTime + "&endTime=" + endTime
      );
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* createVehicleIntegration() {
  yield takeEvery(actions.CREATE_VEHICLE_INTEGRATION_REQUEST, function* ({ data, vehicleId, resolve, reject }) {
    try {
      const response = yield call(api2.post, "telematics/vehicleTelematicsDevices/" + vehicleId, data);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchVehicleIntegration() {
  yield takeEvery(actions.FETCH_VEHICLE_INTEGRATION_REQUEST, function* ({ vehicleId, resolve, reject }) {
    try {
      const response = yield call(api2.get, "telematics/vehicleTelematicsDevices/" + vehicleId);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* updateVehicleIntegration() {
  yield takeEvery(actions.UPDATE_VEHICLE_INTEGRATION_REQUEST, function* ({ vehicleId, data, resolve, reject }) {
    try {
      const response = yield call(api2.update, "telematics/vehicleTelematicsDevices/" + vehicleId, data);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchVehicleDetailsFromNHVR() {
  yield takeEvery(actions.FETCH_VEHICLE_DETAILS_FROM_NHVR_REQUEST, function* ({ vehicleRego, resolve, reject }) {
    try {
      const response = yield call(api2.get, "nhvr/vehicle/" + vehicleRego);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* fetchVehicleType() {
  yield takeEvery(actions.FETCH_VEHICLE_TYPE_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api2.get, "vehicle/vehicle-type", {});
      if (resolve) {
        yield call(resolve, response);
      } else {
        yield put({
          type: actions.FETCH_VEHICLE_TYPE_SUCCESS,
          response: response.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_VEHICLE_TYPE_SUCCESS,
        response: [],
      });
    }
  });
}

export function* fetchVehicleDropdown() {
  yield takeEvery(actions.FETCH_VEHICLE_DROPDOWN_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api2.get, "vehicle/dropdown/all", {});
      if (resolve) {
        yield call(resolve, response);
      } else {
        yield put({
          type: actions.FETCH_VEHICLE_DROPDOWN_SUCCESS,
          response: response.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_VEHICLE_DROPDOWN_SUCCESS,
        response: [],
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createVehicle),
    fork(retrieveVehicle),
    fork(updateVehicle),
    fork(deleteVehicle),
    fork(fetchVehicleList),
    fork(fetchAssetList),
    fork(createDocument),
    fork(deleteDocument),
    fork(updateDocument),
    fork(retrieveDocument),
    fork(createMeterReading),
    fork(updateMeterReadig),
    fork(listMeterReading),
    fork(fetchContractorList),
    fork(fetchUser),
    fork(fetchEventHistoryVideos),
    fork(fetchDeviceHistoryVideos),
    fork(fetchRealTimeLocation),
    fork(fetchLocationHistory),
    fork(fetchDeviceInfo),
    fork(createVehicleIntegration),
    fork(fetchVehicleIntegration),
    fork(updateVehicleIntegration),
    fork(fetchVehicleDetailsFromNHVR),
    fork(fetchVehicleType),
    fork(fetchVehicleDropdown),
  ]);
}
