import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "User/redux/actions";
import { push } from "react-router-redux";
import Api from "Common/api/Api2";

import authActions from "Public/redux/actions";

const api = new Api();

export function* createUserRequest() {
  yield takeEvery(actions.CREATE_USER_REQUEST, function* ({ data, context, resolve, reject }) {
    try {
      const response = yield call(api.post, "user", data);
      yield call(resolve, response);
      if (context === "save") {
        yield put(push("/users/" + response.data.id));
      }
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* retrieveUserRequest() {
  yield takeEvery(actions.FETCH_USER_DETAIL_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.get, "user/" + id, {});
      if (resolve) {
        yield call(resolve, response);
      } else {
        yield put({
          type: actions.FETCH_USER_DETAIL_SUCCESS,
          response: response.data,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.FETCH_USER_DETAIL_FAIL,
          error,
        });
      }
    }
  });
}

export function* fetchUserEditRequest() {
  yield takeEvery(actions.EDIT_USER_DETAIL_REQUEST, function* ({ data, id, resolve, reject }) {
    try {
      const response = yield call(api.update, "user/" + id, data);
      yield call(resolve, response);
      yield put(push("/users/" + id));
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* deleteUserRequest() {
  yield takeEvery(actions.DELETE_USER_DETAIL_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.delete, "user/" + id, {});
      if (resolve) {
        yield call(resolve, response);
        yield put(push("/users/role/all"));
      } else {
        yield put({
          type: actions.EDIT_USER_DETAIL_SUCCESS,
          response,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.EDIT_USER_DETAIL_FAILED,
          error,
        });
      }
    }
  });
}

//TODO: not used need to verify
export function* fetchOrganizationRequest() {
  yield takeEvery(actions.FETCH_ORGANIZATION_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "organizations", {});
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* fetchAllUsersList() {
  yield takeEvery(actions.FETCH_ALL_USERS_LIST_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "user/dropdown", {});
      if (resolve) {
        yield call(resolve);
      } else {
        yield put({
          type: actions.FETCH_ALL_USERS_LIST_SUCCESS,
          response: response.data.result,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.FETCH_ALL_USERS_LIST_FAIL,
          response: error,
        });
      }
    }
  });
}

export function* fetchUserList() {
  yield takeEvery(actions.FETCH_USER_LIST_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "user", {});
      if (resolve) {
        yield call(resolve);
      } else {
        yield put({
          type: actions.FETCH_USER_LIST_SUCCESS,
          response: response.data.results,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.FETCH_USER_LIST_FAIL,
          response: error,
        });
      }
    }
  });
}
export function* changePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, function* ({ data, id, context, resolve, reject }) {
    try {
      const response = yield call(api.patch, "user/" + id, data);
      yield call(resolve, response);
      yield put({
        type: authActions.LOGOUT,
      });
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createUserRequest),
    fork(fetchUserList),
    fork(fetchAllUsersList),
    fork(fetchOrganizationRequest),
    fork(retrieveUserRequest),
    fork(fetchUserEditRequest),
    fork(deleteUserRequest),
    fork(changePassword),
  ]);
}
