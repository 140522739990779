import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "App/redux/actions";
import TopbarUser from "App/components/TopbarUser";
import TopbarWrapper from "App/styles/topbar.style";
import themes from "App/settings/themes";
import { themeConfig } from "App/settings";

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 768,
    };
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    const { toggleCollapsed } = this.props;
    const { isMobile } = this.state;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      height: 0,
      marginBottom: isMobile ? 0 : 35,
      marginTop: isMobile ? 0 : 15,
      lineHeight: isMobile && 0,
    };

    return (
      <TopbarWrapper>
        <Header style={styling} className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}>
          <div className="isoLeft">
            <button
              className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className="isoRight">
            <li onClick={() => this.setState({ selectedItem: "user" })} className="isoUser">
              <TopbarUser userProfile={this.props.userProfile} isMobile={isMobile} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
  }),
  { toggleCollapsed }
)(Topbar);
