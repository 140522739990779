import React from "react";
import { Row, Col, Breadcrumb } from "antd";
import PageHeaderWrapper from "./styles/PageHeader.style";
import Topbar from "App/components/Topbar";
import { connect } from "react-redux";
import { history } from "Common/redux/store";

const getBreadcrumbs = (breadcrumbs) => {
  const breadItems = breadcrumbs.map((breadcrumb, index) => {
    if (breadcrumb.path) {
      return (
        <Breadcrumb.Item
          onClick={(e) => {
            e.preventDefault();
            history.push(breadcrumb.path);
          }}
          href={breadcrumb.path}
          key={index}
        >
          {breadcrumb.title}
        </Breadcrumb.Item>
      );
    } else {
      return <Breadcrumb.Item key={index}>{breadcrumb.title}</Breadcrumb.Item>;
    }
  });
  return <Breadcrumb separator=">">{breadItems}</Breadcrumb>;
};

const PageHeader = ({ title, breadcrumbs, children, auth }) => (
  <PageHeaderWrapper>
    <Row>
      <Col className="titles" lg={12} md={12} sm={24}>
        <h1>{title}</h1>
        <div className="breadcrumbs">{getBreadcrumbs(breadcrumbs)}</div>
      </Col>
      <Col className="buttons" lg={24} md={24}>
        <Topbar userProfile={auth.profile} />
      </Col>
    </Row>
  </PageHeaderWrapper>
);

export default connect((state) => {
  return {
    auth: state.Auth,
  };
})(PageHeader);
