import React, { Component } from "react";
import { Field } from "redux-form";
import { Row, Col, Form } from "antd";

import IntlMessages from "Common/components/intlMessages";
import Section from "Common/components/section";
import { ContractorDropdownWFS as ContractorDropdown } from "Vehicle/components/form/ContractorDropdown";
import SelectField from "Common/components/SelectField";
import SelectOption from "Common/components/select";
import InputField from "Common/components/InputField";
import DateTimePicker from "Common/components/DateTimePicker";
import LocationSearchBox from "../../Location/components/LocationSearchBox";
import TextAreaField from "Common/components/textAreaField";
import UploadWorkSheet from "./UploadWorkSheet";
import { required } from "Route/components/RouteCreateForm";
import info from "Common/image/infoOrange.png";
import scriptLoader from "react-async-load-script";

const FormItem = Form.Item;

class BasicInformation extends Component {
  handleLocationSearchChange = (key, data) => {
    this.props.onChange(key, data);
  };

  handleCheckboxChange = (event) => {
    if (event.target.checked) {
      const { departureLocation } = this.props;
      this.handleLocationSearchChange("arrivalLocation", departureLocation);
    }
  };

  render() {
    const { editingMode } = this.props;
    return (
      <>
        <div
          style={{
            backgroundColor: "#F3F5FC",
            borderRadius: "10px",
            padding: "10px",
            overflow: "hidden",
            marginBottom: "15px",
          }}
        >
          <Section
            label={
              <h2>
                <IntlMessages id="sectiontitle.basic" />
              </h2>
            }
          >
            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.referenceNo" />
                  </label>
                  <Field name="refNo" component={InputField} />
                </FormItem>
              </Col>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.contractor" />
                  </label>
                  <ContractorDropdown name="contractor" addOther />
                </FormItem>
                {this.props.selectedContractor?.id === "other" && (
                  <FormItem>
                    <label>
                      <IntlMessages id="forms.label.newContractor" />*
                    </label>
                    <Field
                      name="otherContractor"
                      component={InputField}
                      validate={required}
                    />
                  </FormItem>
                )}
              </Col>
            </Row>

            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.departureDate" />*
                  </label>
                  <Field
                    validate={required}
                    formType="bookingCreateForm"
                    name="departureDate"
                    component={DateTimePicker}
                    ref={this.myRef}
                  />
                </FormItem>
              </Col>

              <Col lg={12} md={12}>
                <label>
                  <IntlMessages id="forms.label.departureLocation" />*
                </label>
                {this.props.isScriptLoadSucceed && (
                  <LocationSearchBox
                    handleLocationChange={(address, geolocation) =>
                      this.handleLocationSearchChange("departureLocation", {
                        address,
                        geolocation,
                      })
                    }
                    searchOptions={{
                      componentRestrictions: { country: "au" },
                    }}
                    name="departureLocation.address"
                    validate={required}
                    labelInValue={true}
                  />
                )}
              </Col>
            </Row>

            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.arrivalDate" />
                  </label>
                  <Field
                    formType="bookingCreateForm"
                    name="arrivalDate"
                    component={DateTimePicker}
                    ref={this.myRef}
                  />
                </FormItem>
              </Col>

              <Col lg={12} md={12}>
                <label>
                  <IntlMessages id="forms.label.arrivalLocation" />
                </label>
                {this.props.isScriptLoadSucceed && (
                  <LocationSearchBox
                    handleLocationChange={(address, geolocation) =>
                      this.handleLocationSearchChange("arrivalLocation", {
                        address,
                        geolocation,
                      })
                    }
                    searchOptions={{
                      componentRestrictions: { country: "au" },
                    }}
                    name="arrivalLocation.address"
                    labelInValue={true}
                  />
                )}

                <FormItem>
                  <Field
                    name="sameAsPickup"
                    component="input"
                    type="checkbox"
                    onChange={this.handleCheckboxChange}
                  />
                  <label>
                    <IntlMessages id="forms.label.sameAsPickup" />
                  </label>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={48}>
              <Col lg={12} md={12}>
                <label>
                  <IntlMessages id="forms.label.image" />
                </label>
                <UploadWorkSheet
                  workSheetImage={this.props.workSheetImage}
                  handleImageChange={this.props.handleImageChange}
                />
              </Col>
              <Col lg={12} md={12}>
                {editingMode && (
                  <FormItem>
                    <label>
                      <IntlMessages id="forms.label.status" />
                    </label>
                    <Field name="status" component={SelectField}>
                      <SelectOption value="draft">Draft</SelectOption>
                      <SelectOption value="completed">Complete</SelectOption>
                      <SelectOption value="inTransit">In Transit</SelectOption>
                      <SelectOption value="cancelled">Cancelled</SelectOption>
                      <SelectOption value="published">Published</SelectOption>
                    </Field>
                  </FormItem>
                )}
              </Col>
            </Row>
          </Section>
        </div>

        <div
          style={{
            backgroundColor: "#F3F5FC",
            borderRadius: "10px",
            padding: "10px",
            overflow: "hidden",
            marginBottom: "15px",
          }}
        >
          <Section
            label={
              <h2>
                <IntlMessages id="sectiontitle.operatorsSection" />
              </h2>
            }
          >
            <div style={{ color: "#ED8A00", marginBottom: "10px" }}>
              <img src={info} height="20px" /> This information is not shown to
              the driver
            </div>
            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.invoiceAmount" />
                  </label>
                  <Field name="invoiceAmount" component={InputField} />
                </FormItem>
              </Col>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.payment" />
                  </label>
                  <Field name="paymentStatus" component={SelectField}>
                    <SelectOption value="quote">Quote</SelectOption>
                    <SelectOption value="Unpaid">Unpaid</SelectOption>
                    <SelectOption value="invoiceSent">
                      Invoice Sent
                    </SelectOption>
                    <SelectOption value="paid">Paid</SelectOption>
                  </Field>
                </FormItem>
              </Col>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.comments" />
                  </label>
                  <Field name="comments" component={TextAreaField} rows={3} />
                </FormItem>
              </Col>
            </Row>
          </Section>
        </div>
      </>
    );
  }
}

export default scriptLoader([
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBtILKnXre0jbA91S3p2ih_RLX8MMNbH0A&libraries=places",
])(BasicInformation);
