import JobSheetContainer from "./JobSheet";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isMoment } from "moment";
import moment from "moment-timezone";
import { Popconfirm, message } from "antd";
import Button from "Common/components/button";
import { localizeDate } from "Booking/components/BookingCreateForm";
import actions from "Booking/redux/actions";
import IntlMessages from "Common/components/intlMessages";
import Protected from "Common/components/authorization/Protected";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import PageHeader from "Common/components/Page/PageHeader";
import { downloadBooking, downloadJobSheet } from "../../Common/helpers/downloadTable";
import { Tabs, Tooltip, Icon } from "antd";
import { formatDate } from "Common/helpers/utility";
import BookingDetails from "./../components/BookingDetails";

const { TabPane } = Tabs;
const { retrieveBooking, deleteBooking, updateBooking } = actions;

export const fullDate = "DD/MM/YYYY HH:mm";

export const normalizeDateFormat = (value, format) => (value && isMoment(localizeDate(value)) && localizeDate(value).format(format)) || "";

export const loadDateFormat = (value, format, utcOffSet) => {
  if (!value) return "";

  let formattedDate;

  if (utcOffSet === null) {
    // Use Melbourne/Australia timezone
    formattedDate = moment.tz(value, "Australia/Melbourne").format(format);
  } else {
    // Use the provided UTC offset
    formattedDate = moment.utc(value).utcOffset(utcOffSet).format(format);
  }

  return formattedDate;
};
const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Loads",
    path: "/jobs/status/all",
  },
  {
    title: "Detail",
  },
];

class RetrieveBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      entity_id: props.match.params.id,
      entity: {},
      photoIndex: 0,
      isOpen: false,
      tab: "1",
    };
  }

  componentWillMount = () => {
    this.fetchJob();
  };

  fetchJob = () => {
    new Promise((resolve, reject) => {
      const id = this.props.match.params.id;
      this.props.retrieveBooking(id, resolve, reject);
    })
      .then((response) => {
        this.setState({
          loading: false,
          entity: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      }, this);
  };

  confirm = (e) => {
    this.onDeleteClicked(e);
  };

  onDeleteClicked = () => {
    this.setState({ loading: true });
    new Promise((resolve, reject) => {
      this.props.deleteBooking(this.state.entity_id, resolve, reject);
    })
      .then((success) => {
        this.setState({ loading: false });
        message.success("Successfully deleted job");
      })
      .catch((data) => {
        this.setState({ loading: false });
        message.error("Error while deleting job");
      }, this);
  };

  onEditClicked = (data) => {
    this.props.history.push("/jobs/" + this.state.entity_id + "/edit/");
  };
  changeTab = (key) => {
    this.setState({ tab: key });
  };
  publishJob = (data) => {
    const sendData = {
      ...data,
      status: "published",
    };
    this.updateJob(sendData, "published");
  };

  completeJob = (data) => {
    const sendData = {
      ...data,
      status: "completed",
    };
    this.updateJob(sendData, "completed");
  };

  updateJob = (data, context) => {
    this.setState({ loading: true });
    new Promise((resolve, reject) => {
      this.props.updateBooking(this.state.entity_id, data, resolve, reject);
    })
      .then((success) => {
        this.setState({ loading: false });
        message.success(`Successfully Changed Job Status to ${context}`);
        this.fetchJob();
      })
      .catch((data) => {
        this.setState({ loading: false });
        message.error(`Error while changing job status to ${context}`);
      }, this);
  };

  render() {
    const { loading, entity } = this.state;
    const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
    return (
      <>
        <LayoutWrapper>
          <PageHeader title={<IntlMessages id="pagetitle.booking" />} breadcrumbs={breadcrumbs} />
          <Protected scope="write:bookings">
            <div className="topButton responsiveButtonContainer">
              {entity.status == "draft" && entity.driver.id && entity.vehicle.id && (
                <Button
                  className="active"
                  icon="notification"
                  disabled={entity.status == "completed" ? true : false}
                  loading={loading}
                  onClick={() => this.publishJob(entity)}
                >
                  Publish to driver
                </Button>
              )}
              <Button
                className="primary active"
                icon="check"
                loading={loading}
                disabled={entity.status == "completed" ? true : false}
                onClick={() => this.completeJob(entity)}
              >
                Mark as Complete
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  this.state.tab === "1" ? downloadBooking("jobDetailPrintWrapper") : downloadJobSheet(entity);
                }}
                style={{
                  backgroundColor: "#128239",
                  borderColor: "#128239",
                }}
              >
                Download pdf
              </Button>

              <Fragment>
                <Button loading={loading} type="primary" icon="edit" onClick={this.onEditClicked}>
                  Edit
                </Button>
                <Popconfirm title="Are you sure you want to delete this job ?" onConfirm={this.confirm}>
                  <Button type="danger" icon="delete" loading={loading}>
                    Delete
                  </Button>
                </Popconfirm>
              </Fragment>
            </div>
          </Protected>
          <Tabs defaultActiveKey="2" activeKey={this.state.tab} onChange={this.changeTab}>
            <TabPane
              tab={
                <span>
                  Load Detail
                  {entity && entity.editedData?.length > 0 && (
                    <Tooltip
                      title={
                        <div>
                          {entity.editedData.map((data, index) => (
                            <div key={index}>
                              <p>
                                {data.createdBy} {"[at:"}
                                {formatDate(data.createdOn.date)} {"]"} {"["} {data.comments} {"]"}
                              </p>
                            </div>
                          ))}
                        </div>
                      }
                    >
                      <Icon style={{ marginLeft: 8 }} type="info-circle" />
                    </Tooltip>
                  )}
                </span>
              }
              key="1"
            >
              <BookingDetails entity={entity} history={this.props.history} />
            </TabPane>
            {["completed", "commenced"].includes(entity.status) ? (
              <TabPane tab="Load Sheet" key="2">
                <JobSheetContainer entity={entity} MEDIA_BASE_URL={MEDIA_BASE_URL} loading={loading} />
              </TabPane>
            ) : null}
          </Tabs>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    selectedRange: state.Booking.selectedRange,
  }),
  { retrieveBooking, deleteBooking, updateBooking }
)(RetrieveBooking);
